import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { S3_BUCKET, useAxiosInstance } from 'api';
import { BUILDER_IMAGES_DOMAIN, DEFAULT_MAX_FILE_SIZE, isUrl } from 'utils';
import { InfoModal } from 'components';
import { NEUTRAL_10_COLOUR, NEUTRAL_1_COLOUR, NEUTRAL_2_COLOUR, NEUTRAL_6_COLOUR, PRIMARY_ORANGE_COLOUR } from 'theme';
import { useAppBeingEdited } from 'app-context';
import { CopyIcon, RevertIcon, UploadIcon } from 'icons';
import { useUploadImage } from 'hooks';

import { LoadingSpinner } from '../../Loading/LoadingSpinner/LoadingSpinner';

const UploaderBackground = styled.div<{
  $height?: string;
  $width?: string;
  $borderRadius?: string;
  $borderColor?: string;
  $backgroundColor?: string;
  $backgroundImage?: string;
  filename?: string;
}>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: ${({ $height }) => $height || '115px'};
  width: ${({ $width }) => $width || '115px'};
  border-radius: ${({ $borderRadius }) => $borderRadius || '5px'};
  border: ${({ $borderColor }) => ($borderColor ? `1px solid ${$borderColor}` : 'none')};

  background-image: ${({ $backgroundColor, $backgroundImage, filename }) =>
    filename
      ? 'none'
      : $backgroundImage
      ? `url(${$backgroundImage})`
      : !$backgroundColor && `url(${BUILDER_IMAGES_DOMAIN}/misc/checkered_tile.png)`};
  background-size: ${({ $backgroundImage }) => $backgroundImage && 'cover'};
  background-color: ${(props) => props.$backgroundColor};
`;

const Wrapper = styled.div<{
  $height?: string;
  $width?: string;
}>`
  height: ${(props) => `${props.$height || '90px'}`};
  width: ${(props) => `${props.$width || '90px'}`};
  display: flex;
  align-items: center;
  justify-content: center;

  span.anticon-loading {
    color: ${NEUTRAL_6_COLOUR} !important;
  }
`;

const UploadButton = styled.div<UploadButtonInternalConfig>`
  position: absolute;
  // Half the button is off the thumbnail
  bottom: ${({ $size }) => ($size ? `calc(${$size}/-2)` : '-15px')};
  right: ${({ $right }) => $right || '-6px'};
  z-index: 100; // On top of frame

  border-radius: 50%;
  width: ${({ $size }) => $size || '40px'};
  height: ${({ $size }) => $size || '40px'};
  background-color: white;
  box-shadow: 3px 3px 10px 0px #0000001a;

  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $color }) => $color || PRIMARY_ORANGE_COLOUR};

  cursor: pointer;
`;
const StyledUploadIcon = styled(UploadIcon)<UploadButtonInternalConfig>`
  // Scale the icon down relative to container size
  font-size: ${({ $size }) => ($size ? `calc(calc(${$size}} / 40px) * 20px)` : '20px')};
  line-height: 1;
`;
const ScaledImage = styled.img<{ borderRadius?: string }>`
  object-fit: cover;
  border-radius: ${(props) => props.borderRadius || '5px'};
`;

const dashedBorder = '#c1c7d1 dashed 1px';
const VerticalFrame = styled.div<{ scale: number }>`
  position: absolute;
  left: ${(props) => `${(100 - props.scale) / 2}%`};
  height: 100%;
  width: ${(props) => `${props.scale}%`};
  border-left: ${dashedBorder};
  border-right: ${dashedBorder};
`;
const HorizontalFrame = styled.div<{ scale: number }>`
  position: absolute;
  top: ${(props) => `${(100 - props.scale) / 2}%`};
  width: 100%;
  height: ${(props) => `${props.scale}%`};
  border-top: ${dashedBorder};
  border-bottom: ${dashedBorder};
`;
const scaleFontSize = (height: string, scale: number) => {
  const heightPx = parseInt(height.split('px')[0]);
  return `${heightPx * (scale / 100)}px`;
};

const ButtonFrame = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
`;

const WHITE_BUTTON = css`
  margin-left: 4px;
  color: ${NEUTRAL_10_COLOUR};
  font-size: 16px;
  line-height: 1;
  border-radius: 3px;
  background-color: ${NEUTRAL_1_COLOUR};
  cursor: pointer;

  :hover {
    background-color: ${NEUTRAL_2_COLOUR};
  }
`;

const CopyButton = styled.div`
  ${WHITE_BUTTON};
  padding: 2px 2px 3px 3px; //The base icon is uneven
`;
const RevertButton = styled.div`
  ${WHITE_BUTTON};
  padding: 2px;
`;

export interface UploadButtonConfig {
  size?: string;
  color?: string;
  right?: string;
}

interface UploadButtonInternalConfig {
  $size?: string;
  $color?: string;
  $right?: string;
}

export interface ImageUploaderProps {
  onFilenameChange: (filename: string) => void;
  handleError?: () => void;

  // Structure
  height: string;
  width: string;
  borderRadius?: string;
  borderColor?: string;

  // Background
  backgroundColor?: string; // Custom background when no image
  backgroundImage?: string; // Custom background when no image

  // Scaling (For images that will get shrunk down on upload)
  scale?: number; // Percentage of uploader to display image in
  scaleSpinner?: number;

  // Upload requirements
  requiredDimensions?: { requiredWidth: number; requiredHeight: number };
  maxDimensions?: { maxWidth: number; maxHeight: number };
  acceptedFileTypes?: string;
  maxFileSize?: number;

  // Construct the path to the image src
  filename: string | undefined;
  filePath?: string;

  // Inject an externally accessible ref to the input element
  overrideRef?: RefObject<HTMLInputElement>;

  // Customise buttons/overlays
  hideUploadButton?: boolean;
  hideGuide?: boolean;
  showCopyButton?: boolean;
  showRevertButton?: boolean;
  onRevert?: () => void;
  uploadConfig?: UploadButtonConfig;
}

export const ImageUploader = ({
  height,
  width,
  borderRadius,
  borderColor,
  backgroundColor,
  backgroundImage,
  scale,
  scaleSpinner,
  maxFileSize,
  requiredDimensions,
  maxDimensions,
  acceptedFileTypes,
  overrideRef,
  filename,
  filePath,
  hideUploadButton,
  hideGuide,
  showCopyButton,
  onFilenameChange,
  handleError,
  uploadConfig,
  showRevertButton,
  onRevert,
}: ImageUploaderProps) => {
  const localRef = useRef<HTMLInputElement>(null);
  const appBeingEdited = useAppBeingEdited();
  const axiosInstance = useAxiosInstance();
  const uploadImage = useUploadImage();

  const [loading, setLoading] = useState(false);

  const fileInputRef = useMemo(() => {
    // Either generate a ref locally or provide one externally
    // External is useful when we want to trigger a click on this input from other components
    return overrideRef ? overrideRef : localRef;
  }, [overrideRef, localRef]);

  const handleClick = useCallback(() => {
    fileInputRef.current?.click(); //Forward click to the file input
  }, [fileInputRef]);

  const handleFileChange = useCallback(async () => {
    if (fileInputRef.current?.files && fileInputRef.current?.files.length > 0) {
      setLoading(true);
      const file = fileInputRef.current?.files[0];
      if (acceptedFileTypes && !acceptedFileTypes.includes(file.type)) {
        const err = "Image isn't an accepted file type";
        console.error(`${err}: ${acceptedFileTypes}`);
        InfoModal({
          title: 'Incorrect File Type',
          content: `Looks like the file you uploaded isn’t the correct file type. Please try again.`,
          type: 'warning',
        });
        setLoading(false);
        return;
      }

      const maxSize = maxFileSize ?? DEFAULT_MAX_FILE_SIZE;
      const maxFileSizeInBytes = maxSize * 1048576;
      if (file.size > maxFileSizeInBytes) {
        const err = 'File exceeds maximum allowed file size';
        console.error(`${err}: ${maxFileSize}`);
        InfoModal({
          title: 'Maximum File Size Exceeded',
          content: `Looks like the file you uploaded is larger than the maximum allowed size: ${maxFileSize}MB. Please try again.`,
          type: 'warning',
        });
        setLoading(false);
        return;
      }

      if (requiredDimensions || maxDimensions) {
        const processImage = (src: string) => {
          return new Promise<{ height: number; width: number }>((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve({ height: img.height, width: img.width });
            img.onerror = reject;
            img.src = src;
          });
        };
        const uploadSource = window.URL.createObjectURL(file);
        const { height, width } = await processImage(uploadSource);
        console.info(`This image dimensions ${width} x ${height}`);

        if (requiredDimensions) {
          const { requiredHeight, requiredWidth } = requiredDimensions;
          console.info(`Required dimensions ${requiredWidth} x ${requiredHeight}`);

          if (requiredWidth !== width || requiredHeight !== height) {
            const err = "Image doesn't match the required dimensions";
            console.error(`${err} ${requiredWidth} x ${requiredHeight}`);
            InfoModal({
              title: 'Incorrect Image Dimensions',
              content: `Looks like the image you uploaded isn’t the correct dimensions. Please try again with a ${requiredWidth} x ${requiredHeight} pixel image.`,
              type: 'warning',
            });
            setLoading(false);
            return;
          }
        } else if (maxDimensions) {
          const { maxHeight, maxWidth } = maxDimensions;
          console.info(`Max width: ${maxWidth}, Max height: ${maxHeight}`);

          if (width > maxWidth || height > maxHeight) {
            const err = 'Image exceeds the max dimensions';
            console.error(`${err} ${maxWidth} x ${maxHeight}`);
            InfoModal({
              title: 'Incorrect Image Dimensions',
              content: `Looks like the image you uploaded exceeds the maximum dimensions. Please select an image with a maximum width of ${maxWidth} pixels and a maximum height of ${maxHeight} pixels.`,
              type: 'warning',
            });
            setLoading(false);
            return;
          }
        }
      }

      uploadImage.mutate(
        { file: file, options: { filePath } },
        {
          onSuccess: (filename) => {
            setLoading(false);
            onFilenameChange(filename);
          },
          onError: () => {
            handleError
              ? handleError()
              : InfoModal({
                  title: 'Upload Failed',
                  content: `Looks like something went wrong. Please try again or contact VidApp support for help.`,
                  type: 'error',
                });
            setLoading(false);
          },
        },
      );
    }
  }, [uploadImage, fileInputRef, appBeingEdited, axiosInstance, setLoading, handleError]);

  const src = useMemo(() => {
    if (filename) {
      if (isUrl(filename)) {
        return filename;
      } else if (filePath) {
        return `${S3_BUCKET}${filePath}${filename}`;
      }
      return `${S3_BUCKET}${appBeingEdited}/images/${filename}`;
    }
    return undefined;
  }, [filename, filePath, appBeingEdited]);

  const handleCopy = () => {
    if (src) {
      navigator.clipboard.writeText(src);
    }
  };

  return (
    <UploaderBackground
      $height={height}
      $width={width}
      $borderRadius={borderRadius}
      $borderColor={borderColor}
      $backgroundColor={backgroundColor}
      $backgroundImage={backgroundImage}
      filename={filename}
    >
      <Wrapper $height={`${scale || 100}%`} $width={`${scale || 100}%`}>
        {loading ? (
          <LoadingSpinner fontSize={scaleFontSize(height, scaleSpinner || scale || 100)} />
        ) : filename ? (
          // Display the newly uploaded image
          <ScaledImage height="100%" width="100%" src={src} alt="Uploaded Image" borderRadius={borderRadius} />
        ) : null}
        <input
          type="file"
          accept={acceptedFileTypes ?? 'image/*'} // Accept all images unless specified
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </Wrapper>
      {!hideUploadButton && (
        <UploadButton
          onClick={handleClick}
          $size={uploadConfig?.size}
          $color={uploadConfig?.color}
          $right={uploadConfig?.right}
        >
          <StyledUploadIcon $size={uploadConfig?.size} />
        </UploadButton>
      )}
      <ButtonFrame>
        {showRevertButton && (
          <RevertButton onClick={onRevert}>
            <RevertIcon />
          </RevertButton>
        )}
        {showCopyButton && src && (
          <CopyButton onClick={handleCopy}>
            <CopyIcon />
          </CopyButton>
        )}
      </ButtonFrame>
      {/* Visual Guides to show how the image will be scaled */}
      {scale && !hideGuide && <VerticalFrame scale={scale} />}
      {scale && !hideGuide && <HorizontalFrame scale={scale} />}
    </UploaderBackground>
  );
};
