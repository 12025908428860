import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { SettingsGroup, SettingsImageInput, SettingsSegment, SettingsSegmentDivider } from 'components';
import { useLocalAppBasicInfo, useLocalAppProperties, useSaveContext } from 'providers';
import { useProducts } from 'hooks';

import { AppPropertyTextInput, AppPropertyTextAreaInput, AppPropertyCheckbox } from 'app/modules/design-screens';
import { PurchaseScreenView } from 'app/modules/build-dragdrop';

const PURCHASE_SCREEN_IMAGE_WIDTH = 270;
const PURCHASE_SCREEN_IMAGE_HEIGHT = 320;

const RequirementsLabel = styled.div`
  width: 317px;

  &&& b {
    font-weight: 500;
  }
`;

const TextOneInput = ({
  name,
  appName,
  placeholder,
  ...props
}: {
  name: string;
  appName?: string;
  placeholder?: string;
  onFocus?: () => void;
}) => (
  <AppPropertyTextAreaInput
    label="Text 1"
    placeholder={placeholder ?? `Welcome to ${appName ?? 'the app'}`}
    property={name}
    maxLength={70}
    rows={3}
    {...props}
  />
);

const TextTwoInput = ({ name, ...props }: { name: string; placeholder: string; onFocus?: () => void }) => (
  <AppPropertyTextAreaInput label="Text 2" property={name} maxLength={120} rows={3} {...props} />
);

const ButtonTextInput = ({
  name,
  label,
  ...props
}: {
  name: string;
  label: string;
  placeholder: string;
  onFocus?: () => void;
}) => <AppPropertyTextInput label={label} property={name} maxLength={25} $width="320px" {...props} />;

export const PurchaseScreensSettings = ({
  setPurchaseScreenView,
}: {
  setPurchaseScreenView: Dispatch<SetStateAction<PurchaseScreenView>>;
}) => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { basicInfo } = useLocalAppBasicInfo();
  const { setAppPropertyToSave } = useSaveContext();
  const { data: productsData } = useProducts(Infinity, 'always');
  const [screenKey, setScreenKey] = useState<PurchaseScreenView>('Subscription1');

  const appName = basicInfo.AppleTitle;

  const hasBuyToOwn = useMemo(
    () =>
      productsData &&
      productsData.products.filter((product) => product.ReadyForSale === 'yes' && product.ProductType === 'purchase')
        .length > 0,
    [productsData],
  );

  const handleFilenameChange = useCallback(
    (id: string, filename: string) => {
      setAppProperty(id, filename);
      setAppPropertyToSave(id, filename);
    },
    [setAppProperty, setAppPropertyToSave],
  );

  const handleScroll = useCallback(
    (event: Event) => {
      const element = event.target as HTMLElement;
      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight;
      const clientHeight = element.clientHeight;

      const scrolledPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (hasBuyToOwn) {
        if (scrolledPercentage < 27) {
          setScreenKey('Subscription1');
        } else if (scrolledPercentage < 57) {
          setScreenKey('Subscription2');
        } else if (scrolledPercentage < 78) {
          setScreenKey('Purchase1');
        } else {
          setScreenKey('Purchase2');
        }
      } else {
        if (scrolledPercentage < 62) {
          setScreenKey('Subscription1');
        } else {
          setScreenKey('Subscription2');
        }
      }
    },
    [hasBuyToOwn, setScreenKey],
  );

  useEffect(() => {
    const scrollContainer = document.getElementById('ScrollContainer--PageContainer');

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }

    return undefined;
  }, [handleScroll]);

  useEffect(() => {
    setPurchaseScreenView(screenKey);
  }, [screenKey]);

  return (
    <>
      <SettingsSegment title="Feature Image" $marginBottom="32px">
        <SettingsImageInput
          width="270px"
          height="132px"
          requiredDimensions={{ requiredWidth: 1024, requiredHeight: 500 }}
          requirementsLabel="JPG/PNG. 1024 x 500"
          acceptedFileTypes="image/png, image/jpeg"
          hideDelete
          downloadable
          filename={properties.AndroidFeatureGraphic}
          scaleSpinner={30}
          onFilenameChange={(filename) => handleFilenameChange('AndroidFeatureGraphic', filename)}
        />
      </SettingsSegment>
      <AppPropertyTextInput
        label="Reset Password URL"
        placeholder="www.vidapp.com"
        property="ResetPasswordURL"
        $width="100%"
      />
      <SettingsSegmentDivider />
      <SettingsGroup title="Purchase Screen 1 (For Subscription Only Purchases)" $marginBottom="0">
        <TextOneInput
          name="CustomPurchaseScreen1_SubscriptionOnly_Text1"
          appName={appName}
          onFocus={() => setPurchaseScreenView('Subscription1')}
        />
        <TextTwoInput
          name="CustomPurchaseScreen1_SubscriptionOnly_Text2"
          placeholder="Join now or explore the app to see what's inside."
          onFocus={() => setPurchaseScreenView('Subscription1')}
        />
        <ButtonTextInput
          name="CustomPurchaseScreen1_SubscriptionOnly_SubscribeButtonText"
          label="Subscribe Button"
          placeholder="Join now"
          onFocus={() => setPurchaseScreenView('Subscription1')}
        />
        <ButtonTextInput
          name="CustomPurchaseScreen_BrowseButtonText"
          label="Browse Button"
          placeholder="Explore the app"
          onFocus={() => setPurchaseScreenView('Subscription1')}
        />
        <ButtonTextInput
          name="CustomPurchaseScreen1_SubscriptionOnly_LoginButtonText"
          label="Login Button"
          placeholder="Already a member? Sign in"
          onFocus={() => setPurchaseScreenView('Subscription1')}
        />
      </SettingsGroup>
      <SettingsSegmentDivider />
      <SettingsGroup title="Purchase Screen 2 (For Subscription Only Purchases)" $marginBottom="12px">
        <SettingsSegment title="Hero Image" $marginBottom="32px">
          <SettingsImageInput
            width={`${PURCHASE_SCREEN_IMAGE_WIDTH}px`}
            height={`${PURCHASE_SCREEN_IMAGE_HEIGHT}px`}
            requiredDimensions={{
              requiredWidth: 2200,
              requiredHeight: 2610,
            }}
            requirementsLabel={
              <RequirementsLabel>
                <b>JPG/PNG</b>
                <br />
                2200 x 2610
                <br />
                <br />
                This image will resize to the user's device. We recommend keeping the focal point of your image within
                the guiding lines.
                <br />
                <br />
                This image may not display depending on the device and number of Offers you have.
              </RequirementsLabel>
            }
            acceptedFileTypes="image/png, image/jpeg"
            horizontalGuide={`${PURCHASE_SCREEN_IMAGE_HEIGHT / 1.95}px`}
            hideDelete
            downloadable
            filename={properties.PurchaseScreenImage}
            scaleSpinner={20}
            onFilenameChange={(filename) => handleFilenameChange('PurchaseScreenImage', filename)}
          />
        </SettingsSegment>
        <TextOneInput
          name="CustomPurchaseScreen2_SubscriptionOnly_Text1"
          appName={appName}
          placeholder={`Sign up to ${appName ?? 'the app'}`}
          onFocus={() => setPurchaseScreenView('Subscription2')}
        />
        <TextTwoInput
          name="CustomPurchaseScreen2_SubscriptionOnly_Text2"
          placeholder="Select the subscription plan that works for you and get unlimited access."
          onFocus={() => setPurchaseScreenView('Subscription2')}
        />
        <SettingsSegment title="Settings">
          <AppPropertyCheckbox
            label="Show sale percentage tag on applicable offers"
            property="ShowOfferSalePercentage"
          />
        </SettingsSegment>
      </SettingsGroup>
      {hasBuyToOwn && (
        <>
          <SettingsSegmentDivider />
          <SettingsGroup title="Purchase Screen 1 (For Buy To Own Only Purchases)" $marginBottom="0">
            <TextOneInput
              name="CustomPurchaseScreen1_BuyToOwnOnly_Text1"
              appName={appName}
              onFocus={() => setPurchaseScreenView('Purchase1')}
            />
            <TextTwoInput
              name="CustomPurchaseScreen1_BuyToOwnOnly_Text2"
              placeholder="Almost there! Select the purchase option that works for you."
              onFocus={() => setPurchaseScreenView('Purchase1')}
            />
            <ButtonTextInput
              name="CustomPurchaseScreen1_BuyToOwnOnly_BuyToOwnButtonText"
              label="Buy-To-Own Button"
              placeholder="Buy-to-own"
              onFocus={() => setPurchaseScreenView('Purchase1')}
            />
            <ButtonTextInput
              name="CustomPurchaseScreen1_BuyToOwnOnly_LoginButtonText"
              label="Login Button"
              placeholder="Already a member? Sign in"
              onFocus={() => setPurchaseScreenView('Purchase1')}
            />
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Purchase Screen 2 (For Buy To Own Only Purchases)" $marginBottom="12px">
            <TextOneInput
              name="CustomPurchaseScreen2_BuyToOwnOnly_Text1"
              appName={appName}
              placeholder={`Sign up to ${appName ?? 'the app'}`}
              onFocus={() => setPurchaseScreenView('Purchase2')}
            />
            <TextTwoInput
              name="CustomPurchaseScreen2_BuyToOwnOnly_Text2"
              placeholder="Select the buy-to-own option that works for you and get unlimited access."
              onFocus={() => setPurchaseScreenView('Purchase2')}
            />
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Purchase Screen 1 (For Subscription and Buy To Own Purchases)" $marginBottom="0">
            <TextOneInput name="CustomPurchaseScreen1_SubscriptionAndBuyToOwn_Text1" appName={appName} />
            <TextTwoInput
              name="CustomPurchaseScreen1_SubscriptionAndBuyToOwn_Text2"
              placeholder="Join now or explore the app to see what's inside."
            />
            <ButtonTextInput
              name="CustomPurchaseScreen1_SubscriptionAndBuyToOwn_SubscribeButtonText"
              label="Subscribe Button"
              placeholder="Join now"
            />
            <ButtonTextInput
              name="CustomPurchaseScreen1_SubscriptionAndBuyToOwn_BuyToOwnButtonText"
              label="Buy-To-Own Button"
              placeholder="Buy-to-own"
            />
            <ButtonTextInput
              name="CustomPurchaseScreen1_SubscriptionAndBuyToOwn_LoginButtonText"
              label="Login Button"
              placeholder="Already a member? Sign in"
            />
          </SettingsGroup>
        </>
      )}
    </>
  );
};
