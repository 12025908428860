import { format, fromUnixTime } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { AppPurchase } from 'api';
import { CustomCell, CustomTable, FilterDropdown, PageContainer, SearchBar, TableColumn, TypeLabel } from 'components';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { useAppPurchases, useAppPurchasesOptions, useProducts } from 'hooks';
import { DollarSignIcon, MobileAndroidIcon, RocketIcon } from 'icons';
import { NEUTRAL_8_COLOUR, PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

export interface PurchaseFilters {
  platform?: string[];
  store_product_id?: string[];
  status?: string[];
}

const DEVICE_MAP: Record<string, string> = {
  Apple: 'iOS',
  AppleTV: 'Apple TV',
  AndroidTV: 'Android TV',
  AmazonFireTV: 'Amazon Fire TV',
};

const PAGE_SIZE = 20;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SearchBarWrapper = styled.div`
  margin-right: 12px;
`;

const Total = styled.div`
  ${FONT_14PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;

const Ellipsis = styled.div`
  ${OVERFLOW_ELLIPSIS};
  ${FONT_14PX_MEDIUM};
`;

const SmallEllipsis = styled(Ellipsis)`
  ${FONT_12PX_MEDIUM};
`;

const getDurationLabel = (duration?: string) => {
  if (!duration) {
    return '';
  }

  const number = duration.charAt(0);
  const unit = duration.charAt(1) === 'y' ? 'Year' : 'Month';
  return `${number} ${unit}${number === '1' ? '' : 's'}`;
};

const getFilterValue = (value?: string[]) => (value && value.length > 0 ? value.join(',') : undefined);

export const AppPurchases = () => {
  const [page, setPage] = useState(1);
  const {
    data: productsData,
    getProductByProductId,
    isLoading: productsIsLoading,
    isError: productsIsError,
  } = useProducts();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get('search') ?? '';
  const [filters, setFilters] = useState<PurchaseFilters>({});

  const appliedFilters = useMemo(() => {
    const obj: useAppPurchasesOptions = {};
    for (const [key, value] of Object.entries(filters)) {
      const val = getFilterValue(value);
      if (val) {
        obj[key as keyof useAppPurchasesOptions] = val;
      }
    }
    return obj;
  }, [filters]);

  const { purchases, total, fetchNextPage, isLoading, isError } = useAppPurchases({
    query: query === '' ? undefined : query,
    ...appliedFilters,
  });

  const columns: TableColumn<AppPurchase & { id: string }>[] = useMemo(
    () => [
      {
        heading: 'Purchase Email',
        width: 'grow',
        minWidth: 270,
        render: ({ OriginalPurchaseEmail }) => (
          <CustomCell>
            <Ellipsis>{OriginalPurchaseEmail}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Platform',
        width: 85,
        render: ({ DevicePurchasedOn }) => (
          <CustomCell>
            <SmallEllipsis>{DEVICE_MAP[DevicePurchasedOn] ?? DevicePurchasedOn}</SmallEllipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Original Purchase Date',
        width: 158,
        render: ({ OriginalPurchaseTs }) => (
          <CustomCell>
            <SmallEllipsis>{format(fromUnixTime(OriginalPurchaseTs), 'MMMM dd, yyyy')}</SmallEllipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Latest Transaction Date',
        width: 162,
        render: ({ Transaction }) => (
          <CustomCell>
            <SmallEllipsis>{format(fromUnixTime(Transaction.PurchaseTs), 'MMMM dd, yyyy')}</SmallEllipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Offer',
        width: 'grow',
        render: ({ Transaction }) => {
          const product = getProductByProductId(Transaction.StoreProductId);
          return (
            <CustomCell>
              <SmallEllipsis>
                {product?.DisplayName ?? product?.CustomDisplayName ?? `Unknown (${Transaction.StoreProductId})`}
              </SmallEllipsis>
            </CustomCell>
          );
        },
      },
      {
        heading: 'Duration',
        width: 80,
        render: ({ Transaction }) => {
          const product = getProductByProductId(Transaction.StoreProductId);
          return (
            <CustomCell>
              <SmallEllipsis>{getDurationLabel(product?.SubscriptionDuration)}</SmallEllipsis>
            </CustomCell>
          );
        },
      },
      {
        heading: 'Subscription Status',
        width: 140,
        render: ({ Transaction }) => {
          const dateNow = new Date();
          const timeNow = Math.floor(dateNow.getTime() / 1000);
          const status =
            Transaction.ExpirationTs < timeNow ? 'Expired' : Transaction.Trial === 1 ? 'Free Trial' : 'Active';
          return (
            <CustomCell>
              <TypeLabel $danger={status === 'Expired'} $neutral={status === 'Free Trial'}>
                {status}
              </TypeLabel>
            </CustomCell>
          );
        },
      },
    ],
    [getProductByProductId],
  );

  const availableFilters = useMemo(
    () => [
      {
        title: 'Platform',
        key: 'platform',
        icon: <MobileAndroidIcon />,
        options: [{ name: 'Apple', label: 'iOS' }, { name: 'Android' }, { name: 'AppleTV', label: 'Apple TV' }],
        // multiSelect: true,
      },
      {
        title: 'Offer',
        key: 'store_product_id',
        icon: <DollarSignIcon />,
        options: productsData
          ? productsData.products
              .map((product) => ({
                name: product.ProductId,
                label: product.DisplayName ?? product.CustomDisplayName,
              }))
              .filter(({ label }) => !!label)
              .sort((a, b) => a.label.localeCompare(b.label))
          : [],
      },
      {
        title: 'Status',
        key: 'status',
        icon: <RocketIcon />,
        options: [
          { name: 'Active' },
          { name: 'Expired', danger: true },
          { name: 'Trial', label: 'Free Trial', neutral: true },
        ],
        useTypeLabel: true,
        multiSelect: true,
      },
      // {
      //   title: 'Duration',
      //   key: 'duration',
      //   icon: <CalendarIcon />,
      //   options: [
      //     {
      //       name: '1m',
      //       label: '1 month',
      //     },
      //     {
      //       name: '3m',
      //       label: '3 months',
      //     },
      //     {
      //       name: '6m',
      //       label: '6 months',
      //     },
      //     {
      //       name: '1y',
      //       label: '1 year',
      //     },
      //   ],
      // },
    ],
    [productsData],
  );

  const totalTablePages = purchases.length / PAGE_SIZE;

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();
      const searchParam = searchInput !== '' ? `&search=${searchInput}` : '';

      history.replace({
        search: searchParam,
      });

      setPage(1);
    },
    [history],
  );

  return (
    <PageContainer
      heading="App Purchases"
      subheading="View the purchases record made through the app stores in the table below."
      isError={isError || productsIsError}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
      isBeta
    >
      <TableHeader>
        <SearchWrapper>
          <SearchBarWrapper>
            <SearchBar value={query} onSearch={handleSearch} width="293px" />
          </SearchBarWrapper>
          <FilterDropdown
            availableFilters={availableFilters}
            handleChange={(value) => {
              setFilters(value);
              setPage(1);
            }}
            total={Object.keys(appliedFilters).length}
          />
        </SearchWrapper>
        <Total>{!isLoading && `${total} App Purchases`}</Total>
      </TableHeader>
      <CustomTable
        isLoading={isLoading || productsIsLoading}
        pageSize={PAGE_SIZE}
        columns={columns}
        data={purchases.map((purchase) => ({ ...purchase, id: purchase.Id.toString() }))}
        emptyTitle=""
        emptyDescription="No purchases"
        overridePage={page}
        onPageChange={(page) => {
          setPage(page);
          // If on the second to last or last table page, fetch more data
          if (page >= totalTablePages - 1) {
            fetchNextPage();
          }
        }}
      />
    </PageContainer>
  );
};
