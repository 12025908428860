import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useAxiosInstance } from 'api';
import { useUploadFile } from 'hooks';
import { InfoModal } from 'components/index';
import { NEUTRAL_7_COLOUR } from 'theme';

import { useAppBeingEdited } from '../../../app-context';
import { LoadingSpinner } from '../../Loading/LoadingSpinner/LoadingSpinner';
import { DEFAULT_MAX_FILE_SIZE } from 'utils';

const Container = styled.div`
  width: 100%;
`;

const LabelWrapper = styled.div`
  margin-left: 10px;
`;

const FilenameLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${NEUTRAL_7_COLOUR};
`;

interface FileUploaderProps {
  acceptedFileTypes?: string;
  maxFileSize?: number;
  overrideRef?: RefObject<HTMLInputElement>;
  filename?: string;
  useOriginalFilename?: boolean;
  filePath?: string;
  onFilenameChange: (filename: string) => void;
  onFileSelected?: (file: File) => void;
  handleError?: () => void;
  handleMaxSizeExceeded?: () => void;
}

export const FileUploader = ({
  maxFileSize,
  acceptedFileTypes,
  overrideRef,
  filename,
  useOriginalFilename,
  filePath,
  onFilenameChange,
  onFileSelected,
  handleError,
  handleMaxSizeExceeded,
  ...props
}: FileUploaderProps) => {
  const localRef = useRef<HTMLInputElement>(null);
  const appBeingEdited = useAppBeingEdited();
  const axiosInstance = useAxiosInstance();
  const uploadFile = useUploadFile();

  const [loading, setLoading] = useState(false);

  const fileInputRef = useMemo(() => {
    // Either generate a ref locally or provide one externally
    // External is useful when we want to trigger a click on this input from other components
    return overrideRef ? overrideRef : localRef;
  }, [overrideRef, localRef]);

  const handleFileChange = useCallback(async () => {
    if (fileInputRef.current?.files && fileInputRef.current?.files.length > 0) {
      setLoading(true);
      const file = fileInputRef.current?.files[0];
      if (acceptedFileTypes && !acceptedFileTypes.includes(file.type)) {
        const err = "File isn't an accepted file type";
        console.error(`${err}: ${acceptedFileTypes}`);
        InfoModal({
          title: 'Incorrect File Type',
          content: `Looks like the file you uploaded isn’t the correct file type. Please try again.`,
          type: 'warning',
        });
        setLoading(false);
        return;
      }

      const maxSize = maxFileSize ?? DEFAULT_MAX_FILE_SIZE;
      const maxFileSizeInBytes = maxSize * 1048576;
      if (file.size > maxFileSizeInBytes) {
        const err = 'File exceeds maximum allowed file size';
        console.error(`${err}: ${maxFileSize}`);
        if (handleMaxSizeExceeded) {
          handleMaxSizeExceeded();
        } else {
          InfoModal({
            title: 'Maximum File Size Exceeded',
            content: `Looks like the file you uploaded is larger than the maximum allowed size: ${maxFileSize}MB. Please try again.`,
            type: 'warning',
          });
        }
        setLoading(false);
        return;
      }

      onFileSelected && onFileSelected(file);

      uploadFile.mutate(
        { file: file, options: { filePath, useOriginalFilename } },
        {
          onSuccess: (filename) => {
            setLoading(false);
            onFilenameChange(filename);
          },
          onError: () => {
            handleError
              ? handleError()
              : InfoModal({
                  title: 'Upload Failed',
                  content: `Looks like something went wrong. Please try again or contact VidApp support for help.`,
                  type: 'error',
                });
            setLoading(false);
          },
        },
      );
    }
  }, [
    uploadFile,
    fileInputRef,
    appBeingEdited,
    axiosInstance,
    setLoading,
    onFileSelected,
    handleMaxSizeExceeded,
    handleError,
  ]);

  return (
    <Container {...props}>
      <LabelWrapper>
        {loading ? <LoadingSpinner fontSize="16px" /> : <FilenameLabel>{filename ?? 'No File Uploaded'}</FilenameLabel>}
      </LabelWrapper>
      <input
        type="file"
        accept={acceptedFileTypes}
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </Container>
  );
};
