import { useCallback } from 'react';

import {
  SettingsGroup,
  SettingsRadioImageInput,
  SettingsSegment,
  SettingsSegmentDivider,
  SettingsSelectInput,
} from 'components';
import { useLocalAppProperties, useSaveContext } from 'providers';
import { BUILDER_IMAGES_DOMAIN } from 'utils';

import { AppPropertyCheckbox, AppPropertyColorInput, AppPropertyTextInput } from 'app/modules/design-screens';
import { VideoDownloadQualitySettings } from './components/VideoDownloadQualitySettings';

const TEXT_INPUT_MAX = 15;

const itemDetailsDesignOptions = {
  options: [
    {
      name: 'Buttons Below Player, Expandable',
      value: 'ButtonsBelowPlayerDropdown',
    },
    {
      name: 'Buttons Below Player, Sub-Nav',
      value: 'ButtonsBelowPlayerSegment',
    },
    {
      name: 'Buttons Below Player, Resources',
      value: 'ButtonsBelowPlayerResources',
    },
  ],
  default: 'ButtonsBelowPlayerDropdown',
};

export const GeneralSettings = () => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();

  const handlePropertyChange = useCallback(
    (property: string, value: string) => {
      setAppProperty(property, value);
      setAppPropertyToSave(property, value);
    },
    [setAppProperty, setAppPropertyToSave],
  );

  return (
    <>
      <SettingsGroup
        title="Item Display"
        description="Customize the layout and labels on your lesson or video screens."
      >
        <SettingsSelectInput
          label="Item Details Screen Layout"
          value={properties.ItemDetailsDesign ?? itemDetailsDesignOptions.default}
          onChange={(value) => handlePropertyChange('ItemDetailsDesign', value)}
          options={itemDetailsDesignOptions.options}
          width="320px"
        />
      </SettingsGroup>
      <SettingsGroup wrapRow $marginBottom="32px">
        <AppPropertyTextInput label="About" property="CustomVideoAboutLabel" maxLength={TEXT_INPUT_MAX} />
        <AppPropertyTextInput label="Resources" property="CustomVideoResourcesLabel" maxLength={TEXT_INPUT_MAX} />
        <AppPropertyTextInput label="Up Next" property="CustomVideoUpNextLabel" maxLength={TEXT_INPUT_MAX} />
        <AppPropertyTextInput label="Comments" property="CustomVideoCommentsLabel" maxLength={TEXT_INPUT_MAX} />
        <AppPropertyCheckbox label="Lock video details screen until purchased" property="LockVideoDetailsScreen" />
      </SettingsGroup>
      <SettingsSegmentDivider />
      <SettingsGroup
        title="Group Display"
        description="Customize the labels on the group screens."
        wrapRow
        $marginBottom="0"
      >
        <AppPropertyTextInput label="Videos" property="CustomCollectionVideosLabel" maxLength={TEXT_INPUT_MAX} />
        <AppPropertyTextInput
          label="Collections"
          property="CustomCollectionCollectionsLabel"
          maxLength={TEXT_INPUT_MAX}
        />
        <AppPropertyTextInput label="About" property="CustomCollectionAboutLabel" maxLength={TEXT_INPUT_MAX} />
      </SettingsGroup>
      <SettingsSegmentDivider />
      <SettingsGroup title="Thumbnail Display" description="Customize the display of thumbnails" $marginBottom="32px">
        <AppPropertyCheckbox label="Hide timestamp on video thumbnails" property="HideTimestampOnVideoThumbnails" />
        <AppPropertyCheckbox
          label="Hide item count on collection thumbnails"
          property="HideItemCountOnCollectionThumbnails"
        />
        <AppPropertyCheckbox label="Hide completed tick on thumbnails" property="HideCompletedTick" />
      </SettingsGroup>
      <SettingsSegmentDivider />
      <SettingsGroup title="Speed Controller" $marginBottom="32px">
        <AppPropertyCheckbox label="Hide video speed controller" property="ShowVideoSpeed" inverseValue />
        <AppPropertyCheckbox label="Hide audio speed controller" property="ShowAudioSpeed" inverseValue />
      </SettingsGroup>
      <SettingsSegmentDivider />
      <VideoDownloadQualitySettings />
      <SettingsGroup title="Play Button" description="Customize the color of the button on the video player.">
        <AppPropertyColorInput property="CustomPlayButtonColor" />
      </SettingsGroup>
      <SettingsSegmentDivider />
      <SettingsGroup title="Course / Program Display">
        <SettingsSegment>
          <SettingsRadioImageInput
            centered
            value={properties.CourseTemplateDesign ?? 'Thumbnails'}
            onChange={(e) => handlePropertyChange('CourseTemplateDesign', e.target.value)}
            options={[
              {
                name: 'Thumbnails',
                value: 'Thumbnails',
                image: {
                  url: `${BUILDER_IMAGES_DOMAIN}/onboarding/${
                    properties?.ColorScheme === 'Light' ? 'light' : 'dark'
                  }_thumbnails.webp`,
                  width: '196px',
                  height: '405px',
                },
                description: 'Select this if the majority of your content has thumbnails.',
              },
              {
                name: 'Icons',
                value: 'NoThumbnails',
                image: {
                  url: `${BUILDER_IMAGES_DOMAIN}/onboarding/${
                    properties?.ColorScheme === 'Light' ? 'light' : 'dark'
                  }_no_thumbnails.webp`,
                  width: '196px',
                  height: '405px',
                },
                description: "Select this if the majority of your content doesn't have thumbnails.",
              },
            ]}
          />
        </SettingsSegment>
        <SettingsSegment title="Mark Completed Content" $marginBottom="32px">
          <AppPropertyCheckbox
            label="Show ‘Completed’ icon for posts that have been completed by a user. Only applicable with the Course / Program display type."
            property="ItemCompleted"
          />
        </SettingsSegment>
      </SettingsGroup>
    </>
  );
};
