import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { Segment, SelectOption, SettingsSelectInput } from 'components';
import { getSourceTypeDisplayName } from 'utils';
import { useTemplates } from 'hooks';
import { ITEM_TYPE_COLLECTION, Template } from 'api';

import {
  getExcludedTemplates,
  getFormattedTemplateName,
  groupTemplates,
} from 'app/modules/build-dragdrop/Builder/util';
import { TemplateThumbnail } from 'app/modules/build-dragdrop/Builder/components';
import { FONT_10PX_SEMIBOLD, FONT_12PX_MEDIUM } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR } from 'theme';

const SELECT_HEIGHT = '84px';

const getTemplateDisplayName = (t: Template) => {
  const nameObj = getFormattedTemplateName(t);
  if (nameObj.variant) {
    return `${nameObj.name} (${nameObj.variant})`;
  }
  return nameObj.name;
};

const StyledSelect = styled(SettingsSelectInput)`
  &&&& .ant-select-selector {
    height: ${SELECT_HEIGHT} !important;

    .ant-select-selection-item {
      height: ${SELECT_HEIGHT};
    }
  }
`;

const Label = styled.div`
  display: flex;
  height: ${SELECT_HEIGHT};
  align-items: center;
`;

const NameWrapper = styled.div`
  margin-left: 12px;
`;

const GroupName = styled.div`
  ${FONT_10PX_SEMIBOLD};
  color: ${NEUTRAL_8_COLOUR};
`;

const TemplateName = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
`;

const StyledTemplateThumbnail = styled(TemplateThumbnail)`
  width: 36px;
`;

interface CollectionTemplateSelectProps {
  collection: BuilderCollection;
}

export const CollectionTemplateSelect = ({ collection }: CollectionTemplateSelectProps) => {
  const { setCollectionTemplate } = useContent();
  const { setCollectionValueToSave } = useSaveContext();
  const { getTemplateOptionsByType } = useTemplates();

  const groupedTemplates = useMemo(() => {
    const templates = getTemplateOptionsByType(ITEM_TYPE_COLLECTION);
    if (templates) {
      return groupTemplates(
        templates,
        getExcludedTemplates({
          allowCourseView: true,
        }),
      );
    }

    return [];
  }, [getTemplateOptionsByType]);

  const options = useMemo(() => {
    const arr: SelectOption[] = [];
    groupedTemplates.forEach(([groupName, templates]) => {
      if (templates.length > 0) {
        arr.push(
          ...templates.map((t) => ({
            label: (
              <Label>
                <StyledTemplateThumbnail templateId={t.TemplateId} isPage />
                <NameWrapper>
                  <GroupName>{groupName}</GroupName>
                  <TemplateName>{getTemplateDisplayName(t)}</TemplateName>
                </NameWrapper>
              </Label>
            ),
            name: t.TemplateId.toString(),
            value: t.TemplateId.toString(),
          })),
        );
      }
    });
    return arr;
  }, [groupedTemplates]);

  const handleChange = useCallback(
    (value: string) => {
      if (collection) {
        const templateId = parseInt(value);
        setCollectionTemplate(collection, templateId);
        setCollectionValueToSave(collection.TabId, 'TemplateId', templateId);
      }
    },
    [setCollectionTemplate, setCollectionValueToSave],
  );

  return (
    <Segment
      header={`${getSourceTypeDisplayName(collection.SourceType, collection.DataSource)} Layout`}
      $marginTop="0px"
      $marginBottom="32px"
    >
      <StyledSelect options={options} value={collection.TemplateId.toString()} onChange={handleChange} />
    </Segment>
  );
};
