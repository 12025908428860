import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

import { AppProperties, getAppProperties, PAYMENT_STATUS_INVOICED, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useAppBasicInfo } from './useAppBasicInfo';

export const useAppProperties = (options?: UseQueryOptions) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const { data: appBasicInfo } = useAppBasicInfo();
  const query = useQuery<AppProperties>(['appProperties', appId], () => getAppProperties(client, appId), {
    staleTime: options?.staleTime ?? 30000,
    refetchOnMount: options?.refetchOnMount ?? true,
    enabled: !!appId, // Don't call fetcher with empty AppId
  });

  const { data } = query;

  // These properties should return default values if undefined
  const defaults = useMemo(
    () => [
      {
        property: 'DisplayOnboardingChecklist',
        defaultValue: appBasicInfo?.PaymentStatus === PAYMENT_STATUS_INVOICED ? '0' : '1',
      },
    ],
    [appBasicInfo?.PaymentStatus],
  );

  const withDefaults = useMemo(() => {
    if (data) {
      const modifiedData: AppProperties = { ...data };
      defaults.forEach(({ property, defaultValue }) => {
        if (!(property in modifiedData)) {
          modifiedData[property] = defaultValue;
        }
      });
      return modifiedData;
    }
    return data;
  }, [data, defaults]);

  return {
    ...query,
    data: withDefaults,
  };
};
