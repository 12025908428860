import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CustomButton, Modal, ModalBodyNavigationGroup, ModalCollectionOptions, ModalVideoOptions } from 'components';
import {
  BuilderCollection,
  BuilderCollectionItem,
  BuilderVideo,
  useContent,
  useDataSource,
  useSaveContext,
} from 'providers';
import { FONT_16PX_MEDIUM } from 'font';
import { INPUT_BORDER_RADIUS, NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import {
  CollectionIcon,
  DumbbellIcon,
  FileBlankIcon,
  FolderIcon,
  GlobeIcon,
  PostIcon,
  TextImageIcon,
  UISyncIcon,
  VideoIcon,
} from 'icons';
import {
  checkVideoType,
  collectionIsProtected,
  getCollectionValuesFromNewCollection,
  getDataSourceDisplayName,
  getVideoValuesFromNewVideo,
} from 'utils';
import {
  CATALOG_SOURCE_ID,
  COLLECTION_PROPERTY_HTMLPAGE_DESC,
  COLLECTION_PROPERTY_WEBSITE_URL,
  DEFAULT_COLLECTION,
  DEFAULT_COLLECTION_ITEM,
  DEFAULT_FILE,
  DEFAULT_POST,
  FEATURED_SOURCE_ID,
  GROUP_LABEL,
  ITEM_TYPE_COLLECTION,
  ITEM_TYPE_VIDEO,
  MY_PRODUCTS_SOURCE_ID,
  SOURCE_CUSTOMAPI,
  SOURCE_JWPLAYER,
  SOURCE_KAJABI,
  SOURCE_TYPE_CIRCUIT,
  SOURCE_TYPE_COLLECTION,
  SOURCE_TYPE_KAJABI_CAT,
  SOURCE_TYPE_KAJABI_SUBCAT,
  SOURCE_TYPE_TEXT,
  SOURCE_TYPE_USCREEN_CAT,
  SOURCE_TYPE_VHX_CAT,
  SOURCE_TYPE_VHX_SEAS,
  SOURCE_TYPE_WEBSITE,
  SOURCE_TYPE_WORKOUT,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
  SOURCE_YOUTUBE,
  TEXT_IMAGE_TEMPLATE,
  VIDEO_TYPE_POST,
  WEBSITE_TEMPLATE,
} from 'api';
import { useAppBeingEdited } from 'app-context';

import { ContentPreview, FileView, PostView, TextImageView, WebsiteView } from './components';
import { useModalContext } from './providers/modal-provider';
import { useContentNavigationContext } from '../../providers';
import { useAppProperties } from 'hooks';

const ModalHeading = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_9_COLOUR};
  padding: 20px;
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
`;
const ModalBody = styled.div`
  display: flex;
  color: ${NEUTRAL_10_COLOUR};
  height: 516px;
`;
const ModalActions = styled.div`
  display: flex;
  justify-content: right;
  padding: 20px 24px;
  border-top: 1px solid ${NEUTRAL_5_COLOUR};
`;

const CancelButton = styled(CustomButton)`
  &&&& {
    margin-right: 8px;
  }
`;

const SaveButton = styled(CustomButton)`
  &&&& {
    font-weight: 500;
    border-radius: ${INPUT_BORDER_RADIUS};
  }
`;

const BodyNavigation = styled.div`
  padding: 20px;
  width: 186px;
  height: 100%;
  border-right: 1px solid ${NEUTRAL_5_COLOUR};
  overflow-y: auto;
`;

const StyledCollectionIcon = styled(CollectionIcon)`
  svg {
    fill: none;
  }
`;

const LeftPanel = styled.div`
  width: 379px;
  height: 100%;
`;

const getThumbnails = (entity: { thumbnail?: string; squareThumbnail?: string; portraitThumbnail?: string }) => {
  return {
    Thumbnails: { source: entity.thumbnail ?? '' },
    SquareThumbnails: { source: entity.squareThumbnail ?? '' },
    PortraitThumbnails: { source: entity.portraitThumbnail ?? '' },
    ThumbnailSource: entity.thumbnail ?? '',
    SquareThumbnailSource: entity.squareThumbnail ?? '',
    PortraitThumbnailSource: entity.portraitThumbnail ?? '',
  };
};

type PendingFn = () => void;
export const CMSModal = () => {
  const appId = useAppBeingEdited();
  const datasource = useDataSource();
  const { data: appProperties } = useAppProperties();
  const { videos, collections, setCollectionItems, setNewCollection, setCollectionProperty, setNewVideo } =
    useContent();
  const [activeKey, setActiveKey] = useState<string>();
  const { setCollectionItemsToSave, getTempId, setCollectionToSave, setCollectionPropertyToSave, setVideoToSave } =
    useSaveContext();
  const [pendingSaves, setPendingSaves] = useState<PendingFn[]>([]);
  const { confirmDisabled, modalCollectionId, setModalCollectionId } = useModalContext();
  const { CMSEnabled, circuitsEnabled } = useContentNavigationContext();

  const countInDefault = appProperties?.PreExerciseCountInDefault
    ? parseInt(appProperties?.PreExerciseCountInDefault)
    : 10;

  const collection = useMemo(
    () => (modalCollectionId ? collections[modalCollectionId] : undefined),
    [collections, modalCollectionId],
  );

  const [editedItems, setEditedItems] = useState<BuilderCollectionItem[]>(collection?.Items || []);

  const modalType = collection?.SourceType;
  const canAddVideos = modalType && [SOURCE_TYPE_COLLECTION, SOURCE_TYPE_CIRCUIT].includes(modalType);

  // The content that already exists in the current collection
  const includedVideos = useMemo(() => {
    return editedItems.filter((item) => item.Type === ITEM_TYPE_VIDEO).map((item) => item.ChildId);
  }, [editedItems]);
  const includedCollections = useMemo(() => {
    return editedItems.filter((item) => item.Type === ITEM_TYPE_COLLECTION).map((item) => item.ChildId);
  }, [editedItems]);

  // Save the working state of the collection
  const handleSave = useCallback(async () => {
    if (collection) {
      // Setup save for any pending newly created videos/collections
      for (const fn of pendingSaves) {
        fn();
      }

      // Update the dragdrop state to the new items
      setCollectionItems(collection.TabId, editedItems);
      setCollectionItemsToSave(collection.TabId); // Set to be saved
      setModalCollectionId(undefined);
    }
  }, [editedItems, setModalCollectionId, pendingSaves]);

  // The following determines the 1 available collection tab for the main integration of the app,
  // As well as the WordPress/JWPlayer/Vimeo/YouTube collection tabs
  // and the filtered down collection content in each
  const {
    Collections1,
    CollectionsVidAppCollections,
    CollectionsVidAppWorkouts,
    CollectionsVidAppCircuits,
    CollectionsWordPress,
    CollectionsJWPlayer,
    CollectionsVimeo,
    CollectionsYouTube,
    CollectionsCustomAPI,
  } = useMemo(() => {
    const collectionsBySource: Record<string, BuilderCollection[]> = {
      [datasource]: [],
      [SOURCE_WORDPRESS]: [],
      [SOURCE_JWPLAYER]: [],
      [SOURCE_VIMEO]: [],
      [SOURCE_YOUTUBE]: [],
      [SOURCE_VIDAPP]: [],
    };

    collectionsBySource[SOURCE_CUSTOMAPI] = []; // Sometimes its also the app datasource

    if (CMSEnabled || modalType === SOURCE_TYPE_WORKOUT) {
      Object.values(collections).forEach((collection) => {
        if (modalType !== SOURCE_TYPE_CIRCUIT) {
          if (
            (collection.DataSource === SOURCE_VIDAPP || modalType !== SOURCE_TYPE_WORKOUT) &&
            collectionsBySource[collection.DataSource]
          ) {
            collectionsBySource[collection.DataSource].push(collection);
          }
        }
      });
    }

    const otherSources = {
      CollectionsVidAppCollections: {
        Title: `${GROUP_LABEL}s`,
        Icon: <StyledCollectionIcon />,
        Options: collectionsBySource[SOURCE_VIDAPP].filter(
          ({ Position, SourceId, TemplateId, SourceType, TabId }) =>
            SourceType === SOURCE_TYPE_COLLECTION &&
            collection?.TabId !== TabId &&
            (!Position || Position === 0) && // Filter out main tabs
            !collectionIsProtected(SourceId) && // Filter out default Self-Onboarding collections
            !SourceId.includes('TempTabId') && // Filter out temp tabs
            ![4, 5, 25, 34, 35].includes(TemplateId), // Filter out Workouts and Circuits, text/image, websites
        ),
      },
      CollectionsVidAppWorkouts: {
        Title: `Workouts`,
        Icon: <DumbbellIcon />,
        Options: collectionsBySource[SOURCE_VIDAPP].filter(({ SourceType }) => SourceType === SOURCE_TYPE_WORKOUT),
      },
      CollectionsVidAppCircuits: {
        Title: `Circuits`,
        Icon: <UISyncIcon />,
        Options: collectionsBySource[SOURCE_VIDAPP].filter(({ SourceType }) => SourceType === SOURCE_TYPE_CIRCUIT),
      },
      CollectionsWordPress: {
        Title: 'Playlists',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_WORDPRESS],
      },
      CollectionsJWPlayer: {
        Title: 'Playlists',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_JWPLAYER],
      },
      CollectionsVimeo: {
        Title: 'Albums',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_VIMEO],
      },
      CollectionsYouTube: {
        Title: 'Playlists',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_YOUTUBE],
      },
      CollectionsCustomAPI: {
        Title: 'Groups',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_CUSTOMAPI],
      },
    };

    if (datasource == SOURCE_KAJABI) {
      return {
        Collections1: {
          Title: 'Modules',
          Icon: <FolderIcon />,
          Options: collectionsBySource[datasource].filter(
            ({ SourceType }) => SourceType === SOURCE_TYPE_KAJABI_CAT || SourceType === SOURCE_TYPE_KAJABI_SUBCAT,
          ),
        },
        ...otherSources,
      };
    } else if (datasource === SOURCE_VHX) {
      return {
        Collections1: {
          Title: 'Collections',
          Icon: <StyledCollectionIcon />,
          Options: collectionsBySource[datasource].filter(
            ({ SourceType, SourceId }) =>
              SourceType !== SOURCE_TYPE_VHX_CAT &&
              SourceType !== SOURCE_TYPE_VHX_SEAS &&
              SourceId !== CATALOG_SOURCE_ID &&
              SourceId !== FEATURED_SOURCE_ID &&
              SourceId !== MY_PRODUCTS_SOURCE_ID,
          ),
        },
        ...otherSources,
      };
    } else if (datasource == SOURCE_USCREEN) {
      return {
        Collections1: {
          Title: 'Collections',
          Icon: <FolderIcon />,
          Options: collectionsBySource[datasource].filter(
            ({ SourceType, SourceId }) =>
              SourceType !== SOURCE_TYPE_USCREEN_CAT &&
              SourceId !== FEATURED_SOURCE_ID &&
              SourceId !== MY_PRODUCTS_SOURCE_ID,
          ),
        },
        ...otherSources,
      };
    } else if (datasource === SOURCE_VIDAPP) {
      return {
        Collections1: {
          Title: 'Collections',
          Icon: <FolderIcon />,
          Options: [],
        },
        ...otherSources,
      };
    }
    return {
      Collections1: {
        Title: 'Collections',
        Icon: <StyledCollectionIcon />,
        Options: collectionsBySource[datasource].filter(
          ({ SourceType, SourceId }) =>
            SourceType !== SOURCE_TYPE_USCREEN_CAT &&
            SourceId !== CATALOG_SOURCE_ID &&
            SourceId !== FEATURED_SOURCE_ID &&
            SourceId !== MY_PRODUCTS_SOURCE_ID,
        ),
      },
      ...otherSources,
    };
  }, [collections, datasource]);

  const videosBySource = useMemo(() => {
    const obj: Record<string, BuilderVideo[]> = {
      [datasource]: [],
      [SOURCE_WORDPRESS]: [],
      [SOURCE_JWPLAYER]: [],
      [SOURCE_VIMEO]: [],
      [SOURCE_YOUTUBE]: [],
    };
    obj[SOURCE_CUSTOMAPI] = []; // Sometimes its also the app datasource

    if (canAddVideos) {
      Object.values(videos).forEach((video) => {
        if (obj[video.DataSource] && checkVideoType(video, { audio: true, text: true })) {
          if (modalType === SOURCE_TYPE_CIRCUIT && video.DataSource === SOURCE_KAJABI) {
            if (video.Type !== VIDEO_TYPE_POST) {
              obj[video.DataSource].push(video);
            }
          } else {
            obj[video.DataSource].push(video);
          }
        }
      });
    }
    return obj;
  }, [videos, datasource]);

  // Build the list of navigation options on the left side of the modal
  const sourceOptions = useMemo(() => {
    const output = [];
    if (videosBySource[datasource].length > 0) {
      output.push({ id: '1', name: datasource === SOURCE_KAJABI ? 'Lessons' : 'Videos', icon: <VideoIcon /> });
    }
    if (Collections1.Options.length > 0 && CMSEnabled) {
      output.push({ id: '2', name: Collections1.Title, icon: Collections1.Icon });
    }

    return output;
  }, [videosBySource, Collections1, CMSEnabled]);

  const wordPressOptions = useMemo(() => {
    const output = [];
    if (videosBySource[SOURCE_WORDPRESS].length > 0) {
      output.push({ id: '4', name: 'Posts', icon: <VideoIcon /> });
    }

    if (CollectionsWordPress.Options.length > 0) {
      output.push({ id: '5', name: CollectionsWordPress.Title, icon: CollectionsWordPress.Icon });
    }

    return output;
  }, [CollectionsWordPress, videosBySource]);

  const jwPlayerOptions = useMemo(() => {
    const output = [];

    if (datasource == SOURCE_USCREEN) {
      return [];
    }

    if (videosBySource[SOURCE_JWPLAYER].length > 0) {
      output.push({ id: '6', name: 'Videos', icon: <VideoIcon /> });
    }

    if (CollectionsJWPlayer.Options.length > 0) {
      output.push({ id: '7', name: CollectionsJWPlayer.Title, icon: CollectionsJWPlayer.Icon });
    }

    return output;
  }, [CollectionsJWPlayer, videosBySource]);

  const vimeoOptions = useMemo(() => {
    const output = [];
    if (videosBySource[SOURCE_VIMEO].length > 0) {
      output.push({ id: '8', name: 'Videos', icon: <VideoIcon /> });
    }

    if (CollectionsVimeo.Options.length > 0) {
      output.push({ id: '9', name: CollectionsVimeo.Title, icon: CollectionsVimeo.Icon });
    }

    return output;
  }, [CollectionsVimeo, videosBySource]);

  const youTubeOptions = useMemo(() => {
    const output = [];
    if (videosBySource[SOURCE_YOUTUBE].length > 0) {
      output.push({ id: '10', name: 'Videos', icon: <VideoIcon /> });
    }

    if (CollectionsYouTube.Options.length > 0) {
      output.push({ id: '11', name: CollectionsYouTube.Title, icon: CollectionsYouTube.Icon });
    }

    return output;
  }, [CollectionsYouTube, videosBySource]);

  const customAPIOptions = useMemo(() => {
    const output = [];
    // Skip if app's data source is CustomAPI as this will be a duplicate
    if (datasource !== SOURCE_CUSTOMAPI) {
      if (videosBySource[SOURCE_CUSTOMAPI].length > 0) {
        output.push({ id: '12', name: 'Videos', icon: <VideoIcon /> });
      }

      if (CollectionsCustomAPI.Options.length > 0) {
        output.push({ id: '13', name: CollectionsCustomAPI.Title, icon: CollectionsCustomAPI.Icon });
      }
    }

    return output;
  }, [CollectionsCustomAPI, videosBySource]);

  const allKeys = useMemo(() => {
    const allOptions = sourceOptions.concat(
      wordPressOptions,
      jwPlayerOptions,
      vimeoOptions,
      youTubeOptions,
      customAPIOptions,
    );
    const opts = allOptions.map((option) => option.id);
    if (datasource === SOURCE_VIDAPP) {
      opts.push('16'); // VidApp Collections
    }
    return opts;
  }, [sourceOptions, wordPressOptions, jwPlayerOptions, vimeoOptions, youTubeOptions, customAPIOptions]);

  useEffect(() => {
    if (modalType === SOURCE_TYPE_WORKOUT) {
      setActiveKey('18');
    } else if (allKeys.length > 0 && !activeKey) {
      setActiveKey(
        allKeys.sort((a, b) => {
          if (parseInt(a) > parseInt(b)) {
            return 1;
          }
          return 0;
        })[0],
      );
    }
  }, [allKeys]);

  const videoOptionsView = (availableVideos: BuilderVideo[]) => (
    <ModalVideoOptions
      availableVideos={availableVideos}
      includedVideos={includedVideos}
      onVideoClick={(videoId: BuilderVideo['VideoId']) => {
        if (collection) {
          setEditedItems((currentItems) => {
            // Add it to the items
            return [
              ...currentItems,
              {
                ...DEFAULT_COLLECTION_ITEM,
                AppId: appId,
                TabId: collection?.TabId,
                ChildId: videoId,
                Type: ITEM_TYPE_VIDEO,
                SubType: ITEM_TYPE_VIDEO,
                Position: editedItems.length + 1,
                TabItemId: `TempTabItem${getTempId()}`,
                PreExerciseCountInDuration: countInDefault,
              },
            ];
          });
        }
      }}
    />
  );

  const collectionOptionsView = (availableCollections: BuilderCollection[]) => (
    <ModalCollectionOptions
      availableCollections={availableCollections}
      includedCollections={includedCollections}
      onCollectionClick={(id) => {
        if (collection) {
          setEditedItems((currentItems) => {
            // Add it to the items
            return [
              ...currentItems,
              {
                ...DEFAULT_COLLECTION_ITEM,
                AppId: appId,
                TabId: collection.TabId,
                ChildId: id,
                Type: ITEM_TYPE_COLLECTION,
                SubType: ITEM_TYPE_COLLECTION,
                Position: editedItems.length + 1,
                TabItemId: `TempTabItem${getTempId()}`,
                PreExerciseCountInDuration: countInDefault,
              },
            ];
          });
        }
      }}
    />
  );

  const switchViews = (activeKey: string) => {
    switch (activeKey) {
      case '1':
        return videoOptionsView(videosBySource[datasource]);
      case '2':
        return collectionOptionsView(Collections1.Options);
      case '4':
        return videoOptionsView(videosBySource[SOURCE_WORDPRESS]);
      case '5':
        return collectionOptionsView(CollectionsWordPress.Options);
      case '6':
        return videoOptionsView(videosBySource[SOURCE_JWPLAYER]);
      case '7':
        return collectionOptionsView(CollectionsJWPlayer.Options);
      case '8':
        return videoOptionsView(videosBySource[SOURCE_VIMEO]);
      case '9':
        return collectionOptionsView(CollectionsVimeo.Options);
      case '10':
        return videoOptionsView(videosBySource[SOURCE_YOUTUBE]);
      case '11':
        return collectionOptionsView(CollectionsYouTube.Options);
      case '12':
        return videoOptionsView(videosBySource[SOURCE_CUSTOMAPI]);
      case '13':
        return collectionOptionsView(CollectionsCustomAPI.Options);
      case '16':
        return collectionOptionsView(CollectionsVidAppCollections.Options);
      case '17':
        return collectionOptionsView(CollectionsVidAppWorkouts.Options);
      case '18':
        return collectionOptionsView(CollectionsVidAppCircuits.Options);
      case '20':
        return (
          <WebsiteView
            onCreate={(website) => {
              if (collection) {
                const tempTabId = `TempTab${getTempId()}`;
                const newCollection = {
                  AppId: appId,

                  ...DEFAULT_COLLECTION,
                  TabId: tempTabId,
                  TemplateId: WEBSITE_TEMPLATE,
                  Position: 0,
                  Name: website.title ?? '',
                  SourceType: SOURCE_TYPE_WEBSITE,
                  Items: [],
                  ...getThumbnails(website),
                  IncludedInAppData: 0 as const,
                };
                setNewCollection(tempTabId, newCollection);
                setEditedItems((currentItems) => {
                  // Add website to current collection
                  return [
                    ...currentItems,
                    {
                      ...DEFAULT_COLLECTION_ITEM,
                      AppId: appId,
                      TabId: collection.TabId,
                      ChildId: tempTabId,
                      Type: ITEM_TYPE_COLLECTION,
                      SubType: ITEM_TYPE_COLLECTION,
                      Position: editedItems.length + 1,
                      TabItemId: `TempTabItem${getTempId()}`,
                    },
                  ];
                });
                setCollectionProperty(tempTabId, COLLECTION_PROPERTY_WEBSITE_URL, website.url ?? '');
                // Website will be set to save if modal is confirmed
                setPendingSaves((current) => {
                  const fn = () => {
                    setCollectionToSave(tempTabId, getCollectionValuesFromNewCollection(newCollection));
                    setCollectionPropertyToSave(tempTabId, COLLECTION_PROPERTY_WEBSITE_URL, website.url ?? '');
                  };
                  return [...current, fn];
                });
              }
            }}
          />
        );
      case '21':
        return (
          <TextImageView
            onCreate={(ti) => {
              if (collection) {
                const tempTabId = `TempTab${getTempId()}`;
                const newCollection = {
                  AppId: appId,
                  ...DEFAULT_COLLECTION,
                  ...getThumbnails(ti),
                  TabId: tempTabId,
                  TemplateId: TEXT_IMAGE_TEMPLATE,
                  Position: 0,
                  Name: ti.title ?? '',
                  Description: '',
                  SourceType: SOURCE_TYPE_TEXT,
                  Items: [],
                  IncludedInAppData: 0 as const,
                };
                setNewCollection(tempTabId, newCollection);
                setCollectionProperty(tempTabId, COLLECTION_PROPERTY_HTMLPAGE_DESC, ti.description);
                setEditedItems((currentItems) => {
                  // Add text/image to current collection
                  return [
                    ...currentItems,
                    {
                      ...DEFAULT_COLLECTION_ITEM,
                      AppId: appId,
                      TabId: collection.TabId,
                      ChildId: tempTabId,
                      Type: ITEM_TYPE_COLLECTION,
                      SubType: ITEM_TYPE_COLLECTION,
                      Position: editedItems.length + 1,
                      TabItemId: `TempTabItem${getTempId()}`,
                    },
                  ];
                });
                setPendingSaves((current) => {
                  const fn = () => {
                    setCollectionToSave(tempTabId, getCollectionValuesFromNewCollection(newCollection));
                    setCollectionPropertyToSave(tempTabId, COLLECTION_PROPERTY_HTMLPAGE_DESC, ti.description ?? '');
                  };
                  return [...current, fn];
                });
              }
            }}
          />
        );
      case '22':
        return (
          <FileView
            onCreate={(file) => {
              if (collection) {
                const tempVideoId = `TempVideo${getTempId()}`;
                const newVideo: BuilderVideo = {
                  ...DEFAULT_FILE,
                  AppId: appId,
                  VideoId: tempVideoId,
                  SourceId: '',
                  Title: file.title ?? '',
                  ...getThumbnails(file),
                  Description: '',
                  ProductId: '-1',
                  HideFromSearchResults: file.hideFromSearch ? 1 : 0,
                  OriginalFilename: file.filename ?? '',
                };
                setNewVideo(tempVideoId, newVideo);
                setEditedItems((currentItems) => {
                  // Add post to current collection
                  return [
                    ...currentItems,
                    {
                      ...DEFAULT_COLLECTION_ITEM,
                      AppId: appId,
                      TabId: collection.TabId,
                      ChildId: tempVideoId,
                      Type: ITEM_TYPE_VIDEO,
                      SubType: ITEM_TYPE_VIDEO,
                      Position: editedItems.length + 1,
                      TabItemId: `TempTabItem${getTempId()}`,
                    },
                  ];
                });
                // Post will be set to save if modal is confirmed
                setPendingSaves((current) => {
                  const fn = () => {
                    setVideoToSave(tempVideoId, getVideoValuesFromNewVideo(newVideo));
                  };
                  return [...current, fn];
                });
              }
            }}
          />
        );
      case '23':
        return (
          <PostView
            onCreate={(post) => {
              if (collection) {
                const tempVideoId = `TempVideo${getTempId()}`;
                const newVideo: BuilderVideo = {
                  ...DEFAULT_POST,
                  AppId: appId,
                  VideoId: tempVideoId,
                  SourceId: '',
                  Title: post.title ?? '',
                  ...getThumbnails(post),
                  Description: post.description ?? '',
                  ProductId: post.access ?? '-1',
                  HideFromSearchResults: post.hideFromSearch ? 1 : 0,
                  Resources: post.resources ?? [],
                };
                setNewVideo(tempVideoId, newVideo);
                setEditedItems((currentItems) => {
                  // Add post to current collection
                  return [
                    ...currentItems,
                    {
                      ...DEFAULT_COLLECTION_ITEM,
                      AppId: appId,
                      TabId: collection.TabId,
                      ChildId: tempVideoId,
                      Type: ITEM_TYPE_VIDEO,
                      SubType: VIDEO_TYPE_POST,
                      Position: editedItems.length + 1,
                      TabItemId: `TempTabItem${getTempId()}`,
                    },
                  ];
                });
                // Post will be set to save if modal is confirmed
                setPendingSaves((current) => {
                  const fn = () => {
                    setVideoToSave(tempVideoId, getVideoValuesFromNewVideo(newVideo));
                  };
                  return [...current, fn];
                });
              }
            }}
          />
        );
    }
    return null;
  };

  return (
    <Modal open={!!modalCollectionId} $height="100%" $contentWidth="100%" $padding="0" forceRender>
      {collection && (
        <>
          <ModalHeading>
            {modalType === SOURCE_TYPE_WORKOUT
              ? 'Add To Workout'
              : modalType === SOURCE_TYPE_CIRCUIT
              ? 'Add To Circuit'
              : 'Add'}
          </ModalHeading>
          <ModalBody>
            <BodyNavigation>
              {sourceOptions.length > 0 && (
                <ModalBodyNavigationGroup
                  activeKey={activeKey}
                  heading={getDataSourceDisplayName(datasource)}
                  options={sourceOptions}
                  onOptionClick={(id) => setActiveKey(id)}
                />
              )}
              {wordPressOptions.length > 0 && (
                <ModalBodyNavigationGroup
                  activeKey={activeKey}
                  heading="WordPress"
                  options={wordPressOptions}
                  onOptionClick={(id) => setActiveKey(id)}
                />
              )}
              {jwPlayerOptions.length > 0 && (
                <ModalBodyNavigationGroup
                  activeKey={activeKey}
                  heading="JW Player"
                  options={jwPlayerOptions}
                  onOptionClick={(id) => setActiveKey(id)}
                />
              )}
              {vimeoOptions.length > 0 && (
                <ModalBodyNavigationGroup
                  activeKey={activeKey}
                  heading="Vimeo"
                  options={vimeoOptions}
                  onOptionClick={(id) => setActiveKey(id)}
                />
              )}
              {youTubeOptions.length > 0 && (
                <ModalBodyNavigationGroup
                  activeKey={activeKey}
                  heading="YouTube"
                  options={youTubeOptions}
                  onOptionClick={(id) => setActiveKey(id)}
                />
              )}
              {customAPIOptions.length > 0 && (
                <ModalBodyNavigationGroup
                  activeKey={activeKey}
                  heading="CustomAPI"
                  options={customAPIOptions}
                  onOptionClick={(id) => setActiveKey(id)}
                />
              )}
              {modalType !== SOURCE_TYPE_CIRCUIT && (
                <ModalBodyNavigationGroup
                  heading="VidApp"
                  options={
                    modalType === SOURCE_TYPE_WORKOUT
                      ? [{ id: '18', name: 'Circuits', icon: <UISyncIcon /> }]
                      : [
                          CMSEnabled
                            ? { id: '16', name: `${GROUP_LABEL}s`, icon: <StyledCollectionIcon /> }
                            : undefined,
                          circuitsEnabled
                            ? {
                                id: '17',
                                name: `Workouts`,
                                icon: <DumbbellIcon />,
                              }
                            : undefined,
                          canAddVideos ? { id: '20', name: 'Website', icon: <GlobeIcon /> } : undefined,
                          canAddVideos
                            ? {
                                id: '21',
                                name: 'Page (HTML)',
                                icon: <TextImageIcon />,
                              }
                            : undefined,
                          canAddVideos ? { id: '22', name: 'File', icon: <FileBlankIcon /> } : undefined,
                          canAddVideos ? { id: '23', name: 'Post', icon: <PostIcon /> } : undefined,
                        ]
                  }
                  activeKey={activeKey}
                  onOptionClick={(id) => setActiveKey(id)}
                />
              )}
            </BodyNavigation>
            <LeftPanel>{activeKey && switchViews(activeKey)}</LeftPanel>
            <ContentPreview items={editedItems} tabId={collection.TabId.toString()} setItems={setEditedItems} />
          </ModalBody>
          <ModalActions>
            <CancelButton medium tertiary disabled={confirmDisabled} onClick={() => setModalCollectionId(undefined)}>
              Cancel
            </CancelButton>
            <SaveButton onClick={handleSave} disabled={confirmDisabled} medium>
              Confirm
            </SaveButton>
          </ModalActions>
        </>
      )}
    </Modal>
  );
};
