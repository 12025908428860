import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { isUserAdmin } from 'utils';

import { getTemplates, SOURCE_KAJABI, Template, TEMPLATE_TYPE_MAIN, useAxiosInstance } from 'api';
import { useAppBasicInfo } from './useAppBasicInfo';
import { useAppProperties } from './useAppProperties';

export interface GetTemplateResponse {
  templates: Template[];
  templateById: Record<number, Template>;
}

const EXCLUDED_LEGACY_TEMPLATES = [9];
const KAJABI_ADMIN_TEMPLATES = [40];

export const useTemplates = () => {
  const client = useAxiosInstance();
  const query = useQuery<GetTemplateResponse>('templates', () => getTemplates(client));
  const { data: appBasicInfo } = useAppBasicInfo();
  const { data: appProperties } = useAppProperties();
  const { DataSource } = appBasicInfo ?? {};

  const getTemplateById = useCallback(
    (templateId: number) => {
      const template = query.data?.templateById[templateId];
      if (template) {
        return template;
      }
      console.error(`Can't retrieve template with id ${templateId}`);
      return undefined;
    },
    [query.data],
  );

  const isAlternateThumbnailsTemplate = (_id: number) => [45, 46, 48, 49].includes(_id);

  const getTemplateOptions = useCallback(() => {
    if (appBasicInfo) {
      const templates = query.data?.templates;
      return templates?.filter((template) => {
        // Filter alternate thumbnail templates if not rolled out yet
        if (isAlternateThumbnailsTemplate(template.TemplateId)) {
          if (appProperties?.RolloutAlternateThumbnails !== '1') {
            return false;
          }
        }
        return (
          template.AvailableOnTemplatedApp === 1 &&
          (!template.RestrictedTypes || template.RestrictedTypes === TEMPLATE_TYPE_MAIN)
        );
      });
    }
    return query.data?.templates.filter((template) => {
      if (!EXCLUDED_LEGACY_TEMPLATES.includes(template.TemplateId)) {
        if (KAJABI_ADMIN_TEMPLATES.includes(template.TemplateId)) {
          return isUserAdmin() && DataSource === SOURCE_KAJABI;
        }
        return template.AvailableOnMobile === 1;
      }
      return false;
    });
  }, [query.data, appBasicInfo, appProperties]);

  const getTVTemplateOptions = useCallback(() => {
    return query.data?.templates.filter((template) => template.AvailableOnTV === 1 && template.AvailableOnMobile !== 1);
  }, [query.data]);

  const getTemplateOptionsByType = useCallback(
    (type: string) => {
      if (appBasicInfo) {
        const templates = query.data?.templates;
        return templates?.filter((template) => {
          if (isAlternateThumbnailsTemplate(template.TemplateId)) {
            if (appProperties?.RolloutAlternateThumbnails !== '1') {
              return false;
            }
          }
          return (
            template.AvailableOnTemplatedApp === 1 &&
            (!template.RestrictedTypes || template.RestrictedTypes.includes(type))
          );
        });
      }
      return query.data?.templates.filter((template) => {
        if (!EXCLUDED_LEGACY_TEMPLATES.includes(template.TemplateId)) {
          if (KAJABI_ADMIN_TEMPLATES.includes(template.TemplateId)) {
            return isUserAdmin() && DataSource === SOURCE_KAJABI;
          }
          if (isAlternateThumbnailsTemplate(template.TemplateId)) {
            if (appProperties?.RolloutAlternateThumbnails !== '1') {
              return false;
            }
          }
          return template.AvailableOnMobile === 1;
        }
        return false;
      });
    },
    [query.data, appBasicInfo, appProperties],
  );

  return { ...query, getTemplateById, getTemplateOptions, getTVTemplateOptions, getTemplateOptionsByType };
};

export const usePrefetchTemplates = async () => {
  const queryClient = useQueryClient();
  const client = useAxiosInstance();
  await queryClient.prefetchQuery('templates', () => getTemplates(client));
};
