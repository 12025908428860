import { useCallback, useState } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';

import {
  CustomButton,
  InfoModal,
  PageContainer,
  SaveButton,
  SettingsFormItem,
  SettingsGroup,
  SettingsSegmentDivider,
  SettingsTextInput,
} from 'components';
import { includesNumber, includesSpecialCharacter } from 'utils';

import { useUpdatePassword } from './hooks/useUpdatePassword';
import { useAccountDetails } from './hooks/useAccountDetails';
import { useUpdateAccount } from './hooks/useUpdateAccount';
import { NEUTRAL_7_COLOUR, SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';
import { PlanDetails } from './components/PlanDetails';
import { useStripeCustomer } from './hooks/useStripeCustomer';
import { usePricingPlan } from '../../../../hooks';

const PAGE_NAME = 'Account';

const { useForm } = Form;
const DETAILS_FORM = 'details-form';

const StyledSaveButton = styled(SaveButton)`
  #react-app && {
    position: relative;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
`;
const OverrideFormItem = styled(SettingsFormItem)`
  #react-app && .ant-form-item-explain {
    font-size: 12px;
    color: ${NEUTRAL_7_COLOUR};
    margin-bottom: 20px;
  }
`;

export const AccountDetails = () => {
  const { data, isLoading: accountLoading } = useAccountDetails();
  const { data: customer, isLoading: customerLoading } = useStripeCustomer();
  const { data: plan } = usePricingPlan();
  const updatePassword = useUpdatePassword();
  const updateAccount = useUpdateAccount();
  const [passwordForm] = useForm();
  const [detailsForm] = useForm();
  const [detailsSaveDisabled, setDetailsSaveDisabled] = useState(true);

  const onPasswordSubmit = useCallback(
    async (e) => {
      updatePassword.mutate(e, {
        onSuccess: () => {
          passwordForm.resetFields();
          InfoModal({
            title: 'Password Updated',
            content: 'Your password has been updated successfully',
          });
        },
        onError: (e) => {
          const err = e as Error;
          if (err.message.includes('Incorrect password') || err.message.includes('Too many login attempts')) {
            passwordForm.setFields([
              {
                name: 'CurrentPassword',
                errors: [err.message],
              },
            ]);
          } else {
            passwordForm.setFields([
              {
                name: 'ConfirmPassword',
                errors: [err.message],
              },
            ]);
          }
        },
      });
    },
    [updatePassword],
  );

  const onDetailsSubmit = useCallback(
    (e) => {
      updateAccount.mutate({ FirstName: e.FirstName, LastName: e.LastName });
    },
    [updateAccount],
  );

  return (
    <PageContainer
      heading={PAGE_NAME}
      isLoading={customerLoading || accountLoading}
      headingButton={
        <CustomButton
          medium
          onClick={() => detailsForm?.submit()}
          loading={updateAccount.isLoading}
          disabled={detailsSaveDisabled}
        >
          Save
        </CustomButton>
      }
    >
      <StyledForm
        name={DETAILS_FORM}
        form={detailsForm}
        onValuesChange={() => {
          const first = detailsForm.getFieldValue('FirstName');
          const last = detailsForm.getFieldValue('LastName');
          if (first && last) {
            setDetailsSaveDisabled(false);
          } else {
            setDetailsSaveDisabled(true);
          }
        }}
        onFinish={onDetailsSubmit}
        initialValues={{
          FirstName: data?.FirstName,
          LastName: data?.LastName,
          AccountEmail: data?.Email,
        }}
      >
        <SettingsGroup title="Details" wrapRow>
          <SettingsFormItem name="FirstName" width="49%" required $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}>
            <SettingsTextInput label="First Name" maxLength={30} placeholder="First Name" />
          </SettingsFormItem>
          <SettingsFormItem name="LastName" width="49%" required $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}>
            <SettingsTextInput label="Last Name" maxLength={30} placeholder="Last Name" />
          </SettingsFormItem>

          <OverrideFormItem name="AccountEmail" help="Contact your account manager to change your email">
            <SettingsTextInput label="Account Email" disabled />
          </OverrideFormItem>
        </SettingsGroup>
      </StyledForm>
      <SettingsSegmentDivider />
      <StyledForm
        name="settings-form"
        form={passwordForm}
        onFinish={onPasswordSubmit}
        autoComplete="off"
        validateTrigger="onSubmit"
      >
        <SettingsGroup title="Update Password">
          <SettingsFormItem name="CurrentPassword" marginBottom="32px">
            <SettingsTextInput label="Current Password" type="Password" />
          </SettingsFormItem>
          <SettingsFormItem
            name="NewPassword"
            marginBottom="32px"
            rules={[
              {
                validator: (_, value) => {
                  if (!value || !(value.length >= 8 && includesNumber(value) && includesSpecialCharacter(value))) {
                    return Promise.reject(new Error("Password doesn't meet minimum requirements"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <SettingsTextInput
              label="New Password"
              type="Password"
              tooltip="Minimum 8 characters, 1 number, 1 special character"
            />
          </SettingsFormItem>
          <SettingsFormItem
            name="ConfirmPassword"
            marginBottom="32px"
            rules={[
              {
                validator: (_, value) => {
                  const newPass = passwordForm.getFieldValue('NewPassword');
                  if (value === newPass) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords entered do not match'));
                },
              },
            ]}
          >
            <SettingsTextInput
              label="Confirm Password"
              type="Password"
              tooltip="Minimum 8 characters, 1 number, 1 special character"
            />
          </SettingsFormItem>
          <StyledSaveButton onClick={() => passwordForm.submit()} loading={updatePassword.isLoading}>
            Update Password
          </StyledSaveButton>
        </SettingsGroup>
      </StyledForm>
      <SettingsSegmentDivider />
      <PlanDetails customer={customer} plan={plan} account={data} />
    </PageContainer>
  );
};
