import { IN_PROGRESS_TEMPLATE, SOURCE_VIDAPP, Template, Video } from 'api';
import { isContentResource } from 'utils';

import type {
  BuilderCollection,
  BuilderCollectionItem,
  BuilderVideo,
} from 'app/modules/build-dragdrop/Builder/providers';

export const groupTemplates = (templates: Template[], excluded_templates: number[]) => {
  const filteredTemplates = templates?.filter((template) => !excluded_templates.includes(template.TemplateId)) ?? [];
  const output: Record<string, Template[]> = {
    Feature: [],
    Featured: [],
    Slider: [],
    Carousel: [],
    Grid: [],
    List: [],
    Other: [],
  };
  for (const t of filteredTemplates) {
    let assigned = false;

    if (t.TemplateId === IN_PROGRESS_TEMPLATE) {
      output['Feature'].push(t);
      continue;
    }
    if ([39, 30].includes(t.TemplateId)) {
      output['Featured'].push(t);
      continue;
    }

    // Sort templates with display names starting with the following
    for (const prefix of ['Grid', 'Slider', 'List', 'Carousel']) {
      if (t.DisplayName.startsWith(prefix)) {
        // Temporary override until prod supports Carousel prefix
        // Automatically map Carousel to Carousel prefix instead of Slider
        if (t.DisplayName.includes('Carousel')) {
          output['Carousel'].push(t);
        } else {
          output[prefix].push(t);
        }

        assigned = true;
        break;
      }
    }
    // Any others that didnt have a prefix fall into the other group
    if (!assigned) {
      output['Other'].push(t);
    }
  }

  return Object.entries(output);
};

export const getExcludedTemplates = (options?: { allowCourseView: boolean }) => {
  const excluded = [44]; // Cant explicitly set In Progress
  if (!options?.allowCourseView) {
    excluded.push(39);
  }

  return excluded;
};

export const getFormattedTemplateName = (template: Template) => {
  // The template name will be split into 3 parts, e.g. "Slider - Carousel (Title Below)" would be:
  // {
  //   group: 'Slider',
  //   name: 'Carousel',
  //   variant: 'Title Below',
  // }
  const obj = {
    group: '',
    name: '',
    variant: '',
  };
  const parts = template.DisplayName.split(' - ');
  if (parts.length > 1) {
    // If there was a hyphen, the first item in the array will be the group (otherwise it will be left as an empty string)
    obj.group = parts[0];
  }
  // Split the last item in the array into 2 if there are parentheses
  const subParts = (parts.pop() ?? '').split(' (');
  obj.name = subParts[0];
  if (subParts.length > 1) {
    obj.variant = subParts[1].replace(')', ''); // If there is a variant, remove the trailing parenthisis
  }

  // Temporary fix for Carousel
  if (obj.name == 'Carousel') {
    obj.group = 'Carousel';
    obj.name = obj.variant;
    obj.variant = '';
  }

  return obj;
};

export const updateCollectionPositions = (collection: BuilderCollection) => {
  // Update positions based on new items
  collection.Items.forEach((_, idx) => {
    collection.Items[idx].Position = idx + 1;
  });
};

export const isItemClickable = (content: BuilderCollection | BuilderVideo | Video, item: BuilderCollectionItem) => {
  if (content.DataSource !== SOURCE_VIDAPP && isContentResource(content, item.Type)) {
    // Can't click into VHX item resources
    return false;
  }
  return true;
};

export const getFileType = (filename: string) => {
  const splitFilename = filename.split('.'); // Split the filname by all uses of .
  return splitFilename.length > 1 ? (splitFilename.pop() as string) : 'file'; // Remove the the last item of the array and use as the file type
};

export const getFileTitle = (filename: string) => {
  const splitFilename = filename.split('.'); // Split the filname by all uses of .
  splitFilename.length > 1 && splitFilename.pop(); // Remove the last item of the array if more than 1 item
  return splitFilename.join('.'); // Join the remaining array and re-add any non-extention uses of .
};
