import { SearchBar, Tabs } from 'components';
import { FONT_10PX_REGULAR, FONT_14PX_REGULAR } from 'font';
import { ChevronRightIcon } from 'icons';
import { BuilderCollection, BuilderCollectionItem, BuilderVideo, useContent } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { NEUTRAL_4_COLOUR, NEUTRAL_6_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_8_COLOUR } from 'theme';

interface PathItem {
  name: string;
  id: string | number;
}

type Path = PathItem[];

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PathWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  border: 1px solid ${NEUTRAL_6_COLOUR};
  border-radius: 6px;
  padding: 5px 5px 0;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const PathSegment = styled.div`
  display: flex;
  background-color: ${NEUTRAL_4_COLOUR};
  border-radius: 6px;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
`;

const Name = styled.div`
  ${FONT_14PX_REGULAR};
`;
const Id = styled.div`
  margin-left: 5px;
  ${FONT_10PX_REGULAR};
  color: ${NEUTRAL_8_COLOUR};
`;
const Chevron = styled(ChevronRightIcon)`
  &&&& {
    font-size: 20px;
    margin-bottom: 5px;
    color: ${NEUTRAL_7_COLOUR};
  }
`;

const Empty = styled.div`
  margin-top: 20px;
  ${FONT_14PX_REGULAR};
`;

const isCollection = (content: BuilderCollection | BuilderVideo) => {
  try {
    if ((content as BuilderCollection).TabId) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

export const DebugLocateInApp = () => {
  const { collections, getContentForItem } = useContent();
  const [query, setQuery] = useState('');
  const [contentPaths, setContentPaths] = useState<Record<string | number, Path[]>>({});
  const [activePaths, setActivePaths] = useState<Path[]>();

  const mainTabsWithItems = useMemo(() => {
    if (!collections) {
      return undefined;
    }
    return Object.values(collections).filter(({ IsMainTab, Items }) => IsMainTab === 1 && Items.length > 0);
  }, [collections]);

  const getItemPaths = useCallback(
    (Items: BuilderCollectionItem[], currentPath: Path) => {
      const itemPaths: Record<string | number, Path[]> = {};

      for (const item of Items) {
        itemPaths[item.ChildId] = [currentPath];
      }

      return itemPaths;
    },
    [getContentForItem],
  );

  const mapPaths = useCallback(
    (collections: BuilderCollection[], currentPath: Path): Record<string | number, Path[]> => {
      const newPaths: Record<string | number, Path[]> = {};

      for (const c of collections) {
        const updatedPath = [...currentPath, { name: c.Name ?? c.SourceName, id: c.TabId }];
        const itemPaths = getItemPaths(c.Items, updatedPath);
        Object.entries(itemPaths).forEach(([key, paths]) => {
          // Avoid infinite loops
          if (!currentPath.some((p) => p.id === c.TabId)) {
            newPaths[key] = (newPaths[key] || []).concat(paths);
          }
        });

        const childCollections: BuilderCollection[] = c.Items.map((item) => getContentForItem(item)).filter(
          (content): content is BuilderCollection => !!content && isCollection(content),
        );

        const childPaths = mapPaths(childCollections, updatedPath);
        Object.entries(childPaths).forEach(([key, paths]) => {
          newPaths[key] = (newPaths[key] || []).concat(paths);
        });
      }

      return newPaths;
    },
    [getItemPaths, getContentForItem],
  );

  useEffect(() => {
    if (mainTabsWithItems) {
      const processPaths = () => {
        const newPaths = mapPaths(mainTabsWithItems, []);
        setContentPaths(newPaths);
      };

      // Use requestIdleCallback to defer processing
      if (window.requestIdleCallback) {
        window.requestIdleCallback(processPaths);
      } else {
        setTimeout(processPaths, 0);
      }
    }
  }, [mainTabsWithItems, mapPaths]);

  const handleSearch = useCallback(
    (value: string) => {
      setQuery(value);
      const paths = contentPaths[value];
      if (paths) {
        setActivePaths(paths);
      } else {
        setActivePaths(undefined);
      }
    },
    [contentPaths, setQuery, setActivePaths],
  );

  return (
    <Tabs.Content value="locateInApp">
      <SearchBar value={query} onSearch={handleSearch} placeholder="Search VideoId or TabId" width="293px" />
      <Container>
        {activePaths ? (
          activePaths.map((path, idx) => (
            <PathWrapper key={idx}>
              {path.map(({ name, id }, idx) => {
                const isLast = idx === path.length - 1;
                return (
                  <>
                    <PathSegment key={`${id}_${idx}`}>
                      <Name>{name}</Name>
                      <Id>{`(${id})`}</Id>
                    </PathSegment>
                    {!isLast && <Chevron />}
                  </>
                );
              })}
            </PathWrapper>
          ))
        ) : query.length > 0 ? (
          <Empty>Cannot locate item in app</Empty>
        ) : null}
      </Container>
    </Tabs.Content>
  );
};
