import { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from 'icons';
import { ColumnModal } from 'components';
import { NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const IconWrapper = styled.div`
  &&&&& {
    margin-bottom: 16px;
    font-size: 35px;
  }
`;
const Title = styled.div`
  &&&& {
    ${FONT_16PX_MEDIUM};
    color: ${NEUTRAL_9_COLOUR};
    text-align: center;
    margin-bottom: 4px;
  }
`;
const Content = styled.div`
  &&&& {
    ${FONT_14PX_REGULAR};
    color: ${NEUTRAL_7_COLOUR};
    text-align: center;
  }
`;

interface IconMap {
  success: JSX.Element;
  error: JSX.Element;
  warning: JSX.Element;
  info: JSX.Element;
}

type ModalType = 'success' | 'error' | 'warning' | 'info';
const getModalIcon = (key: ModalType) => {
  const iconMap: IconMap = {
    success: <SuccessIcon />,
    error: <ErrorIcon />,
    warning: <WarningIcon />,
    info: <InfoIcon />,
  };
  return iconMap[key];
};

interface InfoModalProps {
  title: string;
  content: string | JSX.Element;
  type?: ModalType;
  okText?: string;
}

const InfoModalComponent = ({
  title,
  content,
  type = 'success',
  okText,
  onUnmount,
}: InfoModalProps & { onUnmount: () => void }) => {
  const [isOpen, setIsOpen] = useState(true);

  const icon = getModalIcon(type);

  const handleClose = () => {
    setIsOpen(false);
    onUnmount();
  };

  return (
    <ColumnModal
      align="full"
      cols={2}
      open={isOpen}
      onOpenChange={handleClose}
      onConfirm={handleClose}
      primaryText={okText ?? 'Ok'}
      hideTrigger={true}
      hideDialogActionsBorder
      portalId="modal-portal"
      hideSecondary
      hideClose
    >
      <Wrapper>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <Title>{title}</Title>
        {typeof content === 'string' ? <Content>{content}</Content> : content}
      </Wrapper>
    </ColumnModal>
  );
};

export const InfoModal = (props: InfoModalProps) => {
  const container = document.querySelector('#modal-portal');

  if (container) {
    const onUnmount = () => {
      ReactDOM.unmountComponentAtNode(container);
    };

    ReactDOM.render(<InfoModalComponent {...props} onUnmount={onUnmount} />, container);
  }

  return null;
};
