import { MenuProps, Modal } from 'antd';
import { Key, ReactNode, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { BetaTag } from 'components';
import {
  AnalyticsIcon,
  AppFeaturesIcon,
  AppStoreIcon,
  BellIcon,
  BuildIcon,
  CogIcon,
  CommentIcon,
  CommunityIcon,
  DashboardIcon,
  DollarSignIcon,
  ImpersonateIcon,
  PlayBoxIcon,
} from 'icons';
import { useDataSource, useUnsavedChanges } from 'providers';
import { NEUTRAL_11_COLOUR, SIDE_NAV_WIDTH } from 'theme';
import { BUILDER_IMAGES_DOMAIN, getScreenFromPathname, isUserAdmin } from 'utils';

import { useFeatureFlags } from 'app/modules/feature-flagged/FeatureFlagged/hooks/useFeatureFlags';
import { NavMenu } from './components';
import { SOURCE_VIDAPP } from '../../../../api';

interface ScreenKey {
  screenKey: string;
  groupKey?: string;
}

const KEY_MAP: Record<string, ScreenKey> = {
  dashboard: { screenKey: 'Dashboard' },
  content: { screenKey: 'Content' },
  'synced-content': { screenKey: 'SyncedContent', groupKey: 'ContentGroup' },
  playlists: { screenKey: 'PlaylistsGroups', groupKey: 'ContentGroup' },
  series: { screenKey: 'Series', groupKey: 'ContentGroup' },
  workouts: { screenKey: 'Workouts', groupKey: 'ContentGroup' },
  'build-my-app': { screenKey: 'BuildMyApp', groupKey: 'BuildGroup' },
  'build-my-tv-app': { screenKey: 'BuildMyTVApp', groupKey: 'BuildGroup' },
  'color-scheme': { screenKey: 'ColorScheme', groupKey: 'BuildGroup' },
  general: { screenKey: 'General', groupKey: 'BuildGroup' },
  'onboarding-screens': { screenKey: 'OnboardingScreens', groupKey: 'BuildGroup' },
  'purchase-screens': { screenKey: 'PurchaseScreens', groupKey: 'BuildGroup' },
  'side-menu': { screenKey: 'SideMenu', groupKey: 'BuildGroup' },
  'web-app': { screenKey: 'WebApp', groupKey: 'BuildGroup' },
  'calendar-scheduler': { screenKey: 'CalendarScheduler', groupKey: 'AppFeaturesGroup' },
  'circuit-configuration': { screenKey: 'CircuitConfiguration', groupKey: 'AppFeaturesGroup' },
  filters: { screenKey: 'Filters', groupKey: 'AppFeaturesGroup' },
  'content-tagging': { screenKey: 'ContentTagging', groupKey: 'AppFeaturesGroup' },
  posts: { screenKey: 'Posts', groupKey: 'CommunityGroup' },
  moderation: { screenKey: 'Moderation', groupKey: 'CommunityGroup' },
  members: { screenKey: 'Members', groupKey: 'CommunityGroup' },
  'push-notifications': { screenKey: 'PushNotifications' },
  ios: { screenKey: 'iOS', groupKey: 'AppStoreGroup' },
  android: { screenKey: 'Android', groupKey: 'AppStoreGroup' },
  'apple-tv': { screenKey: 'AppleTV', groupKey: 'AppStoreGroup' },
  roku: { screenKey: 'Roku', groupKey: 'AppStoreGroup' },
  viewership: { screenKey: 'Viewership', groupKey: 'AnalyticsGroup' },
  engagement: { screenKey: 'Engagement', groupKey: 'AnalyticsGroup' },
  'progress-tracking': { screenKey: 'ProgressTracking', groupKey: 'AnalyticsGroup' },
  'app-purchases': { screenKey: 'AppPurchases', groupKey: 'AnalyticsGroup' },
  'video-analytics': { screenKey: 'Analytics' },
  offers: { screenKey: 'Offers' },
  'app-store-settings': { screenKey: 'AppStore', groupKey: 'SettingsGroup' },
  webhooks: { screenKey: 'Webhooks', groupKey: 'SettingsGroup' },
  'admin-settings': { screenKey: 'Settings', groupKey: 'AdminGroup' },
  integrations: { screenKey: 'Integrations', groupKey: 'AdminGroup' },
  'add-ons': { screenKey: 'AddOns', groupKey: 'AdminGroup' },
  modules: { screenKey: 'Modules', groupKey: 'AdminGroup' },
  testing: { screenKey: 'Testing', groupKey: 'AdminGroup' },
  'action-log': { screenKey: 'BuilderActionLog', groupKey: 'AdminGroup' },
  'publish-diff': { screenKey: 'PublishDifferences', groupKey: 'AdminGroup' },
  'comments-moderator': { screenKey: 'ManageComments' },
  'app-purchases-admin': { screenKey: 'AppPurchases' },
  debug: { screenKey: 'Debug' },
};

const groupKeys = [
  'BuildGroup',
  'DesignGroup',
  'AppFeaturesGroup',
  'CommunityGroup',
  'AppStoreGroup',
  'AnalyticsGroup',
  'CircuitWorkoutsGroup',
  'UsersGroup',
  'SettingsGroup',
  'AdminGroup',
]; // Keys of group menus

type MenuItem = Required<MenuProps>['items'][number];

interface NavItemOptions {
  path?: string;
  icon?: ReactNode;
  children?: MenuItem[];
  isBeta?: boolean;
  isAdmin?: boolean;
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Flex = styled.span`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div<{ $cursor: string }>`
  margin: 0 0 24px 6px;
  cursor: ${({ $cursor }) => $cursor};
`;

const getNavItem = (labelText: string, { icon, children, path, isBeta, isAdmin }: NavItemOptions): MenuItem => {
  // If no path is provided, this is a group menu header, add 'Group' to the end of the key to avoid duplicates if same label is inside group (e.g. Account)
  const key: Key = !path
    ? `${labelText.replace(/[^A-Za-z0-9]/g, '')}Group` // Remove non-alphanumeric characters
    : labelText.replace(/[^A-Za-z0-9]/g, '');
  const label: ReactNode = !path ? (
    // If no path is provided, this is a group menu header, wrap it in a span so we can assign an id for the GuidedTour to use
    <Flex id={`NavItem--${key}`} className="ant-menu-title-content">
      {labelText}
      {isBeta && <BetaTag small isSideNav />}
      {isAdmin && <BetaTag small isSideNav isAdmin />}
    </Flex>
  ) : (
    // This is a React nav item, so link to provided path
    <StyledLink to={{ pathname: path, state: { showUnsavedChangesPrompt: true } }} id={`NavItem--${key}`}>
      {labelText}
      {isBeta && <BetaTag small isSideNav />}
      {isAdmin && <BetaTag small isSideNav isAdmin />}
    </StyledLink>
  );
  return {
    key,
    icon,
    children,
    label,
  };
};

interface ContainerProps {
  visible: boolean;
}

const Container = styled.div<ContainerProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  height: 100%;
  width: ${SIDE_NAV_WIDTH};
  padding: 24px 18px;
  overflow: auto;
  flex-shrink: 0;
  background-color: ${NEUTRAL_11_COLOUR};
`;

interface SideNavProps {
  visible?: boolean;
}

export const SideNav = ({ visible = true }: SideNavProps) => {
  const history = useHistory();
  const location = useLocation();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const { hasAccess, isLoading } = useFeatureFlags();
  const dataSource = useDataSource();
  const [isInitialized, setIsInitialized] = useState(false);
  const [navItems, setNavItems] = useState<MenuProps['items']>([]);

  const [selectedKeys, setSelectedKeys] = useState<string[] | undefined>(['Dashboard']);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  useEffect(() => {
    // Show confirmation modal when trying to navigate with unsaved changes
    const unblock = history.block(({ pathname, state }) => {
      const historyState = state as Record<string, boolean> | undefined;

      if (!historyState?.showUnsavedChangesPrompt) {
        // Don't block navigation
        return;
      }

      const continueNavigation = () => {
        unblock();
        history.push(pathname, state);
      };

      if (!unsavedChanges) {
        // Don't block navigation if changes have already been saved
        continueNavigation();
        return;
      }

      console.info('Blocking navigation due to unsaved changes', `Destination: ${pathname}`, `State: ${state}`);

      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes. If you leave without saving your changes will be lost.',
        getContainer: '#react-app',
        okText: 'Continue Editing',
        cancelText: 'Discard Changes',
        onCancel: () => {
          // Use proceeds discarding changes
          setUnsavedChanges(false);
          Modal.destroyAll();
          continueNavigation();
        },
        onOk: () => {
          // Block navigation
          return false;
        },
      });
      return false;
    });
    return;
  }, [history, unsavedChanges, setUnsavedChanges]);

  // setSelectedKeys and setOpenKeys based on path
  useEffect(() => {
    const key = getScreenFromPathname(location.pathname);

    if (!key) {
      setSelectedKeys([]);
      return;
    }

    if (KEY_MAP[key]) {
      const { screenKey, groupKey } = KEY_MAP[key];
      setSelectedKeys([screenKey]);
      if (groupKey) {
        setOpenKeys([groupKey]);
      }
    } else {
      setSelectedKeys([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isLoading) {
      const items: MenuProps['items'] = [];

      // Dashboard
      items.push(getNavItem('Dashboard', { path: '/', icon: <DashboardIcon /> }));

      // Content
      items.push(getNavItem('Content', { path: '/content', icon: <PlayBoxIcon /> }));

      // Build
      items.push(
        getNavItem('Build', {
          icon: <BuildIcon />,
          children: [
            getNavItem('Build My App', { path: '/build-my-app' }),
            isUserAdmin() && hasAccess('tv')
              ? getNavItem('Build My TV App', { path: '/build-my-tv-app', isAdmin: true })
              : null,
            getNavItem('Color Scheme', { path: '/design/color-scheme' }),
            getNavItem('General', { path: '/design/general' }),
            getNavItem('Onboarding Screens', { path: '/design/onboarding-screens' }),
            getNavItem('Purchase Screens', { path: '/design/purchase-screens' }),
            getNavItem('Side Menu', { path: '/design/side-menu' }),
            hasAccess('web-app') ? getNavItem('Web App', { path: '/design/web-app' }) : null,
          ],
        }),
      );

      const displayAppFeatures =
        hasAccess('calendar-scheduler') || hasAccess('circuit-configuration') || hasAccess('filters');

      // App Features - Feature flagged items
      displayAppFeatures &&
        items.push(
          getNavItem('App Features', {
            icon: <AppFeaturesIcon />,
            children: [
              hasAccess('calendar-scheduler')
                ? getNavItem('Calendar Scheduler', { path: '/calendar-scheduler' })
                : null,
              hasAccess('circuit-configuration')
                ? getNavItem('Circuit Configuration', { path: '/circuit-configuration' })
                : null,
              hasAccess('filters') ? getNavItem('Filters', { path: '/filters' }) : null,
              hasAccess('content-tagging') ? getNavItem('Content Tagging', { path: '/content-tagging' }) : null,
            ],
          }),
        );

      // VidApp Members
      dataSource === SOURCE_VIDAPP &&
        items.push(
          getNavItem('Members', {
            icon: <CommunityIcon />,
            path: '/members',
          }),
        );

      // Community
      hasAccess('community') &&
        items.push(
          getNavItem('Community', {
            icon: <CommunityIcon />,
            children: [
              getNavItem('Posts', { path: '/community/posts' }),
              getNavItem('Moderation', { path: '/community/moderation' }),
              getNavItem('Members', { path: '/community/members' }),
            ],
            isBeta: true,
          }),
        );

      // Push Notifications
      hasAccess('push-notifications') &&
        items.push(getNavItem('Push Notifications', { path: '/push-notifications', icon: <BellIcon /> }));

      // App Store
      items.push(
        getNavItem('App Store', {
          icon: <AppStoreIcon />,
          children: [
            getNavItem('iOS', { path: '/app-store/ios' }),
            getNavItem('Android', { path: '/app-store/android' }),
            hasAccess('apple-tv') ? getNavItem('Apple TV', { path: '/app-store/apple-tv' }) : null,
            hasAccess('roku') ? getNavItem('Roku', { path: '/app-store/roku' }) : null,
          ],
        }),
      );

      // Analytics
      items.push(
        getNavItem('Analytics', {
          icon: <AnalyticsIcon />,
          children: [
            getNavItem('Viewership', { path: '/analytics/viewership' }),
            getNavItem('Engagement', { path: '/analytics/engagement' }),
            getNavItem('Progress Tracking', { path: '/analytics/progress-tracking' }),
            hasAccess('app-purchases')
              ? getNavItem('App Purchases', {
                  path: '/analytics/app-purchases',
                  isBeta: true,
                })
              : null,
          ],
        }),
      );

      // In-App Products / Offers
      items.push(
        getNavItem('Offers', {
          path: '/offers',
          icon: <DollarSignIcon />,
        }),
      );

      // Manage Comments - Feature Flagged
      hasAccess('comments-moderator') &&
        items.push(getNavItem('Manage Comments', { path: '/comments-moderator', icon: <CommentIcon /> }));

      const displaySettings = hasAccess('webhooks') || hasAccess('app-store-settings');
      // Settings
      displaySettings &&
        items.push(
          getNavItem('Settings', {
            icon: <CogIcon />,
            children: [
              hasAccess('app-store-settings') ? getNavItem('App Store', { path: '/app-store-settings' }) : null,
              hasAccess('webhooks') ? getNavItem('Webhooks', { path: '/webhooks', isBeta: true }) : null,
            ],
          }),
        );

      // Admin
      if (isUserAdmin()) {
        const adminItems: MenuProps['items'] = [];

        // Settings
        adminItems.push(getNavItem('Settings', { path: '/admin/admin-settings' }));

        // Integrations
        adminItems.push(getNavItem('Integrations', { path: '/admin/integrations' }));

        // Add-Ons
        adminItems.push(getNavItem('Add-Ons', { path: '/admin/add-ons' }));

        // Modules
        adminItems.push(getNavItem('Modules', { path: '/admin/modules' }));

        // Testing
        adminItems.push(getNavItem('Testing', { path: '/admin/testing' }));

        // App Purchases
        dataSource !== SOURCE_VIDAPP &&
          adminItems.push(getNavItem('App Purchases', { path: '/admin/app-purchases-admin' }));

        // Action Log
        adminItems.push(getNavItem('Builder Action Log', { path: '/admin/action-log' }));

        // Debug
        adminItems.push(getNavItem('Debug', { path: '/admin/debug' }));

        // Engaged users
        adminItems.push(getNavItem('Engaged Users', { path: '/analytics/engaged-users' }));

        // Publish Diff
        adminItems.push(getNavItem('Publish Differences', { path: '/admin/publish-diff' }));

        // CSV Upload
        hasAccess('csv-upload') && adminItems.push(getNavItem('CSV Upload', { path: '/admin/csv-upload' }));

        // Wizard
        adminItems.push(getNavItem('Wizard', { path: '/onboarding' }));

        // User lookup
        adminItems.push(getNavItem('User Lookup', { path: '/admin/users' }));

        items.push(
          getNavItem('Admin', {
            icon: <ImpersonateIcon />,
            children: adminItems,
          }),
        );
      }

      setNavItems(items);
      setIsInitialized(true);
    }
  }, [isLoading, hasAccess]);

  const handleOpenChange: MenuProps['onOpenChange'] = useCallback(
    (keys) => {
      const latestOpenKey = keys.find((key: string) => openKeys.indexOf(key) === -1);
      if (groupKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    },
    [openKeys, setOpenKeys],
  );

  return (
    <Container visible={visible}>
      <LogoWrapper
        onClick={() => history.replace(`/`, { showUnsavedChangesPrompt: true })}
        $cursor={location.pathname === '/' ? 'default' : 'pointer'}
      >
        <img src={`${BUILDER_IMAGES_DOMAIN}/branding/vidapp_logo_light.webp`} width="92" alt="VidApp logo" />
      </LogoWrapper>
      {isInitialized && (
        <NavMenu
          mode="inline"
          items={navItems}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
          expandIcon={<></>}
          forceSubMenuRender
        />
      )}
    </Container>
  );
};
