export const STAGE_KEY_GET_STARTED = 'GET_STARTED';
export const STAGE_KEY_APP_STORE = 'APP_STORE';
export const STAGE_KEY_APP_STORE_APPLE = 'APP_STORE_APPLE';
export const STAGE_KEY_APP_STORE_ANDROID = 'APP_STORE_ANDROID';
export const STAGE_KEY_PLATFORM = 'PLATFORM';
export const STAGE_KEY_IAP = 'IAP';
export const STAGE_KEY_BUILD = 'BUILD';
export const STAGE_KEY_REVIEW_AND_SUBMISSION = 'REVIEW_AND_SUBMISSION';
export const STAGE_KEY_LAUNCH = 'LAUNCH';

const ALL_STAGE_KEYS = [
  STAGE_KEY_GET_STARTED,
  STAGE_KEY_APP_STORE,
  STAGE_KEY_APP_STORE_APPLE,
  STAGE_KEY_APP_STORE_ANDROID,
  STAGE_KEY_PLATFORM,
  STAGE_KEY_IAP,
  STAGE_KEY_BUILD,
  STAGE_KEY_REVIEW_AND_SUBMISSION,
  STAGE_KEY_LAUNCH,
] as const;

export type OnboardingStageKey = (typeof ALL_STAGE_KEYS)[number];

// GET_STARTED - Client
export const STEP_KEY_EXPLORE = 'ONB_EXPLORE_BUILDER';
export const STEP_KEY_BOOK_CALL = 'ONB_BOOK_CALL';
export const STEP_KEY_PAYMENT = 'ONB_PAYMENT_SETUP';
// GET_STARTED - Admin
export const STEP_KEY_MCL = 'ONB_MCL_SETUP';
export const STEP_KEY_HUBSPOT = 'ONB_HUBSPOT_SETUP';

// APP_STORE_APPLE - Client
export const STEP_KEY_APPLE_DEVELOPER = 'ONB_APPLE_DEVELOPER_CREATED';
export const STEP_KEY_APPLE_INVITE_VIDAPP = 'ONB_APPLE_INVITE_VIDAPP';
export const STEP_KEY_APPLE_CREDENTIALS = 'ONB_APPLE_CREDENTIALS_SAVED';
// APP_STORE_APPLE - Admin
export const STEP_KEY_APPLE_ITUNES = 'ONB_APPLE_ITUNES_CREDENTIALS_SAVED';

// APP_STORE_ANDROID - Client
export const STEP_KEY_ANDROID_DEVELOPER = 'ONB_ANDROID_DEVELOPER_CREATED';
export const STEP_KEY_ANDROID_INVITE_VIDAPP = 'ONB_ANDROID_INVITE_VIDAPP';
export const STEP_KEY_ANDROID_CREDENTIALS = 'ONB_ANDROID_CREDENTIALS_SAVED';
export const STEP_KEY_ANDROID_CURRENCY = 'ONB_ANDROID_CURRENCY_SAVED';

// PLATFORM - Kajabi - Client
export const STEP_KEY_KAJABI_PRODUCTS = 'ONB_KAJABI_PRODUCTS_ADDED';
// PLATFORM - VHX - Client
export const STEP_KEY_VHX_ADMIN = 'ONB_VHX_ADMIN_CREATED';
// PLATFORM - WORDPRESS - Client
export const STEP_KEY_WORDPRESS_ADMIN = 'ONB_WORDPRESS_ADMIN_CREATED';
// PLATFORM - Admin
export const STEP_KEY_ONE_PASSWORD = 'ONB_ONE_PASSWORD_SETUP';
// PLATFORM - Kajabi - Admin
export const STEP_KEY_KAJABI_IOS_USER = 'ONB_KAJABI_IOS_USER_ADDED';
export const STEP_KEY_KAJABI_TEST_USER = 'ONB_KAJABI_TEST_USER_ADDED';
// PLATFORM - VHX - Admin
export const STEP_KEY_VHX_IOS_USER = 'ONB_VHX_IOS_USER_ADDED';
export const STEP_KEY_VHX_TEST_USER = 'ONB_TEST_USER_ADDED';
// PLATFORM - WORDPRESS - ADMIN
export const STEP_KEY_WORDPRESS_COMPLETE_INTEGRATION = 'ONB_WORDPRESS_INTEGRATION_COMPLETED';

// IAP - Client
export const STEP_KEY_OFFERS_CREATED = 'ONB_OFFERS_CREATED';
export const STEP_KEY_WORDPRESS_SUBSCRIPTIONS_CREATED = 'ONB_WORDPRESS_SUBSCRIPTIONS_CREATED';
export const STEP_KEY_KAJABI_OFFERS_FOR_SALE = 'ONB_KAJABI_OFFERS_MARKED_FOR_SALE';
export const STEP_KEY_VHX_OFFERS_FOR_SALE = 'ONB_VHX_OFFERS_MARKED_FOR_SALE';
export const STEP_KEY_WORDPRESS_OFFERS_FOR_SALE = 'ONB_WORDPRESS_OFFERS_MARKED_FOR_SALE';
export const STEP_KEY_OFFERS_VALIDATED = 'ONB_OFFERS_VALIDATED';
export const STEP_KEY_IAP_SETTINGS_CONFIGURED = 'ONB_IAP_SETTINGS_CONFIGURED';
export const STEP_KEY_OFFER_IDS_SET = 'ONB_OFFER_IDS_SET';

// BUILD - Client
export const STEP_KEY_APP_STORE_FRONT = 'ONB_APP_STORE_FRONT_DETAILS_SAVED';
export const STEP_KEY_ONBOARDING_SCREENS = 'ONB_ONBOARDING_SCREENS_UPLOADED';
export const STEP_KEY_APP_STORE_SCREENSHOTS = 'ONB_APP_STORE_SCREENSHOTS_UPLOADED';
export const STEP_KEY_BUILD_APP_CONTENT = 'ONB_APP_CONTENT_BUILT';
//BUILD - Admin
export const STEP_KEY_FIGMA = 'ONB_FIGMA_PROJECT_CREATED';
export const STEP_KEY_VALIDATED_STORE_FRONT = 'ONB_STORE_FRONT_VALIDATED';
export const STEP_KEY_VALIDATED_APP_STORE_SCREENSHOTS = 'ONB_APP_STORE_SCREENSHOTS_VALIDATED';

//REVIEW_AND_SUBMISSION - Submission Statuses
export const STEP_KEY_IOS_SUBMITTED = 'ONB_IOS_APP_SUBMITTED';
export const STEP_KEY_IOS_REVIEW_SUCCESSFUL = 'ONB_IOS_REVIEW_SUCCESSFUL';
export const STEP_KEY_ANDROID_SUBMITTED = 'ONB_ANDROID_APP_SUBMITTED';
export const STEP_KEY_ANDROID_REVIEW_SUCCESSFUL = 'ONB_ANDROID_REVIEW_SUCCESSFUL';
//REVIEW_AND_SUBMISSION - Admin
export const STEP_KEY_MAP_IAPS = 'ONB_IAPS_MAPPED';
export const STEP_KEY_MAP_BUNDLE_ID_APPLE = 'ONB_BUNDLE_ID_MAPPED_APPLE';
export const STEP_KEY_MAP_BUNDLE_ID_ANDROID = 'ONB_BUNDLE_ID_MAPPED_ANDROID';
export const STEP_KEY_VERIFY_CUSTOMER_IAPS = 'ONB_CUSTOMER_IAPS_VERFIFIED';
export const STEP_KEY_SETUP_IAPS = 'ONB_IAPS_SETUP_IN_BUILDER';
export const STEP_KEY_FIREBASE_PROJECT = 'ONB_FIREBASE_PROJECT_SETUP';

//LAUNCH - Client
export const STEP_KEY_RELEASE_IOS_APP = 'ONB_IOS_APP_RELEASED';
export const STEP_KEY_RELEASE_ANDROID_APP = 'ONB_ANDROID_APP_RELEASED';
//LAUNCH - Admin
export const STEP_KEY_UPDATE_MCL_STATUS = 'ONB_MCL_STATUS_UPDATED';
export const STEP_KEY_UPDATE_HUBSPOT_STATUS = 'ONB_HUBSPOT_STATUS_UPDATED';

const ALL_STEP_KEYS = [
  STEP_KEY_EXPLORE,
  STEP_KEY_BOOK_CALL,
  STEP_KEY_PAYMENT,
  STEP_KEY_MCL,
  STEP_KEY_HUBSPOT,

  STEP_KEY_APPLE_DEVELOPER,
  STEP_KEY_APPLE_INVITE_VIDAPP,
  STEP_KEY_APPLE_CREDENTIALS,
  STEP_KEY_APPLE_ITUNES,

  STEP_KEY_ANDROID_DEVELOPER,
  STEP_KEY_ANDROID_INVITE_VIDAPP,
  STEP_KEY_ANDROID_CREDENTIALS,
  STEP_KEY_ANDROID_CURRENCY,

  STEP_KEY_KAJABI_PRODUCTS,
  STEP_KEY_VHX_ADMIN,
  STEP_KEY_WORDPRESS_ADMIN,
  STEP_KEY_ONE_PASSWORD,
  STEP_KEY_KAJABI_IOS_USER,
  STEP_KEY_KAJABI_TEST_USER,
  STEP_KEY_VHX_IOS_USER,
  STEP_KEY_VHX_TEST_USER,
  STEP_KEY_WORDPRESS_COMPLETE_INTEGRATION,

  STEP_KEY_OFFERS_CREATED,
  STEP_KEY_WORDPRESS_SUBSCRIPTIONS_CREATED,
  STEP_KEY_KAJABI_OFFERS_FOR_SALE,
  STEP_KEY_VHX_OFFERS_FOR_SALE,
  STEP_KEY_WORDPRESS_OFFERS_FOR_SALE,
  STEP_KEY_OFFERS_VALIDATED,
  STEP_KEY_IAP_SETTINGS_CONFIGURED,
  STEP_KEY_OFFER_IDS_SET,

  STEP_KEY_APP_STORE_FRONT,
  STEP_KEY_ONBOARDING_SCREENS,
  STEP_KEY_APP_STORE_SCREENSHOTS,
  STEP_KEY_BUILD_APP_CONTENT,

  STEP_KEY_FIGMA,
  STEP_KEY_VALIDATED_STORE_FRONT,
  STEP_KEY_VALIDATED_APP_STORE_SCREENSHOTS,

  STEP_KEY_MAP_IAPS,
  STEP_KEY_MAP_BUNDLE_ID_APPLE,
  STEP_KEY_MAP_BUNDLE_ID_ANDROID,
  STEP_KEY_VERIFY_CUSTOMER_IAPS,
  STEP_KEY_SETUP_IAPS,
  STEP_KEY_FIREBASE_PROJECT,

  STEP_KEY_RELEASE_IOS_APP,
  STEP_KEY_RELEASE_ANDROID_APP,
  STEP_KEY_UPDATE_MCL_STATUS,
  STEP_KEY_UPDATE_HUBSPOT_STATUS,
] as const;

export type OnboardingStepKey = (typeof ALL_STEP_KEYS)[number];

export interface OnboardingStage {
  title: string;
  steps: OnboardingStepKey[];
  adminSteps: OnboardingStepKey[];
  position?: number;
  dependency?: OnboardingStageKey;
}

export interface OnboardingStep {
  title: string;
  description?: string;
  helpText?: string;
  buttonText?: string;
  hideButtonIfComplete?: boolean;
  dependency?: OnboardingStepKey;
  blockerText?: string;
  link?: { title: string; url: string };
}

export const ONBOARDING_STAGES: Record<OnboardingStageKey, OnboardingStage> = {
  [STAGE_KEY_GET_STARTED]: {
    title: 'Get Started',
    steps: [STEP_KEY_EXPLORE, STEP_KEY_BOOK_CALL, STEP_KEY_PAYMENT],
    adminSteps: [STEP_KEY_MCL, STEP_KEY_HUBSPOT],
    position: 1,
  },
  [STAGE_KEY_APP_STORE]: {
    title: 'App Store Set Up',
    steps: [
      STEP_KEY_APPLE_DEVELOPER,
      STEP_KEY_APPLE_INVITE_VIDAPP,
      STEP_KEY_APPLE_CREDENTIALS,
      STEP_KEY_ANDROID_DEVELOPER,
      STEP_KEY_ANDROID_INVITE_VIDAPP,
      STEP_KEY_ANDROID_CREDENTIALS,
      STEP_KEY_ANDROID_CURRENCY,
    ],
    adminSteps: [STEP_KEY_APPLE_ITUNES],
    position: 2,
    dependency: STAGE_KEY_GET_STARTED,
  },
  [STAGE_KEY_APP_STORE_APPLE]: {
    title: 'Apple Integration',
    steps: [STEP_KEY_APPLE_DEVELOPER, STEP_KEY_APPLE_INVITE_VIDAPP, STEP_KEY_APPLE_CREDENTIALS],
    adminSteps: [STEP_KEY_APPLE_ITUNES],
  },
  [STAGE_KEY_APP_STORE_ANDROID]: {
    title: 'Android Integration',
    steps: [
      STEP_KEY_ANDROID_DEVELOPER,
      STEP_KEY_ANDROID_INVITE_VIDAPP,
      STEP_KEY_ANDROID_CREDENTIALS,
      STEP_KEY_ANDROID_CURRENCY,
    ],
    adminSteps: [],
  },
  [STAGE_KEY_PLATFORM]: {
    title: 'Platform Integration',
    steps: [], // steps will be set dynamically based on DataSource in OnboardingChecklistProvider
    adminSteps: [], // adminSteps will be set dynamically based on DataSource in OnboardingChecklistProvider
    position: 3,
    dependency: STAGE_KEY_APP_STORE,
  },
  [STAGE_KEY_IAP]: {
    title: 'In-App Purchases',
    steps: [], // steps will be set dynamically based on DataSource in OnboardingChecklistProvider
    adminSteps: [], // adminSteps will be set dynamically based on DataSource in OnboardingChecklistProvider
    position: 4,
    dependency: STAGE_KEY_PLATFORM,
  },
  [STAGE_KEY_BUILD]: {
    title: 'Build',
    steps: [
      STEP_KEY_APP_STORE_FRONT,
      STEP_KEY_ONBOARDING_SCREENS,
      STEP_KEY_APP_STORE_SCREENSHOTS,
      STEP_KEY_BUILD_APP_CONTENT,
    ],
    adminSteps: [STEP_KEY_FIGMA, STEP_KEY_VALIDATED_STORE_FRONT, STEP_KEY_VALIDATED_APP_STORE_SCREENSHOTS],
    position: 5,
    dependency: STAGE_KEY_IAP,
  },
  [STAGE_KEY_REVIEW_AND_SUBMISSION]: {
    title: 'Review & Submission',
    steps: [],
    adminSteps: [
      STEP_KEY_MAP_IAPS,
      STEP_KEY_MAP_BUNDLE_ID_APPLE,
      STEP_KEY_MAP_BUNDLE_ID_ANDROID,
      STEP_KEY_VERIFY_CUSTOMER_IAPS,
      STEP_KEY_SETUP_IAPS,
      STEP_KEY_FIREBASE_PROJECT,
    ],
    position: 6,
  },
  [STAGE_KEY_LAUNCH]: {
    title: 'Launch',
    steps: [STEP_KEY_RELEASE_IOS_APP, STEP_KEY_RELEASE_ANDROID_APP],
    adminSteps: [STEP_KEY_UPDATE_MCL_STATUS, STEP_KEY_UPDATE_HUBSPOT_STATUS],
    position: 7,
  },
};

export const ONBOARDING_STEPS: Record<OnboardingStepKey, OnboardingStep> = {
  // GET_STARTED
  [STEP_KEY_EXPLORE]: {
    title: 'Explore the VidApp Builder',
    description: 'Take a quick tour of our Builder, where you will build, launch and manage your app.',
    buttonText: 'Start Tour',
  },
  [STEP_KEY_BOOK_CALL]: {
    title: 'Book in a call',
    description: 'To finish onboarding, book in a call with a member of our team.',
    buttonText: 'Book A Call',
  },
  [STEP_KEY_PAYMENT]: {
    title: 'Plan Creation',
    description: 'Before we begin launching your app, please confirm your payment plan with our sales team.',
    buttonText: 'Make Payment',
    blockerText: 'This step will unlock once we have had an onboarding call with you.',
    hideButtonIfComplete: true,
  },
  [STEP_KEY_MCL]: {
    title: 'Add client and contact to MCL',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
  },
  [STEP_KEY_HUBSPOT]: {
    title: 'Create company & add contacts in HubSpot',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
  },

  // APP_STORE_APPLE
  [STEP_KEY_APPLE_DEVELOPER]: {
    title: 'Setup Apple Developer account',
    description: 'To launch your iOS app, enroll in the Apple Developer Program to set up your developer account.',
    link: {
      title: 'Follow these steps',
      url: 'https://wearevidapp.zendesk.com/hc/en-nz/articles/4407780463513-How-do-I-enroll-in-the-Apple-Developer-Program',
    },
  },
  [STEP_KEY_APPLE_INVITE_VIDAPP]: {
    title: 'Invite VidApp accounts to App Store Connect',
    link: {
      title: 'Follow these steps',
      url: 'https://wearevidapp.zendesk.com/hc/en-nz/articles/4407780537113-How-do-I-invite-VidApp-to-my-Apple-Developer-App-Store-Connect-account',
    },
    dependency: STEP_KEY_APPLE_DEVELOPER,
    blockerText: 'Complete Setup Apple Developer account to unlock this step.',
  },
  [STEP_KEY_APPLE_CREDENTIALS]: {
    title: 'Enter and save App Store Connect details',
    dependency: STEP_KEY_APPLE_DEVELOPER,
    blockerText: 'Complete Setup Apple Developer account to unlock this step.',
    link: {
      title: 'Follow these steps',
      url: 'https://wearevidapp.zendesk.com/hc/en-nz/articles/4407780414105-How-do-I-generate-my-App-Store-Connect-API-key',
    },
  },
  [STEP_KEY_APPLE_ITUNES]: {
    title: 'Input iTunes user email & password',
    description:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },

  // APP_STORE_ANDROID
  [STEP_KEY_ANDROID_DEVELOPER]: {
    title: 'Setup Google developer account',
    description: 'To launch your Android app, enroll in the Google Developer Program to set up your developer account.',
    helpText: 'This step varies depending on whether you have an existing Google account.',
    link: {
      title: 'Follow these steps',
      url: 'https://wearevidapp.zendesk.com/hc/en-nz/articles/6652250278041-How-do-I-register-for-my-Google-Play-Developer-account',
    },
  },
  [STEP_KEY_ANDROID_INVITE_VIDAPP]: {
    title: 'Invite VidApp accounts to Google Play Store',
    link: {
      title: 'Follow these steps',
      url: 'https://wearevidapp.zendesk.com/hc/en-nz/articles/6652276400537-How-do-I-invite-VidApp-to-my-Google-Developer-Console',
    },
    dependency: STEP_KEY_ANDROID_DEVELOPER,
    blockerText: 'Complete Setup Google developer account to unlock this step.',
  },
  [STEP_KEY_ANDROID_CREDENTIALS]: {
    title: 'Enter and save Google Play details',
    link: {
      title: 'Follow these steps',
      url: 'https://wearevidapp.zendesk.com/hc/en-nz/articles/6650734541209-How-do-I-create-my-Google-Service-Account',
    },
    dependency: STEP_KEY_ANDROID_DEVELOPER,
    blockerText: 'Complete Setup Google developer account to unlock this step.',
  },
  [STEP_KEY_ANDROID_CURRENCY]: {
    title: 'Check Play Store currency is aligned',
    dependency: STEP_KEY_ANDROID_DEVELOPER,
    blockerText: 'Complete Setup Google developer account to unlock this step.',
  },

  // PLATFORM
  [STEP_KEY_KAJABI_PRODUCTS]: {
    title: 'Add your Kajabi products',
    description:
      'Go to our Content page to add new courses. Your six most recent courses have already been synced. New products can also be easily added any time in the future.',
  },
  [STEP_KEY_VHX_ADMIN]: {
    title: 'Add admin user to Vimeo OTT',
    description:
      'Invite us to your Vimeo OTT account. This enables the seamless transfer of your content, subscriptions, and contacts from web to app.',
  },
  [STEP_KEY_WORDPRESS_ADMIN]: {
    title: 'Provide a WordPress admin account',
    description: 'Provide VidApp with a WordPress admin account.',
  },
  [STEP_KEY_ONE_PASSWORD]: {
    title: 'Add login details to 1Password',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_KAJABI_IOS_USER]: {
    title: 'Add VidApp iOS user into Kajabi and VidApp Builder',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_KAJABI_TEST_USER]: {
    title: 'Add VidApp test user into Kajabi and VidApp Builder',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_VHX_IOS_USER]: {
    title: 'Add VidApp iOS user into VHX and VidApp Builder',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_VHX_TEST_USER]: {
    title: 'Add VidApp test user into VHX and VidApp Builder',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_WORDPRESS_COMPLETE_INTEGRATION]: {
    title: 'Developer step - complete integration',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },

  // IAP
  [STEP_KEY_OFFERS_CREATED]: {
    title: 'Duplicate existing or create a new subscription offer in Kajabi',
  },
  [STEP_KEY_WORDPRESS_SUBSCRIPTIONS_CREATED]: {
    title: 'Create new membership levels in WordPress',
  },
  [STEP_KEY_KAJABI_OFFERS_FOR_SALE]: {
    title: 'Mark offers ‘For Sale’',
  },
  [STEP_KEY_VHX_OFFERS_FOR_SALE]: {
    title: 'Mark offers ‘For Sale’',
  },
  [STEP_KEY_WORDPRESS_OFFERS_FOR_SALE]: {
    title: 'Mark offers ‘For Sale’',
  },
  [STEP_KEY_OFFERS_VALIDATED]: {
    title: 'Check all details and check if it has synced to Builder',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_IAP_SETTINGS_CONFIGURED]: {
    title: 'Configure in-app purchase settings',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_OFFER_IDS_SET]: {
    title: 'Edit offer ID to match Apple and Google product ID',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },

  // BUILD
  [STEP_KEY_APP_STORE_FRONT]: {
    title: 'Add App Store Store Front details',
    link: {
      title: 'Follow these steps',
      url: 'https://wearevidapp.zendesk.com/hc/en-nz/articles/4407780541849-App-Store-description-best-practices',
    },
  },
  [STEP_KEY_ONBOARDING_SCREENS]: {
    title: 'Create and upload onboarding screens',
    description:
      "Onboarding Screens introduce your app to first-time users and non-members. They effectively showcase your app's value, features, and benefits to new users.",
    helpText:
      'If you do not upload your own onboarding screens we will provide you with one. You will be able to upload one later if you decide to skip now.',
    link: {
      title: 'Follow these steps',
      url: 'https://wearevidapp.zendesk.com/hc/en-nz/articles/4407780575001-Onboarding-Screens-101',
    },
  },
  [STEP_KEY_APP_STORE_SCREENSHOTS]: {
    title: 'Create and upload App Store screenshots',
    description:
      "App Store Screenshots are images on your VidApp's product page in the Apple App Store and Google Play Store. They highlight your app's key features, functionality, and value. They help with user discovery and understanding.",
    link: {
      title: 'Follow these steps',
      url: 'https://wearevidapp.zendesk.com/hc/en-nz/articles/4407786186009-App-Store-Screenshots-101',
    },
  },
  [STEP_KEY_BUILD_APP_CONTENT]: {
    title: 'Complete build of app in Drag & Drop',
  },
  [STEP_KEY_FIGMA]: {
    title: 'Create Figma project',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_VALIDATED_STORE_FRONT]: {
    title: 'Review store front details',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_VALIDATED_APP_STORE_SCREENSHOTS]: {
    title: 'Review App Store screenshots',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },

  // REVIEW_AND_SUBMISSION
  [STEP_KEY_MAP_IAPS]: {
    title: 'Map in-app purchases to existing in-app purchases',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_MAP_BUNDLE_ID_APPLE]: {
    title: 'Map app Bundle ID to existing Apple Bundle ID',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_MAP_BUNDLE_ID_ANDROID]: {
    title: 'Map app Bundle ID to existing Android Bundle ID',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_VERIFY_CUSTOMER_IAPS]: {
    title: "Verify customer's set up of IAP",
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_SETUP_IAPS]: {
    title: 'Set up IAP in VidApp Builder',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_FIREBASE_PROJECT]: {
    title: 'Create Firebase project',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },

  // LAUNCH
  [STEP_KEY_RELEASE_IOS_APP]: {
    title: 'Release iOS App',
    description: 'Follow the below steps to release your iOS app.',
    buttonText: 'iOS app released',
  },
  [STEP_KEY_RELEASE_ANDROID_APP]: {
    title: 'Release Android App',
    description: 'Follow the below steps to release your Android app.',
    buttonText: 'Android app released',
  },
  [STEP_KEY_UPDATE_MCL_STATUS]: {
    title: "Change MCL Client Member status to 'New Customer'",
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  [STEP_KEY_UPDATE_HUBSPOT_STATUS]: {
    title: 'Update company status in HubSpot',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
};
