import styled from 'styled-components';

import { Template } from 'api';
import { FONT_16PX_MEDIUM } from 'font';

import { AddTemplate, DrawerScrollContainer } from 'app/modules/build-dragdrop/Builder/drawer/components';

const TemplateGroupWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;
const TemplateGroupTitle = styled.div`
  ${FONT_16PX_MEDIUM};
  color: inherit;
  margin-bottom: 16px;
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

interface AddSectionProps {
  groupedTemplates: [string, Template[]][];
  isFeatureTab?: boolean;
}

export const AddSection = ({ groupedTemplates, isFeatureTab }: AddSectionProps) => (
  <DrawerScrollContainer>
    {groupedTemplates.map(([groupName, templates]) => {
      if (templates.length === 0) {
        // Don't render title of empty groups of templates
        return null;
      }

      const groupIsFeature = groupName === 'Feature';
      if ((!isFeatureTab && groupIsFeature) || (isFeatureTab && !groupIsFeature)) {
        return null;
      }

      return (
        <TemplateGroupWrapper key={groupName}>
          {groupName !== 'Feature' && <TemplateGroupTitle>{groupName}</TemplateGroupTitle>}
          <Container>
            {templates.map((t) => (
              <AddTemplate template={t} key={t.TemplateId} prefix={groupName} />
            ))}
          </Container>
        </TemplateGroupWrapper>
      );
    })}
  </DrawerScrollContainer>
);
