import { useAppBeingEdited } from 'app-context';
import { useAxiosInstance } from 'api';
import { useInfiniteQuery } from 'react-query';
import {
  AmityChildPost,
  AmityComment,
  AmityCommunity,
  AmityFile,
  AmityPost,
  AmityUser,
  getCommunityPosts,
} from 'api/community';
import { useEffect, useState } from 'react';

interface UseCommunityPostsOptions {
  flagged?: boolean;
}

export const useCommunityPosts = (communityId: string, { flagged }: UseCommunityPostsOptions) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();

  const [posts, setPosts] = useState<Record<string, AmityPost>>({});
  const [childPosts, setChildPosts] = useState<Record<string, AmityChildPost>>({});
  const [comments, setComments] = useState<Record<string, AmityComment>>({});
  const [users, setUsers] = useState<Record<string, AmityUser>>({});
  const [files, setFiles] = useState<Record<string, AmityFile>>({});
  const [communities, setCommunities] = useState<Record<string, AmityCommunity>>({});
  const [pagesFetched, setPagesFetched] = useState<number>(0);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const { data, fetchNextPage, isLoading, isError, hasNextPage } = useInfiniteQuery(
    ['community', 'posts', communityId, flagged],
    ({ pageParam }) => getCommunityPosts(client, appId, communityId, { flagged, page_token: pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage?.data?.paging?.next,
      enabled: !!communityId,
      onError: () => setErrorOccurred(true),
      retry: false,
      refetchOnWindowFocus: !errorOccurred,
      refetchOnReconnect: !errorOccurred,
    },
  );

  useEffect(() => {
    if (data && !isLoading && !isError) {
      // These can be unflagged or deleted, so they may no longer exist after an invalidation,
      // need to reset to ensure the old data isnt retained
      setPosts({});
      setComments({});
      setUsers({});

      for (const { data: page } of data.pages) {
        if (page?.posts) {
          setPosts((existing) => {
            for (const post of page?.posts) {
              existing[post._id] = post;
            }
            return { ...existing };
          });
        }
        if (page?.postChildren) {
          setChildPosts((existing) => {
            for (const post of page?.postChildren) {
              existing[post._id] = post;
            }
            return { ...existing };
          });
        }
        if (page?.comments) {
          setComments((existing) => {
            for (const comment of page?.comments) {
              existing[comment.commentId] = comment;
            }
            return { ...existing };
          });
        }
        if (page?.users) {
          setUsers((existing) => {
            for (const u of page?.users) {
              existing[u.userId] = u;
            }
            return { ...existing };
          });
        }
        if (page?.files) {
          setFiles((existing) => {
            for (const f of page?.files) {
              existing[f.fileId] = f;
            }
            return { ...existing };
          });
        }
        if (page?.communities) {
          setCommunities((existing) => {
            for (const c of page?.communities) {
              existing[c.communityId] = c;
            }
            return { ...existing };
          });
        }

        // Get up to 300 of the most recent posts
        if (hasNextPage && pagesFetched < 3) {
          setPagesFetched((a) => a + 1);
          fetchNextPage().then(() => {
            console.debug('Got next page of community posts');
          });
        }
      }
    }
  }, [data, pagesFetched]);

  return { posts, childPosts, comments, users, files, communities, fetchNextPage, hasNextPage, isLoading, isError };
};
