import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { useCollections } from 'hooks';
import { getCommunityIdFromCollections, padStart } from 'utils';
import { CustomTable, CustomCell, PageContainer, TableColumn } from 'components';
import { AmityComment, AmityPost } from 'api';
import { NEUTRAL_10_COLOUR, NEUTRAL_4_COLOUR, PAGE_CONTAINER_WIDE_WIDTH } from 'theme';
import { FONT_10PX_MEDIUM, FONT_12PX_MEDIUM } from 'font';
import { ImageIcon } from 'icons';

import { useCommunityPosts } from 'app/modules/community/Posts/hooks/useCommunityPosts';
import {
  CommunityPostsProvider,
  useCommunityPostContext,
} from 'app/modules/community/Posts/providers/CommunityPostProvider';
import { Center, EllipsisText, SearchBox, StyledSearchIcon } from 'app/modules/community/Shared';
import { CommunityModerationModal } from 'app/modules/community/Moderation/components/CommunityModerationModal';

const MemberCell = ({ data }: { data: AmityPost | AmityComment }) => {
  const { users } = useCommunityPostContext();
  const userId = useMemo(() => {
    if ('postedUserId' in data) {
      return data.postedUserId;
    }
    if ('userId' in data) {
      return data.userId;
    }
    return '';
  }, [data]);
  return (
    <CustomCell>
      <EllipsisText>{users[userId].displayName}</EllipsisText>
    </CustomCell>
  );
};
const Tag = styled.div`
  background-color: ${NEUTRAL_4_COLOUR};
  ${FONT_10PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
  padding: 0 4px;
`;
const NumberCell = styled(CustomCell)`
  ${FONT_12PX_MEDIUM};
`;
const ViewCell = styled(CustomCell)`
  padding: 8px 4px;
`;

const hasImage = (data: AmityComment | AmityPost) => {
  if ('commentId' in data) {
    return !!(data.attachments && data.attachments.find((a) => a.type === 'image'));
  }
  if ('postId' in data) {
    return data.children.length > 0;
  }
  return false;
};

const columns: TableColumn<(AmityPost | AmityComment) & { id: string }>[] = [
  {
    heading: 'Member',
    width: 124,
    render: (data) => <MemberCell data={data} />,
  },
  {
    heading: 'Content',
    width: 'grow',
    render: (data) => (
      <CustomCell>
        <EllipsisText>{data.data.text}</EllipsisText>
      </CustomCell>
    ),
  },
  {
    heading: 'Image',
    width: 60,
    render: (data) => (
      <CustomCell>
        {hasImage(data) && (
          <Center>
            <ImageIcon />
          </Center>
        )}
      </CustomCell>
    ),
  },
  {
    heading: 'Type',
    width: 85,
    render: (data) => (
      <CustomCell>
        <Tag>{'commentId' in data ? 'COMMENT' : 'POST'}</Tag>
      </CustomCell>
    ),
  },
  {
    heading: 'Flags',
    width: 56,
    render: (data) => <NumberCell>{padStart(data.flagCount, 3)}</NumberCell>,
  },
  {
    id: 'Action',
    heading: '',
    width: 60,
    render: (data) => (
      <ViewCell>
        <CommunityModerationModal data={data} />
      </ViewCell>
    ),
  },
];

export const CommunityModerationTable = () => {
  const { data } = useCollections();
  const communityId = useMemo(() => {
    if (!data) {
      return '';
    }
    return getCommunityIdFromCollections(data.collections);
  }, [data]);

  const { posts, comments, isError } = useCommunityPosts(communityId ?? '', { flagged: true });
  const [query, setQuery] = useState<string>('');

  const rows = useMemo(() => {
    const output = [];
    const filteredPosts = Object.values(posts)
      .filter((p) => !p.isDeleted)
      .map((post) => ({
        ...post,
        id: post._id,
      }));

    for (const post of filteredPosts) {
      if (post.flagCount > 0) {
        // Include flagged posts in table
        output.push(post);
      }
      if (post.hasFlaggedComment) {
        for (const c of post.comments) {
          const comment = comments[c];
          if (comment.flagCount > 0 && !comment.isDeleted) {
            output.push({ ...comment, id: comment._id });
          }
        }
      }
    }

    return output
      .filter((data) => {
        return query ? data.data.text.toLowerCase().includes(query.toLowerCase()) : true;
      })
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [posts, comments, query]);

  if (!communityId) {
    // There is no CommunityID on any of the tabs
    // Need some sort of default state
    return (
      <PageContainer
        heading="Moderation"
        subheading="Community is not setup, Contact VidApp for support with this feature"
      ></PageContainer>
    );
  }

  return (
    <PageContainer heading="Moderation" contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH} isError={isError}>
      <CommunityPostsProvider communityId={communityId} flagged>
        <SearchBox
          width="50%"
          prefix={<StyledSearchIcon />}
          placeholder="Search"
          onChange={(e) => setQuery(e.target.value)}
          size="middle"
        />
        <CustomTable
          columns={columns}
          data={rows}
          query={query}
          emptyTitle="No Moderation Tasks"
          emptyDescription="No posts or comments have been flagged."
        />
      </CommunityPostsProvider>
    </PageContainer>
  );
};
