import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { CustomButton, Dropdown, DropdownOptionInterface, InfoModal } from 'components';
import {
  DataSource,
  GROUP_LABEL,
  SOURCE_KAJABI,
  SOURCE_TYPE_COLLECTION,
  SOURCE_TYPE_WORKOUT,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
} from 'api';
import { useAppProperties, useSecureAppProperties, useSyncApp, useSyncWordPress } from 'hooks';
import { getDataSourceDisplayName } from 'utils';
import { CollectionIcon, DumbbellIcon, PlusIcon } from 'icons';
import { useDataSource } from 'providers';

import { AddKajabiProductModal } from './AddKajabiProductModal';
import { useBanner } from 'providers/banner-provider';
import { useContentNavigationContext } from 'app/modules/content/providers';
import { AddJWPlayerModal } from './AddJWPlayerModal';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 15px;
  min-height: 51px;
`;

const StyledButton = styled(CustomButton)`
  &&&& {
    margin-left: 10px;
    margin-top: 15px;
  }
`;

const StyledCollectionIcon = styled(CollectionIcon)`
  svg {
    fill: none;
  }
`;

const CreateButton = ({ label, onClick }: { label: string; onClick?: () => void }) => (
  <StyledButton medium icon={<PlusIcon />} onClick={onClick}>
    {label}
  </StyledButton>
);

const CreateGroup = ({ options }: { options: DropdownOptionInterface[] }) => (
  <Dropdown trigger={<CreateButton label="Create New" />} options={options} menuMinWidth="198px" />
);

export const ContentHeaderButtons = () => {
  const { data: appProperties } = useAppProperties({ staleTime: Infinity });
  const { data: secureAppProperties } = useSecureAppProperties();
  const syncApp = useSyncApp({ isOnboarding: false });
  const { CMSEnabled, circuitsEnabled, navigate, changeTab } = useContentNavigationContext();
  const syncWordPress = useSyncWordPress();
  const [modalVisible, setModalVisible] = useState(false);
  const [jwModal, setJwModal] = useState(false);

  const dataSource = useDataSource();
  const { setRestartBanner } = useBanner();

  const showKajabi = dataSource === SOURCE_KAJABI;
  const showVHX = dataSource === SOURCE_VHX;
  const showVimeo =
    secureAppProperties?.VimeoToken && secureAppProperties?.VimeoClientId && secureAppProperties?.VimeoClientSecret;
  const showWordPress =
    appProperties?.WordPressSyncEnabled === '1' &&
    secureAppProperties?.WordPressBackendLoginURL &&
    secureAppProperties?.WordPressBackendLoginEmail &&
    secureAppProperties?.WordPressBackendPassword;
  const showJWPlayer = !!secureAppProperties?.JWPlayerSiteId && dataSource !== SOURCE_USCREEN;

  const handleSync = useCallback(
    (overrideDataSource: DataSource) => {
      if (overrideDataSource === SOURCE_WORDPRESS) {
        syncWordPress.mutate({});
      } else {
        syncApp.mutate({ overrideDataSource: overrideDataSource });
        // Need delay before the sync tracing record is created
        setTimeout(() => {
          setRestartBanner(true);
        }, 3000);
      }

      InfoModal({
        title: `Your ${getDataSourceDisplayName(overrideDataSource)} content is being synced`,
        content: "You'll receive an email once this is complete",
      });
    },
    [dataSource, appProperties, syncApp, syncWordPress, setRestartBanner],
  );

  const syncKajabiProduct = useCallback(
    (productId: string) => {
      syncApp.mutate({ overrideDataSource: SOURCE_KAJABI, productId });
      setTimeout(() => {
        setRestartBanner(true);
      }, 3000);
      InfoModal({
        title: `Success!`,
        content: `Your course has been added. Please refresh the VidApp Builder once you receive the sync complete email.`,
      });
    },
    [syncApp, setRestartBanner],
  );

  const goToWorkoutsTab = () => {
    changeTab('datasource', SOURCE_VIDAPP);
    changeTab('filter', 'WorkoutCollections');
  };
  const createNewGroup = () => {
    changeTab('datasource', SOURCE_VIDAPP);
    changeTab('filter', 'VidAppCollections');
    navigate(`new_${SOURCE_TYPE_COLLECTION.toLowerCase()}`);
  };
  const createNewWorkout = () => {
    navigate(`new_${SOURCE_TYPE_WORKOUT.toLowerCase()}`);
  };

  const CreateWorkoutButton = () => <CreateButton label="Create New Workout" onClick={createNewWorkout} />;
  const CreateGroupButton = () => <CreateButton label={`Create New ${GROUP_LABEL}`} onClick={createNewGroup} />;
  const SyncButton = ({ dataSource, disabled }: { dataSource: DataSource; disabled?: boolean }) => (
    <StyledButton
      secondary
      medium
      onClick={() => handleSync(dataSource)}
      disabled={disabled}
      tooltip={disabled ? 'Temporarily disabled by VidApp' : undefined}
    >
      {`Sync ${getDataSourceDisplayName(dataSource)}`}
    </StyledButton>
  );

  return (
    <ButtonsContainer>
      {showKajabi && (
        <StyledButton secondary medium onClick={() => setModalVisible(true)}>
          Add New Kajabi Course
        </StyledButton>
      )}
      {showVHX && <SyncButton dataSource={SOURCE_VHX} />}
      {showVimeo && <SyncButton dataSource={SOURCE_VIMEO} />}
      {showWordPress && <SyncButton dataSource={SOURCE_WORDPRESS} />}
      {showJWPlayer && (
        <StyledButton icon={<PlusIcon />} secondary medium onClick={() => setJwModal(true)}>
          Add Video in JW Player
        </StyledButton>
      )}
      {CMSEnabled && circuitsEnabled ? (
        <CreateGroup
          options={[
            { label: GROUP_LABEL, icon: <StyledCollectionIcon />, onClick: createNewGroup },
            {
              label: 'Workout',
              icon: <DumbbellIcon />,
              onClick: () => {
                goToWorkoutsTab();
                createNewWorkout();
              },
            },
          ]}
        />
      ) : CMSEnabled ? (
        <CreateGroupButton />
      ) : circuitsEnabled ? (
        <CreateWorkoutButton />
      ) : null}
      <AddKajabiProductModal
        visible={modalVisible}
        setVisible={setModalVisible}
        syncKajabiProduct={syncKajabiProduct}
      />
      <AddJWPlayerModal jwModal={jwModal} setJwModal={setJwModal} />
    </ButtonsContainer>
  );
};
