import { animated, useSpring } from '@react-spring/web';
import styled from 'styled-components';

import { FONT_12PX_SEMIBOLD } from 'font';
import { NEUTRAL_4_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from 'theme';

const Wrapper = styled.div`
  width: 100%;
  height: 35px;

  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  border-radius: 9px;
  background-color: ${NEUTRAL_4_COLOUR};
  flex-shrink: 0;
`;
const FloatingTextWrapper = styled.div<{ focus: boolean; $pillWidth: number }>`
  width: ${({ $pillWidth }) => `${$pillWidth}%`};
  text-align: center;
  z-index: 20;
  color: ${({ focus }) => (focus ? NEUTRAL_9_COLOUR : NEUTRAL_7_COLOUR)};
  ${FONT_12PX_SEMIBOLD}
  user-select: none;
`;

const Pill = styled.div<{ $pillWidth: number }>`
  background-color: white;
  border-radius: 4px;

  margin: 4px;
  width: ${({ $pillWidth }) => `calc(${$pillWidth}% - 8px)`};
  height: calc(100% - 8px);
  box-sizing: border-box;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
`;

interface Option {
  key: string;
  label?: string;
}
interface PillSegmentedControllerProps {
  activeKey: string;
  onChange: (key: string) => void;
  options: Option[];
}

export const PillSegmentedController = ({ activeKey, onChange, options }: PillSegmentedControllerProps) => {
  const pillWidth = 100 / options.length;
  const getOptionPosition = (key: string) => options.findIndex((o) => o.key === key);
  const pillLeftPosition = `${pillWidth * getOptionPosition(activeKey)}%`;
  const styles = useSpring({ left: pillLeftPosition });

  return (
    <Wrapper>
      {options.map(({ key, label }) => (
        <FloatingTextWrapper onClick={() => onChange(key)} key={key} focus={activeKey === key} $pillWidth={pillWidth}>
          {label ?? key}
        </FloatingTextWrapper>
      ))}
      <Pill as={animated.div} style={styles} $pillWidth={pillWidth} />
    </Wrapper>
  );
};
