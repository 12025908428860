import styled from 'styled-components';

import { Form } from 'antd';
import { CustomButton, PageContainer, SettingsGroup, SettingsFormItem, SettingsTextInput } from 'components';
import { useLocalAppProperties, useSaveContext, useUnsavedChanges } from 'providers';
import { PAGE_CONTAINER_MIDDLE_WIDTH } from 'theme';

import { useMFFSettings } from '../hooks/useMFFSettings';
import { ModuleCard } from './components/ModuleCard';
import { PREVIEW_USERS_MODULES } from '../const';

import { useModularFeatures } from 'mff';

const ModuleContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const MFFModules = () => {
  const { modules, isLoading: modulesIsLoading, isError: modulesIsError } = useModularFeatures();
  const { properties } = useLocalAppProperties();
  const { isSaving } = useSaveContext();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const { isLoading: secureAppPropertiesIsLoading, isError: secureAppPropertiesIsError, form, save } = useMFFSettings();

  return (
    <>
      <PageContainer
        heading="Modules"
        subheading="Configure an app’s Modules"
        headingButton={
          <CustomButton medium onClick={() => form.submit()} loading={isSaving} disabled={!unsavedChanges}>
            Save
          </CustomButton>
        }
        contentMaxWidth={PAGE_CONTAINER_MIDDLE_WIDTH}
        isLoading={modulesIsLoading || secureAppPropertiesIsLoading || !properties}
        isError={modulesIsError || secureAppPropertiesIsError}
      >
        <Form
          form={form}
          onFinish={save}
          onValuesChange={(values) => {
            setUnsavedChanges(!!values[PREVIEW_USERS_MODULES]);
          }}
        >
          <SettingsGroup title="Settings">
            <SettingsFormItem name={PREVIEW_USERS_MODULES}>
              <SettingsTextInput
                label="Users with Module preview access"
                placeholder="Enter a list of comma separated user emails"
                autoComplete="off"
              />
            </SettingsFormItem>
          </SettingsGroup>
        </Form>

        <SettingsGroup title="Modules access">
          <ModuleContainer>
            {modules?.map((module) => (
              <ModuleCard key={module.ID} module={module} />
            ))}
          </ModuleContainer>
        </SettingsGroup>
      </PageContainer>
    </>
  );
};
