import { Alert } from 'antd';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  MOST_WATCHED_7_DAYS_SOURCE_ID,
  SOURCE_KAJABI,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_WORDPRESS,
  Template,
} from 'api';
import { PillSegmentedController } from 'components';
import { FONT_14PX_REGULAR } from 'font';
import { useAppProperties, useTemplates } from 'hooks';
import { XIcon } from 'icons';
import { useContent, useDataSource } from 'providers';
import { NEUTRAL_10_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from 'theme';

import { AddContent, AddSection, DrawerHeading } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import {
  FEATURE_TEMPLATE_ALLCOURSES,
  FEATURE_TEMPLATE_MOST_WATCHED,
  FEATURE_TEMPLATE_MYCOURSES,
  FEATURE_TEMPLATE_TVOD,
} from '../../../const';
import { groupTemplates } from '../../../util';

import { MODULAR_FEATURE_SECTIONS, useModularFeatures } from 'mff';

const ADD_KEY_CONTENT = 'Content';
const ADD_KEY_SECTIONS = 'Sections';
const ADD_KEY_FEATURES = 'Features';

const DrawerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const AddHeader = styled.div`
  padding-top: 21px;
  padding-bottom: 24px;
`;

const StyledAlert = styled(Alert)`
  &&&& {
    color: ${NEUTRAL_10_COLOUR};
    ${FONT_14PX_REGULAR};
    border-radius: 6px;
    margin-bottom: 24px;
  }
`;

const CloseButton = styled(XIcon)`
  &&&& {
    position: absolute;
    top: 28px;
    left: 28px;

    font-size: 21px;
    color: ${NEUTRAL_7_COLOUR};
    cursor: pointer;

    :hover {
      color: ${NEUTRAL_9_COLOUR};
    }
  }
`;

const getFeatureTemplates = (groupedTemplates: [string, Template[]][]) => {
  const { 1: templates } = groupedTemplates.find(([name]) => name === 'Feature') || { 1: [] as Template[] };
  return templates;
};

export const AddDrawer = () => {
  const [activeKey, setActiveKey] = useState(ADD_KEY_CONTENT);

  const { isAddPanelEnabled, setDrawerContext } = useBuilderContext();
  const dataSource = useDataSource();
  const { data: appProperties } = useAppProperties();
  const { getTemplateOptionsByType } = useTemplates();
  const { collections } = useContent();
  const { modularFeatureIsEnabled } = useModularFeatures();

  const disabledMessage = useMemo(() => {
    if (dataSource === SOURCE_KAJABI) {
      return 'Content and sections can not be added to linked sub-tabs, Kajabi Courses, Modules or Sub-Modules.';
    } else if (dataSource === SOURCE_VHX) {
      return 'Content and sections can not be added to linked sub-tabs, Vimeo OTT Categories or Collections.';
    } else if (dataSource === SOURCE_USCREEN) {
      return 'Content and sections can not be added to linked sub-tabs, Uscreen Categories or Playlists.';
    } else if (dataSource === SOURCE_WORDPRESS) {
      return 'Content and sections can not be added to linked sub-tabs and WordPress Collections';
    }
    return 'Content and sections can not be added to linked sub-tab and source content';
  }, [dataSource]);

  // Get template groups to be used in Sections panel and show Features panel if there are any
  const groupedTemplates = useMemo(() => {
    const templates = getTemplateOptionsByType('block');
    const groupedTemplates = templates ? groupTemplates(templates, []) : [];
    const featureTemplates = getFeatureTemplates(groupedTemplates);

    const mostWatchedCollection = Object.values(collections).find((c) => c.SourceId == MOST_WATCHED_7_DAYS_SOURCE_ID);
    if (mostWatchedCollection) {
      featureTemplates?.push({ TemplateId: FEATURE_TEMPLATE_MOST_WATCHED, DisplayName: 'Most Watched' } as Template);
    }

    if (dataSource === SOURCE_KAJABI) {
      featureTemplates?.push({ TemplateId: FEATURE_TEMPLATE_MYCOURSES, DisplayName: 'My Courses' } as Template);
      featureTemplates?.push({ TemplateId: FEATURE_TEMPLATE_ALLCOURSES, DisplayName: 'All Courses' } as Template);
    } else if (dataSource === SOURCE_VHX) {
      const myProductsCollection = Object.values(collections).find((c) => c.SourceId == 'MyProducts');
      // If they don't have any TVOD content the collection won't sync
      if (myProductsCollection) {
        featureTemplates?.push({ TemplateId: FEATURE_TEMPLATE_TVOD, DisplayName: 'TVOD' } as Template);
      }
    }

    // Add any modular features
    Object.keys(MODULAR_FEATURE_SECTIONS).forEach((id: string) => {
      if (modularFeatureIsEnabled(id)) {
        const modularFeature = MODULAR_FEATURE_SECTIONS[id];
        if (modularFeature) {
          featureTemplates?.push({
            TemplateId: modularFeature.featureTemplate,
            DisplayName: modularFeature.name,
          } as Template);
        }
      }
    });

    return groupedTemplates;
  }, [getTemplateOptionsByType, groupTemplates, appProperties]);

  const showFeatures = useMemo(() => {
    const group = groupedTemplates.find((arr) => arr[0] === 'Feature');
    if (group) {
      return group[1] && group[1].length > 0;
    }

    return false;
  }, [groupedTemplates]);

  const options = useMemo(() => {
    const arr = [{ key: ADD_KEY_CONTENT }, { key: ADD_KEY_SECTIONS }];
    if (showFeatures) {
      arr.push({ key: ADD_KEY_FEATURES });
    }
    return arr;
  }, [showFeatures]);

  const getActivePanel = (key: string) => {
    switch (key) {
      case ADD_KEY_CONTENT:
        return <AddContent />;
      case ADD_KEY_SECTIONS:
        return <AddSection groupedTemplates={groupedTemplates} />;
      case ADD_KEY_FEATURES:
        return <AddSection groupedTemplates={groupedTemplates} isFeatureTab />;
      default:
        return <AddContent />;
    }
  };

  return (
    <DrawerContainer>
      <CloseButton onClick={() => setDrawerContext(undefined)} />
      <DrawerHeading heading="Add">
        <AddHeader>
          {!isAddPanelEnabled && <StyledAlert type="warning" message={disabledMessage} />}
          <PillSegmentedController activeKey={activeKey} onChange={(key) => setActiveKey(key)} options={options} />
        </AddHeader>
      </DrawerHeading>
      {getActivePanel(activeKey)}
    </DrawerContainer>
  );
};
