import { ReactNode } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Heading, HeadingProps, LoadingSpinner, PageLoadError } from 'components';
import {
  BREAKPOINT_1,
  BREAKPOINT_2,
  BREAKPOINT_3,
  BREAKPOINT_4,
  DEFAULT_PAGE_PADDING,
  DEFAULT_PAGE_PADDING_SMALL,
  NAV_BAR_OFFSET,
  NEUTRAL_3_COLOUR,
  PAGE_CONTAINER_NARROW_WIDTH,
} from 'theme';

import { NavHeader } from 'app/modules/nav-header';
import { Helmet } from 'react-helmet-async';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const ContentWrapper = styled.div<{ $maxWidth?: ContentMaxWidth }>`
  width: 100%;
  height: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth?.small || '100%'};
  @media (min-width: ${BREAKPOINT_1.minWidth}) {
    max-width: ${({ $maxWidth }) => $maxWidth?.large || '100%'};
  }
`;

const ScrollContainer = styled.div<{ hideNavHeader?: boolean }>`
  width: 100%;
  height: ${({ hideNavHeader }) => (hideNavHeader ? '100%' : `calc(100% - ${NAV_BAR_OFFSET})`)};
  overflow-y: auto;
`;

interface ContentProps {
  $maxWidth?: ContentMaxWidth;
  $leftAligned?: boolean;
  $padding?: string;
}

const Content = styled.div<ContentProps>`
  width: 100%;
  overflow: hidden;
  ${({ $leftAligned }) => !$leftAligned && 'margin: auto'};
  display: flex;
  flex-direction: column;
  position: relative;

  transition: padding 0.2s linear;

  max-width: ${({ $maxWidth }) => $maxWidth?.small || '674px'};
  padding: ${({ $padding }) => $padding || DEFAULT_PAGE_PADDING_SMALL};

  @media (min-width: ${BREAKPOINT_1.minWidth}) {
    max-width: ${({ $maxWidth }) => $maxWidth?.large || PAGE_CONTAINER_NARROW_WIDTH};
    padding: ${({ $padding }) => $padding || DEFAULT_PAGE_PADDING};
  }
`;

const MockupContainer = styled.div<{ $contentHasMaxWidth?: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${NEUTRAL_3_COLOUR};

  transition: 0.2s linear width;
  ${({ $contentHasMaxWidth }) => !$contentHasMaxWidth && 'width: 366px'};
  @media (min-width: ${BREAKPOINT_1.minWidth}) and (min-height: ${BREAKPOINT_1.minHeight}) {
    ${({ $contentHasMaxWidth }) => !$contentHasMaxWidth && 'width: 440px'};
  }
  @media (min-width: ${BREAKPOINT_2.minWidth}) and (min-height: ${BREAKPOINT_2.minHeight}) {
    ${({ $contentHasMaxWidth }) => !$contentHasMaxWidth && 'width: 511px'};
  }
  @media (min-width: ${BREAKPOINT_3.minWidth}) and (min-height: ${BREAKPOINT_3.minHeight}) {
    ${({ $contentHasMaxWidth }) => !$contentHasMaxWidth && 'width: 582px'};
  }
  @media (min-width: ${BREAKPOINT_4.minWidth}) and (min-height: ${BREAKPOINT_4.minHeight}) {
    ${({ $contentHasMaxWidth }) => !$contentHasMaxWidth && 'width: 653px'};
  }
`;

export const MockupScalingWrapper = styled.div`
  transition: 0.2s linear transform;

  transform: scale(0.666);
  @media (min-width: ${BREAKPOINT_1.minWidth}) and (min-height: ${BREAKPOINT_1.minHeight}) {
    transform: scale(0.765);
  }
  @media (min-width: ${BREAKPOINT_2.minWidth}) and (min-height: ${BREAKPOINT_2.minHeight}) {
    transform: scale(0.87);
  }
  @media (min-width: ${BREAKPOINT_3.minWidth}) and (min-height: ${BREAKPOINT_3.minHeight}) {
    transform: scale(0.93);
  }
  @media (min-width: ${BREAKPOINT_4.minWidth}) and (min-height: ${BREAKPOINT_4.minHeight}) {
    transform: scale(1);
  }
`;

const NoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ContentMaxWidth {
  small: string;
  large: string;
}

export interface PageContainerProps {
  isLoading?: boolean;
  isError?: boolean;
  leftAligned?: boolean;
  contentMaxWidth?: ContentMaxWidth;
  children?: ReactNode;
  mockup?: ReactNode;
  $headingPadding?: string;
  $contentPadding?: string;
}

export const PageContainer = ({
  isLoading = false,
  isError,
  leftAligned,
  contentMaxWidth,
  children,
  mockup,
  $contentPadding,
  $headingPadding,
  ...props
}: PageContainerProps & HeadingProps) => {
  const location = useLocation();

  const hideNavHeader = location.pathname.startsWith('/builder');

  return (
    <>
      <Helmet>
        <title>{props.heading ? `VidApp - ${props.heading}` : 'VidApp Builder'}</title>
      </Helmet>
      <Container>
        <ContentWrapper
          $maxWidth={
            !mockup
              ? undefined
              : contentMaxWidth || { small: PAGE_CONTAINER_NARROW_WIDTH, large: PAGE_CONTAINER_NARROW_WIDTH }
          }
        >
          {!hideNavHeader && <NavHeader hideAccountDropdown={!!mockup} />}
          <ScrollContainer id="ScrollContainer--PageContainer" hideNavHeader={hideNavHeader}>
            {isError ? (
              <NoContent>
                <PageLoadError />
              </NoContent>
            ) : isLoading ? (
              <NoContent>
                <LoadingSpinner />
              </NoContent>
            ) : (
              <Content
                $leftAligned={leftAligned}
                $maxWidth={mockup ? { small: '100%', large: '100%' } : contentMaxWidth}
                $padding={$contentPadding}
              >
                {props.heading && <Heading $padding={$headingPadding} {...props} />}
                {children}
              </Content>
            )}
          </ScrollContainer>
        </ContentWrapper>
        {mockup && (
          <MockupContainer $contentHasMaxWidth={!!contentMaxWidth}>
            <MockupScalingWrapper>{mockup}</MockupScalingWrapper>
          </MockupContainer>
        )}
      </Container>
    </>
  );
};
