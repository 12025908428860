import { createContext, useCallback, useContext, useState } from 'react';
import { Modal } from 'antd';

import { InfoModal } from 'components';
import { Stage } from 'api';
import { usePublishApp } from 'hooks';

type PublishAvailable = 'preview' | 'live';

interface ContextProps {
  publishAvailable: PublishAvailable;
  isPublishing: boolean;
  setPublishAvailable: React.Dispatch<React.SetStateAction<PublishAvailable>>;
  handlePublishApp: (stage: Stage, unsavedChanges?: boolean) => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

const defaultFunction = () => {
  console.warn('Unexpected function call publish-provider');
};

const PublishContext = createContext<ContextProps>({
  publishAvailable: 'preview',
  isPublishing: false,
  setPublishAvailable: defaultFunction,
  handlePublishApp: defaultFunction,
});

const PublishProvider = ({ children }: ProviderProps) => {
  const [publishAvailable, setPublishAvailable] = useState<PublishAvailable>('preview');
  const publishApp = usePublishApp();

  const publishToPreview = useCallback(() => {
    publishApp.mutate('Preview', {
      onSuccess: async () => {
        setPublishAvailable('live');
        InfoModal({
          title: "We're updating the Vidapp preview app",
          content: 'Your updates will be live in a few minutes.',
        });
      },
      onError: (err) => {
        setPublishAvailable('live');
        InfoModal({
          title: 'It looks like your publishing task is taking a while!',
          content: "Apologies for the delay. You'll receive an email as soon as it's complete",
          type: 'error',
        });
        console.error(err);
      },
    });
  }, [publishApp, setPublishAvailable]);

  const handlePublishApp = useCallback(
    (stage: Stage, unsavedChanges?: boolean) => {
      if (stage === 'Live') {
        Modal.confirm({
          title: 'Are you sure?',
          content: 'Are you sure you want to publish your app live?',
          getContainer: '#react-app',
          okText: 'Yes, publish my app live',
          cancelText: 'Cancel',
          onOk: () => {
            publishApp.mutate(stage, {
              onSuccess: async () => {
                setPublishAvailable('preview');
                InfoModal({
                  title: 'Your updates will be live in a few minutes',
                  content: 'Users will receive your updates the next time they launch your app',
                });
              },
              onError: (err) => {
                setPublishAvailable('preview');
                InfoModal({
                  title: 'It looks like your publishing task is taking a while!',
                  content: "Apologies for the delay. You'll receive an email as soon as it's complete",
                  type: 'error',
                });
                console.error(err);
              },
            });
          },
        });
      } else if (unsavedChanges) {
        Modal.confirm({
          title: 'You have unsaved changes',
          content: 'Proceed with publishing your app without the unsaved changes?',
          getContainer: '#react-app',
          okText: 'Publish',
          cancelText: 'Continue editing',
          onOk: () => publishToPreview(),
        });
      } else {
        publishToPreview();
      }
    },
    [publishApp, setPublishAvailable, publishToPreview],
  );

  const isPublishing = publishApp.isLoading;

  return (
    <PublishContext.Provider value={{ publishAvailable, isPublishing, setPublishAvailable, handlePublishApp }}>
      {children}
    </PublishContext.Provider>
  );
};

const usePublishContext = () => {
  const context = useContext(PublishContext);
  if (context === undefined) {
    throw new Error('usePublishContext must be used within an PublishProvider');
  }
  return context;
};

export { PublishProvider, usePublishContext };
