import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { PRIMARY_ORANGE_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { GradientButton, LoadingStyles } from '../../GradientButton/GradientButton';
import { ColumnModal } from 'components';
import { FONT_16PX_MEDIUM, FONT_24PX_MEDIUM } from 'font';

const Content = styled.div`
  text-align: center;
  padding: 20px 10px 0;
`;
const Header = styled.div`
  ${FONT_24PX_MEDIUM};
`;
const BodyText = styled.div`
  ${FONT_16PX_MEDIUM};
  padding-top: 20px;
  color: ${NEUTRAL_7_COLOUR};
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
`;
const ResetButton = styled(GradientButton)`
  ${LoadingStyles}
  .ant-btn-loading-icon {
    margin-left: 8px !important;
  }
`;
const CancelButton = styled.div`
  margin: 15px auto 0;
  color: ${PRIMARY_ORANGE_COLOUR};
  font-size: 16px;
  cursor: pointer;
`;

export interface ModalProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const LoginModal = ({ visible, onCancel, onSubmit }: ModalProps) => {
  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]); // Disable background scroll when modal is open

  const [loading, setLoading] = useState(false);
  const [hasReset, setHasReset] = useState(false);
  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
    if (!hasReset) {
      console.info('Password has been reset');
      setHasReset(true);
    } else {
      console.info('Password reset has been sent again');
      setHasReset(false); // Resets for next use of modal
      onCancel(); // Closes modal
    }
  }, [onCancel, onSubmit, hasReset]);

  return (
    <ColumnModal
      open={visible}
      hideTrigger
      primaryText={hasReset ? 'Resend Email' : 'Confirm'}
      cols={2}
      portalId="login-panel"
      hideDialogActionsBorder
      customActions={
        <ButtonWrapper>
          <ResetButton $height="43px" $fontSize="16px" onClick={handleSubmit} loading={loading}>
            {hasReset ? 'Resend Email' : 'Confirm'}
          </ResetButton>
          <CancelButton onClick={onCancel}> {hasReset ? 'Close' : 'Cancel'}</CancelButton>
        </ButtonWrapper>
      }
    >
      <Content>
        {hasReset ? (
          <>
            <Header>{'New Password Sent'}</Header>
            <BodyText>
              {
                "We have sent you an email with your new password. If you haven't received an email, check your Spam folder or tap 'Resend Email'."
              }
            </BodyText>
          </>
        ) : (
          <>
            <Header>{'Reset Password'}</Header>
            <BodyText>
              {
                "Don't worry, happens to the best of us! To reset your password tap 'Confirm' below and we will email you a new password."
              }
            </BodyText>
          </>
        )}
      </Content>
    </ColumnModal>
  );
};
