import { Route, Switch } from 'react-router-dom';

import { ErrorPage } from 'components';
import { DataSourceProvider, DefaultAppPropertiesProvider, SaveProvider } from 'providers';
import { isUserAdmin } from 'utils';

import { ActionLog } from 'app/modules/action-log';
import { AddOns } from 'app/modules/add-ons';
import { AdminSettings } from 'app/modules/admin-settings';
import { FeatureFlagged } from 'app/modules/feature-flagged';
import { TagsCsvUploadPage } from 'app/modules/filters';
import { Integrations } from 'app/modules/integrations';
import { MFFModules } from 'app/modules/mff-modules';
import { PublishDiff } from 'app/modules/publish-diffs';
import { TestingScreen } from 'app/modules/testing-screen';
import { AppPurchases } from 'app/modules/app-purchases';
import { DebugScreen } from 'app/modules/debug-screen';
import { UserLookup } from '../../admin-user/UserLookup';

import { ModularFeaturesProvider } from 'mff';

export const AdminScreens = () => {
  if (!isUserAdmin()) {
    return <ErrorPage code={403} />;
  }

  return (
    <Switch>
      <Route exact path="/admin/csv-upload">
        <FeatureFlagged>
          <TagsCsvUploadPage />
        </FeatureFlagged>
      </Route>
      <Route exact path="/admin/action-log">
        <ActionLog />
      </Route>
      <Route path="/admin/add-ons">
        <DataSourceProvider>
          <AddOns />
        </DataSourceProvider>
      </Route>
      <Route path="/admin/modules">
        <SaveProvider>
          <DefaultAppPropertiesProvider>
            <ModularFeaturesProvider>
              <MFFModules />
            </ModularFeaturesProvider>
          </DefaultAppPropertiesProvider>
        </SaveProvider>
      </Route>
      <Route exact path="/admin/admin-settings">
        <DataSourceProvider>
          <AdminSettings />
        </DataSourceProvider>
      </Route>
      <Route exact path="/admin/integrations">
        <DataSourceProvider>
          <Integrations />
        </DataSourceProvider>
      </Route>
      <Route exact path="/admin/testing">
        <TestingScreen />
      </Route>
      <Route exact path="/admin/publish-diff">
        <PublishDiff />
      </Route>
      <Route exact path="/admin/app-purchases-admin">
        <AppPurchases />
      </Route>
      <Route exact path="/admin/debug">
        <DebugScreen />
      </Route>
      <Route exact path="/admin/users">
        <UserLookup />
      </Route>
      <Route path="*">
        <ErrorPage code={404} />
      </Route>
    </Switch>
  );
};
