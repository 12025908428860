import { CSSProperties, useMemo } from 'react';
import styled from 'styled-components';

import { IN_PROGRESS_TEMPLATE, Template } from 'api';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { FONT_12PX_MEDIUM, FONT_12PX_REGULAR } from 'font';
import { InfoCircleIcon } from 'icons';
import { Tooltip } from 'components';

import { TemplateThumbnail } from 'app/modules/build-dragdrop/Builder/components/TemplateThumbnail';
import {
  FEATURE_TEMPLATE_ALLCOURSES,
  FEATURE_TEMPLATE_MOST_WATCHED,
  FEATURE_TEMPLATE_MYCOURSES,
  FEATURE_TEMPLATE_TVOD,
} from 'app/modules/build-dragdrop/Builder/const';
import { OnClick, usePointerOnClick } from 'utils';

import { getFeatureSectionFromTemplateId } from 'mff';

const DisplayBackground = styled.div<{ $height: string } & OnClick>`
  background-color: #fff;
  overflow-x: clip;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  height: ${({ $height }) => $height};
  width: calc(50% - 8px);
  padding: 8px 7px;
  margin-bottom: 16px;
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 8px;

  color: ${NEUTRAL_9_COLOUR};

  :hover {
    border: 1px solid ${HIGHLIGHT_PRIMARY_COLOUR} !important;
  }

  cursor: ${usePointerOnClick};
`;
const TemplateTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 28px;
  margin-top: 4px;
  white-space: nowrap;
`;
const Name = styled.div`
  ${FONT_12PX_MEDIUM};
`;
const Variant = styled.div`
  ${FONT_12PX_REGULAR};
`;

const ThumbnailWrapper = styled.div<{ $height: string }>`
  display: flex;
  align-items: center;
  height: ${({ $height }) => $height};
`;
const StyledInfoIcon = styled(InfoCircleIcon)`
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 16px;
  color: ${NEUTRAL_9_COLOUR};
`;

interface DisplayPreviewProps {
  name?: string; // Override the display name from the template itself
  template: Template;
  style?: CSSProperties;
  isPage?: boolean;
  forwardRef?: (element?: HTMLElement | null) => void;
  onClick?: () => void;
}

export const DisplayPreview = ({ template, name, isPage, forwardRef, ...props }: DisplayPreviewProps) => {
  const displayName = name ?? template.DisplayName;
  const splitDisplayName = displayName.split('('); // If the display name contains parentheses, split it so it will be displayed over 2 lines
  if (splitDisplayName[1]) {
    splitDisplayName[1] = splitDisplayName[1].replace(')', ''); // Remove the trailing parenthesis
  }

  const tooltip = useMemo(() => {
    if (template.TemplateId === IN_PROGRESS_TEMPLATE) {
      return 'The ‘In Progress’ section will display Courses a user has in progress.';
    }
    if (template.TemplateId === FEATURE_TEMPLATE_MYCOURSES) {
      return 'The ‘My Courses’ section will display all courses owned by the user. This is suitable if you sell buy-to-own courses.';
    }
    if (template.TemplateId === FEATURE_TEMPLATE_ALLCOURSES) {
      return 'The ‘All Courses’ section will display all of your published Kajabi courses.';
    }
    if (template.TemplateId === FEATURE_TEMPLATE_TVOD) {
      return 'The ‘TVOD’ section will display all TVOD content owned by the user. This is suitable if you sell buy-to-own content.';
    }
    if (template.TemplateId === FEATURE_TEMPLATE_MOST_WATCHED) {
      return "The ‘Most Watched’ section will display the app's most watched videos from the past 7 days.";
    }
    // MFF Sections
    if (getFeatureSectionFromTemplateId(template.TemplateId)) {
      return getFeatureSectionFromTemplateId(template.TemplateId)?.tooltip;
    }
    return '';
  }, [template]);

  return (
    <DisplayBackground {...props} ref={forwardRef} $height={isPage ? '167px' : '108px'}>
      {tooltip && (
        <Tooltip title={tooltip} icon={<StyledInfoIcon />} placement="right" overlayStyle={{ maxWidth: '280px' }} />
      )}
      <ThumbnailWrapper $height={isPage ? '111px' : '60px'}>
        <TemplateThumbnail templateId={template.TemplateId} isPage={isPage} />
      </ThumbnailWrapper>
      <TemplateTitle>
        {splitDisplayName[0] && <Name>{splitDisplayName[0]}</Name>}
        {splitDisplayName[1] && <Variant>{splitDisplayName[1]}</Variant>}
      </TemplateTitle>
    </DisplayBackground>
  );
};
