import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';

import { CustomButton, CustomTable, CustomCell, PageContainer, TableColumn, Tabs } from 'components';
import { useCollections } from 'hooks';
import { AmityUser } from 'api';
import { formatDateToDDMMYYYYHHMM, getCommunityIdFromCollections } from 'utils';
import { FONT_12PX_MEDIUM } from 'font';
import { BanMemberIcon, EllipsisIcon, UnbanMemberIcon } from 'icons';

import { EllipsisText, SearchBox, StyledSearchIcon } from 'app/modules/community/Shared';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { useCommunityMembers } from './hooks/useCommunityMembers';
import { useBanCommunityMember } from './hooks/useBanCommunityMember';
import { useUnbanCommunityMember } from './hooks/useUnbanCommunityMember';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

const SmallCell = styled(CustomCell)`
  ${FONT_12PX_MEDIUM};
`;
const DotDotCell = styled(CustomCell)`
  padding: 8px 0;
`;

const Tab = styled(Tabs.Trigger)`
  width: fit-content;
`;

const MenuCell = ({ communityId, userId, unban }: AmityUser & { communityId: string; unban?: boolean }) => {
  const banCommunityMember = useBanCommunityMember();
  const unbanCommunityMember = useUnbanCommunityMember();
  const isLoading = banCommunityMember.isLoading || unbanCommunityMember.isLoading;
  return (
    <DotDotCell>
      <Dropdown
        disabled={isLoading}
        trigger={
          <CustomButton small tertiary loading={isLoading}>
            <EllipsisIcon />
          </CustomButton>
        }
        options={[
          {
            label: unban ? 'Unban Member' : 'Ban Member',
            icon: unban ? <UnbanMemberIcon /> : <BanMemberIcon />,
            onClick: () => {
              if (unban) {
                unbanCommunityMember.mutate({ communityId, userId });
              } else {
                banCommunityMember.mutate({ communityId, userId });
              }
            },
            danger: !unban,
          },
        ]}
      />
    </DotDotCell>
  );
};

const getColumns: (communityId: string, unban?: boolean) => TableColumn<AmityUser & { id: string }>[] = (
  communityId,
  unban,
) => [
  {
    heading: 'Member',
    width: 'grow',
    render: (data) => (
      <CustomCell>
        <EllipsisText>{data.displayName}</EllipsisText>
      </CustomCell>
    ),
  },
  {
    heading: 'User ID',
    width: 160,
    render: (data) => (
      <CustomCell>
        <EllipsisText>{data.userId}</EllipsisText>
      </CustomCell>
    ),
  },
  {
    heading: 'Join Date',
    width: 150,
    render: (data) => <SmallCell>{formatDateToDDMMYYYYHHMM(new Date(data.createdAt))}</SmallCell>,
  },
  {
    id: 'Action' + (unban ? '-unban' : 'ban'),
    heading: '',
    width: 24,
    render: (data) => <MenuCell {...data} communityId={communityId} unban={unban} />,
  },
];

export const CommunityMembersTable = () => {
  const [query, setQuery] = useState<string>('');
  const debouncedSetQuery = useCallback(
    debounce((newQuery) => {
      setQuery(newQuery);
    }, 500),
    [setQuery],
  );
  // Need a separate search query for the second table
  const [queryBanned, setQueryBanned] = useState<string>('');
  const debouncedSetQueryBanned = useCallback(
    debounce((newQuery) => {
      setQueryBanned(newQuery);
    }, 500),
    [setQueryBanned],
  );

  const { data } = useCollections();
  const communityId = useMemo(() => {
    if (!data) {
      return '';
    }
    return getCommunityIdFromCollections(data.collections);
  }, [data]);
  const { users, communities, communityUsers, isLoading, isError } = useCommunityMembers(communityId ?? '', { query });
  const {
    users: bannedUsers,
    communityUsers: bannedCommunityUsers,
    isLoading: isLoading2,
    isError: isError2,
  } = useCommunityMembers(communityId ?? '', {
    banned: true,
    query: queryBanned,
  });

  const filteredMembers = useMemo(() => {
    return Object.values(communityUsers)
      .map((u) => ({
        ...u,
        ...users[u.userId],
        id: u.userId,
      }))
      .filter((u) => !u.isDeleted)
      .filter((u) => !u.userId.startsWith('_admin'))
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [communityUsers, users, query]);

  const filteredBanned = useMemo(() => {
    return Object.values(bannedCommunityUsers)
      .map((u) => ({
        ...u,
        ...bannedUsers[u.userId],
        id: u.userId,
      }))
      .filter((u) => !u.isDeleted)
      .filter((u) => !u.userId.startsWith('_admin'))
      .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [bannedCommunityUsers, bannedUsers, query]);

  if (!communityId) {
    // There is no CommunityID on any of the tabs
    // Need some sort of default state
    return (
      <PageContainer
        heading="Members"
        subheading="Community is not setup, Contact VidApp for support with this feature"
        isLoading={isLoading || !data}
      ></PageContainer>
    );
  }

  return (
    <PageContainer heading="Members" contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH} isError={isError || isError2}>
      <Tabs.Root defaultValue="1">
        <Tabs.List $hideUnderline>
          <Tab value="1">All Members ({communities[communityId]?.membersCount ?? '...'})</Tab>
          <Tab value="2">Banned ({Object.values(filteredBanned ?? []).length})</Tab>
        </Tabs.List>
        <Tabs.Content value="1">
          <SearchBox
            width="50%"
            prefix={<StyledSearchIcon />}
            placeholder="Search"
            defaultValue={query}
            onChange={(e) => debouncedSetQuery(e.target.value)}
            size="middle"
          />
          <>
            <CustomTable
              isLoading={isLoading}
              columns={getColumns(communityId)}
              data={filteredMembers}
              query={query}
              emptyTitle="No community members"
              emptyDescription="No one has joined the community yet."
            />
          </>
        </Tabs.Content>
        <Tabs.Content value="2">
          <SearchBox
            width="50%"
            prefix={<StyledSearchIcon />}
            placeholder="Search"
            defaultValue={queryBanned}
            onChange={(e) => debouncedSetQueryBanned(e.target.value)}
            size="middle"
          />
          <CustomTable
            isLoading={isLoading2}
            columns={getColumns(communityId, true)}
            data={filteredBanned}
            query={query}
            emptyTitle="No banned members"
            emptyDescription="You haven't banned any members yet."
          />
        </Tabs.Content>
      </Tabs.Root>
    </PageContainer>
  );
};
