import styled, { css } from 'styled-components';
import { Input as AntdInput, InputNumber as AntdInputNumber, InputProps, InputNumberProps } from 'antd';
import { SettingsInputWrapper, SettingsInputWrapperProps } from 'components';
import {
  NEUTRAL_10_COLOUR,
  NEUTRAL_9_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_5_COLOUR,
  INPUT_STYLES_LARGE,
  INPUT_BORDER,
  INPUT_BORDER_RADIUS,
  INPUT_HEIGHT_LARGE,
  INPUT_STYLES,
  InputSize,
  INPUT_BORDER_FOCUS,
  NEUTRAL_2_COLOUR,
  SHARED_INPUT_STYLES,
} from 'theme';
import { ReactNode } from 'react';
import { TextAreaProps } from 'antd/lib/input';
import { FONT_14PX_REGULAR, FONT_16PX_REGULAR } from 'font';

const UNUSED_MAX_LENGTH = 999;
const Input = styled(AntdInput)<{ size: InputSize; $marginTop?: string }>`
  // antd 4.20.0 made it so that this styling is applied to the wrapper and the input
  // we just want the INPUT_STYLES on the wrapper
  &&&.ant-input-affix-wrapper {
    ${({ size }) => INPUT_STYLES[size]};
    width: 100%;
    margin-top: ${({ $marginTop }) => $marginTop || 0};
  }

  &&&.ant-input-affix-wrapper-focused {
    box-shadow: none;
    border: ${INPUT_BORDER_FOCUS};

    :hover {
      border: ${INPUT_BORDER_FOCUS} !important;
    }
  }

  #react-app &&,
  #react-app && .ant-input {
    color: ${NEUTRAL_10_COLOUR};
  }

  #react-app && .ant-input-show-count-suffix {
    display: ${({ maxLength }) => (maxLength === UNUSED_MAX_LENGTH ? 'none' : 'block')};
    color: ${NEUTRAL_7_COLOUR};
    font-size: 12px;
    word-spacing: -3px;
  }

  &&&.ant-input-affix-wrapper-disabled {
    :hover {
      border-color: ${NEUTRAL_5_COLOUR} !important;
    }

    input {
      color: ${NEUTRAL_7_COLOUR} !important;
    }
  }
`;

const InputNumber = styled(AntdInputNumber)`
  &&&& .ant-input-number-handler-wrap {
    display: none;
  }

  &&&.ant-input-number-affix-wrapper {
    ${INPUT_STYLES_LARGE};
    width: 100%;

    .ant-input-number {
      margin-right: 10px;
      color: ${NEUTRAL_10_COLOUR};

      input {
        background: none;
      }
    }
  }

  &&&.ant-input-number-affix-wrapper-focused {
    box-shadow: none;
    border: ${INPUT_BORDER_FOCUS};

    :hover {
      border: ${INPUT_BORDER_FOCUS} !important;
    }
  }

  &&&.ant-input-affix-wrapper-disabled {
    :hover {
      border-color: ${NEUTRAL_5_COLOUR} !important;
    }

    input {
      color: ${NEUTRAL_7_COLOUR} !important;
    }
  }

  #react-app && .ant-input-number {
    display: flex;
    align-items: center;

    :hover {
      background-color: ${NEUTRAL_2_COLOUR};
    }
  }
`;

const textAreaStyles = css`
  width: 100%;
  ${SHARED_INPUT_STYLES};
  ${FONT_16PX_REGULAR};
  transition: none;

  :focus {
    box-shadow: none;
    border: ${INPUT_BORDER_FOCUS};

    :hover {
      border: ${INPUT_BORDER_FOCUS} !important;
    }
  }
`;

const textAreaStylesMiddle = css`
  width: 100%;
  ${SHARED_INPUT_STYLES};
  ${FONT_14PX_REGULAR};
  transition: none;

  :focus {
    box-shadow: none;
    border: ${INPUT_BORDER_FOCUS};

    :hover {
      border: ${INPUT_BORDER_FOCUS} !important;
    }
  }
`;

const getTextAreaStyles = (showCount: boolean, size?: InputSize) => {
  if (showCount) {
    // If count is shown, textarea is nested inside of a div
    return css`
      textarea {
        ${size === 'middle' ? textAreaStylesMiddle : textAreaStyles};
      }
    `;
  }

  return size === 'middle' ? textAreaStylesMiddle : textAreaStyles;
};

const TextArea = styled(AntdInput.TextArea)`
  #react-app && {
    ${({ showCount, size }) => getTextAreaStyles(!!showCount, size)};

    textarea {
      padding: 8px 11px;
    }

    &:after {
      color: ${NEUTRAL_7_COLOUR};
      font-size: 12px;
      word-spacing: -3px;
      transform: ${({ size }) => (size === 'middle' ? 'translate(-10px, -180%)' : 'translate(-10px, -190%)')};
    }
  }
`;

interface SettingsTextInputProps {
  label?: string;
  tooltip?: SettingsInputWrapperProps['tooltip'];
  maxLength?: number;
  width?: string;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  defaultValue?: string | number | undefined;
  size?: InputSize;
  $marginTop?: string;
  $marginBottom?: string;
  onBlur?: () => void;
}

export const SettingsTextInput = ({
  label,
  tooltip,
  maxLength,
  width,
  size = 'large',
  $marginBottom,
  ...props
}: SettingsTextInputProps & InputProps) => (
  <SettingsInputWrapper label={label} tooltip={tooltip} width={width} $marginBottom={$marginBottom}>
    <Input showCount maxLength={maxLength ?? UNUSED_MAX_LENGTH} size={size} {...props} />
  </SettingsInputWrapper>
);

interface SettingsNumberInputProps {
  label?: string;
  tooltip?: SettingsInputWrapperProps['tooltip'];
  width?: string;
  placeholder?: string;
  value?: string;
}

export const SettingsNumberInput = ({
  label,
  tooltip,
  width,
  ...props
}: SettingsNumberInputProps & InputNumberProps) => (
  <SettingsInputWrapper label={label} tooltip={tooltip} width={width}>
    <InputNumber {...props} />
  </SettingsInputWrapper>
);

export const SettingsTextArea = ({
  label,
  tooltip,
  maxLength,
  width,
  rows,
  ...props
}: SettingsTextInputProps & TextAreaProps) => (
  <SettingsInputWrapper label={label} tooltip={tooltip} width={width}>
    <TextArea showCount={!!maxLength} autoSize={!rows} rows={rows} maxLength={maxLength} {...props} />
  </SettingsInputWrapper>
);

// Mimics the appearance of an input field, but you cannot edit it
const DisplayField = styled.div<{ size?: InputSize; color?: string }>`
  width: 100%;
  height: ${(props) => (props.size === 'large' ? '64px' : INPUT_HEIGHT_LARGE)};
  line-height: ${(props) => (props.size === 'large' ? '64px' : INPUT_HEIGHT_LARGE)};

  border: ${INPUT_BORDER};
  border-radius: ${INPUT_BORDER_RADIUS};

  padding-left: 11px;
  padding-right: 11px;
  position: relative;

  font-size: ${({ size }) => (size === 'large' ? '16px' : '14px')};
  font-weight: 500;
  color: ${({ color }) => color ?? NEUTRAL_9_COLOUR};
`;

export const SettingTextDisplay = ({
  label,
  tooltip,
  width,
  value,
  size,
  children,
  color,
}: SettingsTextInputProps & { size?: InputSize; children?: ReactNode; color?: string }) => (
  <SettingsInputWrapper label={label} tooltip={tooltip} width={width}>
    <DisplayField size={size} color={color}>
      {value}
      {children}
    </DisplayField>
  </SettingsInputWrapper>
);
