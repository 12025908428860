import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useSaveContext } from 'providers';
import { NAV_BAR_OFFSET } from 'theme';
import { calculateCollectionItemPositions } from 'utils';
import { LoadingSpinner } from 'components';
import { usePrefetchTemplates } from 'hooks';

import { NavHeaderSimple } from 'app/modules/nav-header';
import { useMockupContext } from 'app/modules/build-dragdrop';
import { BUILDER_DRAWER_WIDTH } from 'app/modules/build-dragdrop/Builder/const';
import { TreeMenu } from './tree-menu';
import { AppMockup } from './mockup';
import { RightDrawer } from './drawer';
import { useBuilderContext, useContent } from './providers';
import { BMA_SCREEN, BMA_TV_SCREEN } from 'app/modules/build-screens/const';

const StyledDragDropContext = styled(DragDropContext)`
  height: 100%;
`;

const Content = styled.div`
  height: calc(100% - ${NAV_BAR_OFFSET});
  display: flex;
`;

const MiddleWrapper = styled.div`
  height: 100%;
  min-width: 600px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;

  position: relative;
  padding-right: ${`${BUILDER_DRAWER_WIDTH}px`};
  overflow: visible;
  overflow-y: clip;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// This is separated so it can be wrapped by ContentProvider
export const BuilderInternal = () => {
  // Artificial loading state
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const locationState = location.state ? (location.state as Record<string, string>) : undefined;

  const { collections, setCollections } = useContent();
  const { setDrawerContext } = useBuilderContext();
  const { currentTabId, currentPage, setCurrentPage } = useMockupContext();
  const { entitiesToSave, setCollectionItemsToSave, saveChanges, isSaving, discardChangesToSave } = useSaveContext();
  usePrefetchTemplates(); // Ensure templates are retrieved before AddDrawer is opened

  const currentTab = currentTabId ? collections[currentTabId] : undefined;

  // If the tab has been changed via ToolboxMainTabSelector, unsaved changes should be discarded on navigation
  useEffect(() => {
    if (locationState?.resetSaveProvider) {
      discardChangesToSave();
    }
  }, [location]);

  // Artificial loading state
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [setIsLoading]);

  const handleDragEnd = ({ destination, source, draggableId }: DropResult) => {
    if (destination?.droppableId === source.droppableId && destination?.index === source.index) {
      return; // Item has not moved
    }
    if (destination) {
      // DroppableId is the TabId of the tab being rearranged
      // use suffix separated by | to avoid overlapping droppable ids which may cause issues
      const parentTabId = destination.droppableId.split('|')[0];
      /*
       * DraggableId -> CollectionId|TabItemId
       */
      const tabItemId = draggableId.split('|')[1];
      const newCollections = { ...collections };
      const parsedCurrentTabId = parentTabId.startsWith('Temp') ? parentTabId : Number.parseInt(parentTabId);
      const newCollection = newCollections[parsedCurrentTabId];

      if (!newCollection) {
        console.warn(`Failed to drag and drop couldn't find the Tab with id: ${parentTabId}`);
        return;
      }

      /* Rearranging existing items in the mockup or the drawer*/
      const sourceItem = newCollection.Items.find((item) => item.TabItemId?.toString() === tabItemId);
      if (sourceItem) {
        newCollection.Items.splice(source.index, 1);
        newCollection.Items.splice(destination.index, 0, sourceItem);
        console.debug('BuilderInternal', 'Rearranging content', {
          TabId: newCollection.TabId,
          Items: newCollection.Items,
        });
      } else {
        console.warn(`Failed to drag and drop couldn't find TabItem with TabItemId: ${tabItemId}`);
        return;
      }
      // Update positions based on new items
      calculateCollectionItemPositions(newCollection.Items);
      newCollection.Items = [...newCollection.Items]; // Trigger dependent updates
      setCollections(newCollections);
      setCollectionItemsToSave(parentTabId);
    }
  };

  const handleSave = async () => {
    if (collections) {
      const { getPermanentCollectionId } = await saveChanges();

      // Main Tab update
      if (currentTabId?.toString()?.includes('Temp')) {
        const permanentId = getPermanentCollectionId(currentTabId as string);
        console.debug('Tab is a new collection', permanentId);
        // This may reset the state of the D&D builder but otherwise it will crash :)
        history.push(`/builder/${permanentId}`);
        return;
      }

      // Ensure that the drawer is focussed on a permanent instance of a collection
      setDrawerContext((prevContext) => {
        if (prevContext && prevContext.itemId) {
          const id = getPermanentCollectionId(prevContext.itemId)?.toString();
          if (id) {
            return { ...prevContext, itemId: id };
          }
        }
        return prevContext;
      });

      // SubTab update
      if (currentPage?.subItemId?.toString()?.includes('Temp') || currentPage?.itemId?.toString()?.includes('Temp')) {
        setCurrentPage(undefined);
      }
    }
  };

  if (!currentTab) {
    return null; // HOLD THE UI TIL THE TAB EXISTS, THIS CAN OCCUR DURING SAVE
  }

  return (
    <StyledDragDropContext onDragEnd={handleDragEnd}>
      <NavHeaderSimple
        handleSave={handleSave}
        handleBack={() =>
          locationState?.viaDashboard
            ? history.push(`/`, { showUnsavedChangesPrompt: true })
            : history.push(`/${locationState?.viaTV ? BMA_TV_SCREEN : BMA_SCREEN}/${currentTab.TabId}`, {
                showUnsavedChangesPrompt: true,
              })
        }
        isSaving={isSaving}
        saveDisabled={isSaving || entitiesToSave.length === 0}
      />
      <Content>
        {isLoading ? (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        ) : (
          <>
            <TreeMenu />
            <MiddleWrapper>
              <AppMockup />
            </MiddleWrapper>
            <RightDrawer />
          </>
        )}
      </Content>
    </StyledDragDropContext>
  );
};
