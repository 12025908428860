import { createContext, useCallback, useContext, useMemo } from 'react';

import { useLocalAppProperties } from 'providers';

import { MFFModule } from 'api';
import { ENABLED_MODULES_PROPERTY } from 'app/modules/mff-modules/const';

import { useMFFModules } from 'mff';

interface ContextProps {
  modules: MFFModule[] | undefined;
  isLoading: boolean;
  isError: boolean;
  modularFeatureIsEnabled: (id: string) => boolean;
}

interface ProviderProps {
  children: React.ReactNode;
}

const ModularFeaturesContext = createContext<ContextProps>({
  modules: undefined,
  isLoading: false,
  isError: false,
  modularFeatureIsEnabled: () => false,
});

const ModularFeaturesProvider = ({ children }: ProviderProps) => {
  const { data: modules, isLoading: modulesIsLoading, isError: modulesIsError } = useMFFModules();
  const { properties } = useLocalAppProperties();

  const enabledModules = useMemo(() => {
    if (properties[ENABLED_MODULES_PROPERTY]) {
      return JSON.parse(properties[ENABLED_MODULES_PROPERTY]);
    }

    return undefined;
  }, [properties]);

  const modularFeatureIsEnabled = useCallback(
    (id: string) => {
      if (enabledModules && enabledModules[id]) {
        return [1, 2].includes(enabledModules[id]); // 1 is live, 2 is preview, return true for either so feature is available in builder
      }

      return false;
    },
    [enabledModules],
  );

  return (
    <ModularFeaturesContext.Provider
      value={{
        modules,
        isLoading: modulesIsLoading,
        isError: modulesIsError,
        modularFeatureIsEnabled,
      }}
    >
      {children}
    </ModularFeaturesContext.Provider>
  );
};

const useModularFeatures = () => {
  const context = useContext(ModularFeaturesContext);
  if (context === undefined) {
    throw new Error('useModularFeatures must be used within a ModularFeaturesProvider');
  }
  return context;
};

export { ModularFeaturesProvider, useModularFeatures };
