import { Form } from 'antd';

import {
  PageContainer,
  SettingsGroup,
  SettingsFormItem,
  SettingsTextInput,
  SettingsTextArea,
  SettingsSelectInput,
  SelectOption,
  SettingsImageInput,
  CustomButton,
  SettingsSegmentDivider,
} from 'components';
import { AppBasicInfoKey } from 'api';
import { SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';

import { useAppStore, PropertyType } from '../../hooks/useAppStore';
import { ScreenshotInput, ScreensContainer, AddScreenshotButton } from '../../components';

// Map of all properties being edited on this screen
const PROPERTIES: Record<string, PropertyType> = {
  AppleTitle: { type: 'appBasicInfo' },
  AppleShortDescription: { type: 'appBasicInfo' },
  AppleDisplayName: { type: 'appBasicInfo' },
  ApplePromoText: { type: 'appBasicInfo' },
  AppleKeywords: { type: 'appBasicInfo' },
  AppleFullDescription: { type: 'appBasicInfo' },
  PrimaryCategory: { type: 'appBasicInfo' },
  SecondaryCategory: { type: 'appBasicInfo' },
  MarketingURL: { type: 'appBasicInfo' },
  SupportURL: { type: 'appBasicInfo' },
  RatingRealisticViolence: { type: 'appBasicInfo' },
  RatingMatureThemes: { type: 'appBasicInfo' },
  AppIconImage: { type: 'appBasicInfo', isFile: true },

  TargetsChildren: { type: 'appProperty' },
  AppSupportURL: { type: 'appProperty' },
  PrivacyPolicy: { type: 'appProperty' },
  TermsOfService: { type: 'appProperty' },
};

interface Field {
  name: string;
  label: string;
  type: 'text' | 'textArea' | 'select';
  propertyType: 'appBasicInfo' | 'appProperty';
  maxLength?: number;
  tooltip?: string;
  width?: string;
  options?: SelectOption[];
  forceHttps?: boolean; // On blur, prepend with https:// if not already there
}

const FIELDS: Field[] = [
  {
    name: 'AppleTitle',
    label: 'App Name',
    type: 'text',
    propertyType: 'appBasicInfo',
    maxLength: 30,
    tooltip:
      "Displayed in the Apple app store (note these words count as 'super keywords', so it's important they match the words your customers will be using to search for your app).",
  },
  {
    name: 'AppleShortDescription',
    label: 'Subtitle',
    type: 'text',
    propertyType: 'appBasicInfo',
    maxLength: 30,
    tooltip: 'A short description displayed in the Apple app store (new in iOS 11).',
  },
  {
    name: 'AppleDisplayName',
    label: 'iPhone/iPad Display Name',
    type: 'text',
    propertyType: 'appBasicInfo',
    maxLength: 16,
    tooltip: "Displayed on a user's home screen under the app icon.",
  },
  {
    name: 'ApplePromoText',
    label: 'Promotional Text',
    type: 'text',
    propertyType: 'appBasicInfo',
    maxLength: 170,
    tooltip:
      'Displayed at the top of your description in the Apple app store. Typically used to share the latest news about your app, such as limited-time sales, upcoming features or content, or other events within your app.',
    width: '100%',
  },
  {
    name: 'AppleKeywords',
    label: 'Keywords',
    type: 'text',
    propertyType: 'appBasicInfo',
    maxLength: 100,
    tooltip:
      'These keywords determine which search phrases your app will match. Important note! Please separate each word by a space OR comma, not both.',
    width: '100%',
  },
  {
    name: 'AppleFullDescription',
    label: 'App Store Description',
    type: 'textArea',
    propertyType: 'appBasicInfo',
    maxLength: 4000,
    tooltip:
      "A full-length description displayed in the Apple app store. Can't include emojis. Must include subscription pricing information. Must include Vidapp shoutout!",
    width: '100%',
  },
  {
    name: 'PrimaryCategory',
    label: 'Primary Category',
    type: 'select',
    propertyType: 'appBasicInfo',
    tooltip: 'The main category of Apple apps that your app most aligns with.',
  },
  {
    name: 'SecondaryCategory',
    label: 'Secondary Category',
    type: 'select',
    propertyType: 'appBasicInfo',
    tooltip: 'The second cateogry of Apple apps that your app most aligns with.',
  },
  {
    name: 'MarketingURL',
    label: 'Marketing URL',
    type: 'text',
    propertyType: 'appBasicInfo',
    tooltip:
      'A URL where customers can go to find out more about you and your app (this is typically your main website).',
    forceHttps: true,
  },
  {
    name: 'SupportURL',
    label: 'Support URL',
    type: 'text',
    propertyType: 'appBasicInfo',
    tooltip: 'A URL where customers can go to get support for the app (this is typically your main website).',
    forceHttps: true,
  },
  {
    name: 'RatingRealisticViolence',
    label: 'Rating Realistic Violence',
    type: 'select',
    propertyType: 'appBasicInfo',
    tooltip: "This is used to determine your app's age rating in the Apple app store.",
    options: [
      { name: 'None', value: 0 },
      { name: 'Infrequent/Mild', value: 1 },
      { name: 'Frequent/Intense', value: 2 },
    ],
  },
  {
    name: 'RatingMatureThemes',
    label: 'Rating Mature Themes',
    type: 'select',
    propertyType: 'appBasicInfo',
    tooltip: "This is used to determine your app's age rating in the Apple app store.",
    options: [
      { name: 'None', value: 0 },
      { name: 'Infrequent/Mild', value: 1 },
      { name: 'Frequent/Intense', value: 2 },
    ],
  },
  {
    name: 'TargetsChildren',
    label: 'Targets Children',
    type: 'select',
    propertyType: 'appProperty',
    tooltip: 'Specify whether your app is targeted towards children.',
    options: [
      { name: 'No', value: '0' },
      { name: 'Yes', value: '1' },
    ],
  },
  {
    name: 'AppSupportURL',
    label: 'App Support Link',
    type: 'text',
    propertyType: 'appProperty',
    tooltip: 'A URL where customers can go to get support for the app (this is typically your main website).',
    forceHttps: true,
  },
  {
    name: 'PrivacyPolicy',
    label: 'Privacy Policy',
    type: 'text',
    propertyType: 'appProperty',
    tooltip: 'A URL where customers can go to get support for the app (this is typically your main website).',
    forceHttps: true,
  },
  {
    name: 'TermsOfService',
    label: 'Terms & Conditions',
    type: 'text',
    propertyType: 'appProperty',
    tooltip: 'A URL where customers can go to get support for the app (this is typically your main website).',
    forceHttps: true,
  },
];

export const AppStoreIOSSettings = () => {
  const {
    appBasicInfo,
    appProperties,
    form,
    unsavedChanges,
    isSaving,
    isLoading,
    isError,
    appleCategories,
    filenames,
    screenshots,
    handleForceHttps,
    addScreenshot,
    removeScreenshot,
    onValuesChange,
    onFilenameChange,
    onScreenshotChange,
    saveForm,
  } = useAppStore(PROPERTIES, 'IOS');

  return (
    <PageContainer
      heading="Apple App Store Details"
      subheading={
        <>
          Update information presented in the Apple App Store. For more information on each element please visit our{' '}
          <a href="https://vidapp.com/ios-app-store-product-page-vidapp/" target="_blank" rel="noopener noreferrer">
            iOS App Store Product page here.
          </a>{' '}
          Please note any changes here will require a new version of your app to be submitted through the Apple review
          process.
        </>
      }
      headingButton={
        <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
          Save
        </CustomButton>
      }
      isLoading={isLoading}
      isError={isError}
    >
      <Form name="settings-form" onValuesChange={onValuesChange} onFinish={saveForm} autoComplete="off" form={form}>
        <SettingsGroup>
          <SettingsImageInput
            heading="App Icon"
            tooltip="This should be a square image, but the corners will be automatically rounded by Apple, so keep that in mind when designing your app icon."
            width="50px"
            height="50px"
            borderRadius="11px"
            requiredDimensions={{ requiredWidth: 1024, requiredHeight: 1024 }}
            requirementsLabel="JPG. 1024 x 1024"
            acceptedFileTypes="image/jpeg"
            hideDelete
            downloadable
            overlayButtonSize="small"
            filename={filenames.AppIconImage?.value}
            scaleSpinner={80}
            onFilenameChange={(_filename) => onFilenameChange('AppIconImage', _filename)}
          />
        </SettingsGroup>
        <SettingsGroup $marginBottom="0" wrapRow>
          {FIELDS.map(({ name, propertyType, width, label, maxLength, tooltip, forceHttps, type, options }) => {
            const defaultValue =
              (propertyType === 'appBasicInfo' && appBasicInfo
                ? appBasicInfo[name as AppBasicInfoKey]
                : propertyType === 'appProperty' && appProperties
                ? appProperties[name]
                : undefined) ?? undefined;

            return (
              <SettingsFormItem
                key={name}
                name={name}
                width={width || '49%'}
                $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}
              >
                {type === 'text' ? (
                  <SettingsTextInput
                    defaultValue={defaultValue}
                    label={label}
                    maxLength={maxLength}
                    tooltip={tooltip}
                    onBlur={forceHttps ? () => handleForceHttps(name) : undefined}
                  />
                ) : type === 'textArea' ? (
                  <SettingsTextArea
                    defaultValue={defaultValue}
                    label={label}
                    maxLength={maxLength}
                    tooltip={tooltip}
                    rows={6}
                  />
                ) : (
                  <SettingsSelectInput
                    defaultVal={defaultValue}
                    label={label}
                    tooltip={tooltip}
                    options={options ?? appleCategories}
                  />
                )}
              </SettingsFormItem>
            );
          })}
        </SettingsGroup>
        <SettingsSegmentDivider />
        <SettingsGroup title="iPhone 8 Screenshots">
          <ScreensContainer>
            {screenshots.iPhone8?.map((screenshot, idx) => (
              <ScreenshotInput
                key={screenshot.id}
                heading={`Screenshot ${idx + 1}`}
                filename={screenshot.filename}
                id={screenshot.id}
                width="206px"
                height="366px"
                requiredDimensions={{ requiredWidth: 1242, requiredHeight: 2208 }}
                device="iPhone8"
                handleRemove={() => removeScreenshot(screenshot.id, 'iPhone8')}
                handleFilenameChange={onScreenshotChange}
              />
            ))}
          </ScreensContainer>
          <AddScreenshotButton
            text="Add an iPhone screenshot"
            disabled={screenshots.iPhone8?.length > 9}
            device="iPhone8"
            handleClick={addScreenshot}
          />
        </SettingsGroup>
        <SettingsSegmentDivider />
        <SettingsGroup title="iPhone X Screenshots">
          <ScreensContainer>
            {screenshots.iPhoneX?.map((screenshot, idx) => (
              <ScreenshotInput
                key={screenshot.id}
                heading={`Screenshot ${idx + 1}`}
                filename={screenshot.filename}
                id={screenshot.id}
                width="206px"
                height="446px"
                requiredDimensions={{ requiredWidth: 1242, requiredHeight: 2688 }}
                device="iPhoneX"
                handleRemove={() => removeScreenshot(screenshot.id, 'iPhoneX')}
                handleFilenameChange={onScreenshotChange}
              />
            ))}
          </ScreensContainer>
          <AddScreenshotButton
            text="Add an iPhone X screenshot"
            disabled={screenshots.iPhoneX?.length > 9}
            device="iPhoneX"
            handleClick={addScreenshot}
          />
        </SettingsGroup>
        <SettingsSegmentDivider />
        <SettingsGroup title="iPad Screenshots">
          <ScreensContainer>
            {screenshots.iPad?.map((screenshot, idx) => (
              <ScreenshotInput
                key={screenshot.id}
                heading={`Screenshot ${idx + 1}`}
                filename={screenshot.filename}
                id={screenshot.id}
                width="206px"
                height="275px"
                requiredDimensions={{ requiredWidth: 2048, requiredHeight: 2732 }}
                device="iPad"
                handleRemove={() => removeScreenshot(screenshot.id, 'iPad')}
                handleFilenameChange={onScreenshotChange}
              />
            ))}
          </ScreensContainer>
          <AddScreenshotButton
            text="Add an iPad screenshot"
            disabled={screenshots.iPad?.length > 9}
            device="iPad"
            handleClick={addScreenshot}
          />
        </SettingsGroup>
      </Form>
    </PageContainer>
  );
};
