import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import {
  PAYMENT_STATUS_DETAILS_SAVED,
  SOURCE_KAJABI,
  SOURCE_MEMBERPRESS,
  SOURCE_VHX,
  SOURCE_WOOCOMMERCESUBSCRIPTIONS,
} from 'api';
import { useAppBasicInfo, useAppProperties, useBuilderEvents, useDeleteBuilderEvent, useSaveBuilderEvent } from 'hooks';
import { useDataSource } from 'providers';

import { isUserAdmin } from 'utils';
import {
  ONBOARDING_STAGES,
  ONBOARDING_STEPS,
  OnboardingStage,
  OnboardingStageKey,
  OnboardingStep,
  OnboardingStepKey,
  STAGE_KEY_GET_STARTED,
  STAGE_KEY_IAP,
  STAGE_KEY_PLATFORM,
  STAGE_KEY_REVIEW_AND_SUBMISSION,
  STEP_KEY_ANDROID_REVIEW_SUCCESSFUL,
  STEP_KEY_ANDROID_SUBMITTED,
  STEP_KEY_IAP_SETTINGS_CONFIGURED,
  STEP_KEY_IOS_REVIEW_SUCCESSFUL,
  STEP_KEY_IOS_SUBMITTED,
  STEP_KEY_KAJABI_IOS_USER,
  STEP_KEY_KAJABI_OFFERS_FOR_SALE,
  STEP_KEY_KAJABI_PRODUCTS,
  STEP_KEY_KAJABI_TEST_USER,
  STEP_KEY_OFFER_IDS_SET,
  STEP_KEY_OFFERS_CREATED,
  STEP_KEY_OFFERS_VALIDATED,
  STEP_KEY_ONE_PASSWORD,
  STEP_KEY_PAYMENT,
  STEP_KEY_VHX_ADMIN,
  STEP_KEY_VHX_IOS_USER,
  STEP_KEY_VHX_OFFERS_FOR_SALE,
  STEP_KEY_VHX_TEST_USER,
  STEP_KEY_WORDPRESS_ADMIN,
  STEP_KEY_WORDPRESS_COMPLETE_INTEGRATION,
  STEP_KEY_WORDPRESS_OFFERS_FOR_SALE,
  STEP_KEY_WORDPRESS_SUBSCRIPTIONS_CREATED,
} from '../const';

export const STATUS_PREPARING = 'Preparing To Submit';
export const STATUS_SUBMITTED = 'Submitted';
export const STATUS_IN_REVIEW = 'In Review';
export const STATUS_REVIEW_SUCCESSFUL = 'Review Successful';
export const STATUS_READY_TO_RELEASE = 'Ready To Release';

interface ProviderProps {
  children: ReactNode;
}

interface onboardingScreenConfig {
  onboardingScreenIsSet: boolean;
  onboardingBackgroundIsSet: boolean;
  splashScreenUrl: string | undefined;
}
interface ContextValue {
  activeStage: OnboardingStageKey;
  setActiveStage: Dispatch<SetStateAction<OnboardingStageKey>>;
  currentStageKey?: OnboardingStageKey;
  onboardingStages?: Record<OnboardingStageKey, OnboardingStageWithStatus>;
  onboardingSteps?: Record<OnboardingStepKey, OnboardingStepWithStatus>;
  submissionStatuses: { iOS: string; android: string };
  toggleOnboardingStepStatus: (key: OnboardingStepKey) => void;
  completeOnboardingStep: (key: OnboardingStepKey) => void;
  onboardingScreensConfig: onboardingScreenConfig;
  salesCallModalVisible: boolean;
  setSalesCallModalVisible: Dispatch<SetStateAction<boolean>>;
  isReadyToRelease: boolean;
  isLoading?: boolean;
}

export interface OnboardingStageWithStatus extends OnboardingStage {
  completedSteps: number;
  completedAdminSteps: number;
}

interface OnboardingStepWithStatus extends OnboardingStep {
  isCompleted?: boolean;
}

const OnboardingChecklistContext = createContext<ContextValue | undefined>(undefined);

const OnboardingChecklistProvider = ({ children }: ProviderProps) => {
  const dataSource = useDataSource();
  const { data: appBasicInfo, isLoading: appBasicInfoIsLoading } = useAppBasicInfo();
  const { data: appProperties, isLoading: appPropertiesIsLoading } = useAppProperties();
  const { data: builderEvents, isLoading: builderEventsIsLoading } = useBuilderEvents();
  const saveBuilderEvent = useSaveBuilderEvent();
  const deleteBuilderEvent = useDeleteBuilderEvent();

  const [activeStage, setActiveStage] = useState<OnboardingStageKey>(STAGE_KEY_GET_STARTED); // The stage that has been navigated to
  const [currentStageKey, setCurrentStageKey] = useState<OnboardingStageKey>(); // The first incomplete stage i.e. the stage the client should currently be working on
  const [onboardingSteps, setOnboardingSteps] =
    useState<Record<OnboardingStepKey, OnboardingStepWithStatus>>(ONBOARDING_STEPS);
  const [salesCallModalVisible, setSalesCallModalVisible] = useState<boolean>(false);
  const [submissionStatuses, setSubmissionStatuses] = useState({ iOS: STATUS_PREPARING, android: STATUS_PREPARING });

  // Filter out deleted events
  const filteredEvents = useMemo(() => {
    if (!builderEvents) {
      return undefined;
    }
    return builderEvents.filter((event) => !event.DeleteTs);
  }, [builderEvents]);

  useEffect(() => {
    if (filteredEvents) {
      const completedTypes = new Set(filteredEvents.map((event) => event.Type));
      const steps: Record<OnboardingStepKey, OnboardingStepWithStatus> = { ...ONBOARDING_STEPS };

      for (const key in steps) {
        const stepKey = key as OnboardingStepKey;
        if (stepKey === STEP_KEY_PAYMENT) {
          steps[stepKey].isCompleted = (appBasicInfo?.PaymentStatus || 0) >= PAYMENT_STATUS_DETAILS_SAVED;
        } else {
          steps[stepKey].isCompleted = completedTypes.has(stepKey);
        }
      }

      setOnboardingSteps(steps);

      const statuses = { iOS: STATUS_PREPARING, android: STATUS_PREPARING };

      if (completedTypes.has(STEP_KEY_IOS_REVIEW_SUCCESSFUL)) {
        statuses.iOS = STATUS_READY_TO_RELEASE;
      } else if (completedTypes.has(STEP_KEY_IOS_SUBMITTED)) {
        statuses.iOS = STATUS_IN_REVIEW;
      } else {
        statuses.iOS = STATUS_PREPARING;
      }
      if (completedTypes.has(STEP_KEY_ANDROID_REVIEW_SUCCESSFUL)) {
        statuses.android = STATUS_READY_TO_RELEASE;
      } else if (completedTypes.has(STEP_KEY_ANDROID_SUBMITTED)) {
        statuses.android = STATUS_IN_REVIEW;
      } else {
        statuses.android = STATUS_PREPARING;
      }

      setSubmissionStatuses(statuses);
    }
  }, [filteredEvents, appBasicInfo]);

  const isReadyToRelease =
    submissionStatuses.iOS === STATUS_READY_TO_RELEASE && submissionStatuses.android === STATUS_READY_TO_RELEASE;

  const onboardingStages = useMemo(() => {
    const stages: Record<OnboardingStageKey, Partial<OnboardingStageWithStatus>> = { ...ONBOARDING_STAGES };

    // Set DataSource specific steps
    if (stages[STAGE_KEY_PLATFORM].steps && stages[STAGE_KEY_PLATFORM].adminSteps) {
      if (dataSource === SOURCE_KAJABI) {
        stages[STAGE_KEY_PLATFORM].steps = [STEP_KEY_KAJABI_PRODUCTS];
        stages[STAGE_KEY_PLATFORM].adminSteps = [
          STEP_KEY_ONE_PASSWORD,
          STEP_KEY_KAJABI_IOS_USER,
          STEP_KEY_KAJABI_TEST_USER,
        ];

        stages[STAGE_KEY_IAP].steps = [STEP_KEY_OFFERS_CREATED, STEP_KEY_KAJABI_OFFERS_FOR_SALE];
        stages[STAGE_KEY_IAP].adminSteps = [
          STEP_KEY_OFFERS_VALIDATED,
          STEP_KEY_IAP_SETTINGS_CONFIGURED,
          STEP_KEY_OFFER_IDS_SET,
        ];
      } else if (dataSource === SOURCE_VHX) {
        stages[STAGE_KEY_PLATFORM].steps = [STEP_KEY_VHX_ADMIN];
        stages[STAGE_KEY_PLATFORM].adminSteps = [STEP_KEY_ONE_PASSWORD, STEP_KEY_VHX_IOS_USER, STEP_KEY_VHX_TEST_USER];

        stages[STAGE_KEY_IAP].steps = [STEP_KEY_VHX_OFFERS_FOR_SALE];
        stages[STAGE_KEY_IAP].adminSteps = [STEP_KEY_IAP_SETTINGS_CONFIGURED, STEP_KEY_OFFER_IDS_SET];
      } else if ([SOURCE_MEMBERPRESS, SOURCE_WOOCOMMERCESUBSCRIPTIONS].includes(dataSource)) {
        stages[STAGE_KEY_PLATFORM].steps = [STEP_KEY_WORDPRESS_ADMIN];
        stages[STAGE_KEY_PLATFORM].adminSteps = [STEP_KEY_ONE_PASSWORD, STEP_KEY_WORDPRESS_COMPLETE_INTEGRATION];

        stages[STAGE_KEY_IAP].steps = [STEP_KEY_WORDPRESS_SUBSCRIPTIONS_CREATED, STEP_KEY_WORDPRESS_OFFERS_FOR_SALE];
        stages[STAGE_KEY_IAP].adminSteps = [STEP_KEY_IAP_SETTINGS_CONFIGURED, STEP_KEY_OFFER_IDS_SET];
      }
    }

    let currentStage;

    for (const key in ONBOARDING_STAGES) {
      const stageKey = key as OnboardingStageKey;
      const stage = ONBOARDING_STAGES[stageKey];

      const completedSteps = [];
      const completedAdminSteps = [];

      stage.steps?.forEach((s) => {
        if (onboardingSteps[s].isCompleted) {
          completedSteps.push(s);
        }
      });
      stage.adminSteps?.forEach((s) => {
        if (onboardingSteps[s].isCompleted) {
          completedAdminSteps.push(s);
        }
      });

      // Find the first incomplete stage
      if (!currentStage) {
        if (stageKey === STAGE_KEY_REVIEW_AND_SUBMISSION) {
          if (!isReadyToRelease) {
            currentStage = stageKey;
          }
        } else {
          if (completedSteps.length < stage.steps.length && stage.position) {
            currentStage = stageKey;
          }
        }
      }

      stages[stageKey] = {
        ...ONBOARDING_STAGES[stageKey],
        completedSteps: completedSteps.length,
        completedAdminSteps: completedAdminSteps.length,
      };
    }

    if (!isUserAdmin() && currentStage) {
      setCurrentStageKey(currentStage);
      setActiveStage(currentStage);
    }

    return stages as Record<OnboardingStageKey, OnboardingStageWithStatus>;
  }, [onboardingSteps, dataSource, isReadyToRelease]);

  const toggleOnboardingStepStatus = (key: OnboardingStepKey) => {
    const existingEvent = filteredEvents?.find((e) => e.Type === key);

    if (existingEvent) {
      deleteBuilderEvent.mutate({ type: key, id: existingEvent.ID });
      setOnboardingSteps((prev) => ({ ...prev, [key]: { ...prev[key], isCompleted: false } }));
    } else {
      saveBuilderEvent.mutate(key);
      setOnboardingSteps((prev) => ({ ...prev, [key]: { ...prev[key], isCompleted: true } }));
    }
  };

  const completeOnboardingStep = (key: OnboardingStepKey) => {
    const existingEvent = filteredEvents?.find((e) => e.Type === key);

    // Only save event if it doesn't already exist
    if (!existingEvent) {
      saveBuilderEvent.mutate(key);
      setOnboardingSteps((prev) => ({ ...prev, [key]: { ...prev[key], isCompleted: true } }));
    }
  };

  const onboardingScreensConfig = useMemo(
    () => ({
      onboardingScreenIsSet: !!appProperties?.OnboardingScreens && appProperties?.OnboardingScreens.length > 0,
      onboardingBackgroundIsSet: !!appProperties?.OnboardingScreenBackground,
      splashScreenUrl: appBasicInfo?.SplashScreenImage,
    }),
    [appBasicInfo, appProperties],
  );

  return (
    <OnboardingChecklistContext.Provider
      value={{
        activeStage,
        setActiveStage,
        currentStageKey,
        onboardingStages,
        onboardingSteps,
        submissionStatuses,
        toggleOnboardingStepStatus,
        completeOnboardingStep,
        onboardingScreensConfig,
        salesCallModalVisible,
        setSalesCallModalVisible,
        isReadyToRelease,
        isLoading: appBasicInfoIsLoading || appPropertiesIsLoading || builderEventsIsLoading,
      }}
    >
      {children}
    </OnboardingChecklistContext.Provider>
  );
};

const useOnboardingChecklistContext = () => {
  const context = useContext(OnboardingChecklistContext);
  if (context === undefined) {
    throw new Error('useOnboardingChecklistContext must be used with an OnboardingChecklistProvider');
  }
  return context;
};

export { OnboardingChecklistProvider, useOnboardingChecklistContext };
