import styled from 'styled-components';
import { BUILDER_IMAGES_DOMAIN } from 'utils';
import { NEUTRAL_1_COLOUR } from 'theme';

import {
  FEATURE_TEMPLATE_ALLCOURSES,
  FEATURE_TEMPLATE_MOST_WATCHED,
  FEATURE_TEMPLATE_MYCOURSES,
  FEATURE_TEMPLATE_TVOD,
} from 'app/modules/build-dragdrop/Builder/const';

import { getFeatureSectionFromTemplateId } from 'mff';

interface TemplateThumbnailProps {
  templateId?: number;
  isPage?: boolean;
  height?: number;
  width?: number;
}

const TEMPLATE_THUMBNAILS: Record<number, string> = {
  1: 'list_large_overlay.svg',
  2: 'list_small.svg',
  3: 'slider_scroll.svg',
  7: 'list_large.svg',
  10: 'list_large_hero.svg',
  12: 'list_small_hero.svg',
  13: 'grid_square_no_title.svg',
  16: 'slider_scroll_hero.svg',
  26: 'grid_landscape_overlay.svg',
  27: 'slider_carousel_overlay.svg',
  28: 'grid_square.svg',
  30: 'mixed.svg',
  36: 'list_large_no_title.svg',
  39: 'course.svg',
  42: 'grid_landscape.svg',
  43: 'slider_carousel.svg',
  44: 'in_progress.svg',
  45: 'slider_portrait.svg',
  46: 'slider_square.svg',
  [FEATURE_TEMPLATE_MYCOURSES]: 'slider_scroll.svg',
  [FEATURE_TEMPLATE_ALLCOURSES]: 'grid_landscape.svg',
  [FEATURE_TEMPLATE_TVOD]: 'slider_scroll.svg',
  [FEATURE_TEMPLATE_MOST_WATCHED]: 'slider_scroll.svg',
};

const StyledImage = styled.img<{ defaultHeight: string; height?: number; width?: number }>`
  width: ${({ width, defaultHeight }) => width || defaultHeight};
  height: ${({ height, defaultHeight }) => height || defaultHeight};
`;

const Fallback = styled.div<{ defaultHeight: string; height?: number; width?: number }>`
  width: ${({ width, defaultHeight }) => `${width}px` || defaultHeight};
  height: ${({ height, defaultHeight }) => `${height}px` || defaultHeight};
  background-color: ${NEUTRAL_1_COLOUR};
  border-radius: 6px;
`;

export const TemplateThumbnail = ({ templateId, isPage, ...props }: TemplateThumbnailProps) => {
  const defaultHeight = isPage ? '100px' : '60px';

  if (!templateId) {
    return <Fallback defaultHeight={defaultHeight} {...props} />;
  }

  const thumbnailFileName = getFeatureSectionFromTemplateId(templateId)
    ? getFeatureSectionFromTemplateId(templateId)?.thumbnail
    : TEMPLATE_THUMBNAILS[templateId || '1'];

  return (
    <StyledImage
      src={`${BUILDER_IMAGES_DOMAIN}/icons/templates/${isPage ? 'long_' : ''}${thumbnailFileName}`}
      defaultHeight={defaultHeight}
      {...props}
    />
  );
};
